import * as yup from 'yup';
import {
    ESTADUAL,
    MOTIVO,
    ANEXOS
} from './fieldNames';

export default (strings) => {
    return yup.object().shape({
        [ESTADUAL]: yup.object().shape({
            [MOTIVO]: yup
                .string()
                .nullable()
                .trim()
                .min(10, strings.motivacaoInvalida)
                .required(strings.campoObrigatorio),
            [ANEXOS]: yup
                .array()
                .of(
                    yup
                        .mixed()
                        .nullable()
                        .test('formatoArquivo', strings.formatoArquivoInvalido, (value) => {
                            if (!value) {
                                return true;
                            }

                            if (!value || !(value instanceof File)) {
                                return true;
                            }
                            return value && ['image/jpeg', 'image/png', 'application/pdf', 'application/msword', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'].includes(value.type);
                        })
                ),
        }).required()
    });
}