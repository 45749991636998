import { validateBr } from 'js-brasil';
import * as yup from 'yup';
import {
    ACOMPANHANTE,
    NUMERO_ACOMPANHANTES,
    NUMERO_ACOMPANHANTES_ORIGINAL,
    JUSTIFICATIVA,
    ACOMPANHANTES,
    NOME,
    CPF,
    CNS,
    RACA,
    DATA_NASCIMENTO,
    CEP,
    LOGRADOURO,
    BAIRRO,
    COMPLEMENTO,
    NUMERO,
    CIDADE,
    UF,
    ANEXOS,
    PARENTESCO,
    EXIGE_COMPLEMENTO,
    COMPLEMENTO_PARENTESCO,
    SEXO
} from './fieldNames';
import {
    DATA_NASCIMENTO as DATA_NASCIMENTO_PACIENTE,
    PACIENTE
} from '../../paciente/form/fieldNames';
import moment from 'moment';

const maxDate = () => {
    return moment().subtract(18, 'years').toDate();
}

const numeroAcompanhantesObrigatorioMenosIdade = (parent) => {
    let dados = parent.value;
    return !(moment().diff(dados[PACIENTE][DATA_NASCIMENTO_PACIENTE], 'years') < 18 && parseInt(dados[ACOMPANHANTE][NUMERO_ACOMPANHANTES]) === 0);
};

export default (strings) => {
    return yup.object().shape({
        [ACOMPANHANTE]: yup.object().shape({
            [NUMERO_ACOMPANHANTES]: yup
                .number()
                .test('pacienteMenorIdade', strings.numeroAcompanhantesObrigatorioMenosIdade, function () {
                    const { from } = this;
                    return numeroAcompanhantesObrigatorioMenosIdade(from[1]);
                })
                .required(strings.campoObrigatorio),
            [JUSTIFICATIVA]: yup
                .string()
                .nullable()
                .trim()
                .when([NUMERO_ACOMPANHANTES_ORIGINAL, NUMERO_ACOMPANHANTES], {
                    is: (numeroAcompOriginal, numeroAcomp) => parseInt(numeroAcompOriginal) !== parseInt(numeroAcomp),
                    then: yup
                        .string()
                        .when(ANEXOS, {
                            is: (val) => !val || val.length === 0 || (val.some(a => (!(a instanceof File) || a.id))),
                            then: yup
                                .string()
                                .required(strings.obrigatorioJustificativaTextual)
                        })
                }),
            [ACOMPANHANTES]: yup.array().of(
                yup.object().shape({
                    [NOME]: yup
                        .string()
                        .nullable()
                        .required(strings.campoObrigatorio),
                    [CNS]: yup
                        .string()
                        .nullable()
                        .test('cns-valido', strings.cnsInvalido, (cns) => {
                            cns = cns || '';
                            if (cns.length === 0) {
                                return false;
                            }
                            return validateBr.cns(cns);
                        })
                        .required(strings.campoObrigatorio),
                    [RACA]: yup
                        .object().shape({
                            id: yup.number(),
                            descricao: yup.string(),
                        })
                        .nullable()
                        .required(strings.campoObrigatorio),
                    [CPF]: yup
                        .string()
                        .nullable()
                        .test('cpf-valido', strings.cpfInvalido, (cpf) => {
                            cpf = (cpf || '').replace(/\D+/g, '');
                            if (cpf.length === 0) {
                                return true;
                            }
                            return validateBr.cpf(cpf);
                        })
                        .required(strings.campoObrigatorio),
                    [DATA_NASCIMENTO]: yup
                        .date()
                        .typeError(strings.dataInvalida)
                        .nullable()
                        .required(strings.campoObrigatorio),
                    [CEP]: yup
                        .string()
                        .min(8, strings.cepInvalido)
                        .max(9, strings.cepInvalido)
                        .nullable()
                        .required(strings.campoObrigatorio),
                    [UF]: yup
                        .string()
                        .min(2, strings.ufInvalida)
                        .max(2, strings.ufInvalida)
                        .nullable()
                        .required(strings.campoObrigatorio),
                    [CIDADE]: yup
                        .string()
                        .min(1, strings.cidadeInvalida)
                        .max(50, strings.cidadeInvalida)
                        .nullable()
                        .required(strings.campoObrigatorio),
                    [BAIRRO]: yup
                        .string()
                        .trim()
                        .nullable()
                        .required(strings.campoObrigatorio),
                    [LOGRADOURO]: yup
                        .string()
                        .trim()
                        .nullable()
                        .required(strings.campoObrigatorio),
                    [COMPLEMENTO]: yup
                        .string()
                        .trim()
                        .max(30, strings.complementoInvalido)
                        .nullable(),
                    [NUMERO]: yup
                        .string()
                        .trim()
                        .max(10, strings.numeroInvalido)
                        .nullable()
                        .required(strings.campoObrigatorio),
                    [SEXO]: yup
                        .object().shape({
                            id: yup.number(),
                            descricao: yup.string(),
                        })
                        .nullable()
                        .required(strings.campoObrigatorio),
                    [PARENTESCO]: yup
                        .object().shape({
                            id: yup.number(),
                            descricao: yup.string(),
                            exige_complemento: yup.boolean(),
                        })
                        .nullable()
                        .required(strings.campoObrigatorio),
                    [COMPLEMENTO_PARENTESCO]: yup
                        .string()
                        .nullable()
                        .when(EXIGE_COMPLEMENTO, {
                            is: (val) => val,
                            then: yup
                                .string()
                                .trim()
                                .required(strings.campoObrigatorio)
                        }),
                })
            ),
            [ANEXOS]: yup
                .array()
                .when([NUMERO_ACOMPANHANTES_ORIGINAL, NUMERO_ACOMPANHANTES], {
                    is: (numeroAcompOriginal, numeroAcomp) => parseInt(numeroAcompOriginal) !== parseInt(numeroAcomp),
                    then: yup
                        .array()
                        .when(JUSTIFICATIVA, {
                            is: (val) => (!val || val.trim().length === 0),
                            then: yup
                                .array()
                                .test('anexo-valido', strings.obrigatorioJustificativaTextual, (anexos) => (anexos && anexos.length >= 1))
                                .test('anexo-valido', strings.obrigatorioJustificativaTextual, (anexos) => {
                                    return anexos.some(a => {
                                        return ((a instanceof File) || a.id)

                                    });
                                })
                                .required(strings.obrigatorioJustificativaTextual)
                        })
                })
                .of(
                    yup
                        .mixed()
                        .nullable()
                        .test('formatoArquivo', strings.formatoArquivoInvalido, (value) => {
                            if (!value) {
                                return true;
                            }

                            if (!value || !(value instanceof File)) {
                                return true;
                            }
                            return value && ['image/jpeg', 'image/png', 'application/pdf', 'application/msword', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'].includes(value.type);
                        })
                ),
        }).required()
    });
}