import * as yup from 'yup';
import {
    GERAL,
    DATA_AGENDAMENTO,
    DATA_EMISSAO_AGENDAMENTO,
} from './fieldNames';

export default (strings) => {
    return yup.object().shape({
        [GERAL]: yup.object().shape({
            [DATA_AGENDAMENTO]: yup
                .date()
                .typeError(strings.dataInvalida)
                .nullable()
                .test('agendamento-valido', strings.dataAgendamentoInvalida, (data) => {
                    if (!data) {
                        return true;
                    }
                    const dataAtual = new Date();
                    const anoAtual = dataAtual.getFullYear();
                    const dataAgentamentoProxAno = new Date(anoAtual + 1, 0, 15);
                    const dataAgendamento = new Date(data);
                    const erroAgendamento = (dataAgendamento >= dataAgentamentoProxAno);
                    return !erroAgendamento;
                })
                .required(strings.campoObrigatorio),
            [DATA_EMISSAO_AGENDAMENTO]: yup
                .date()
                .typeError(strings.dataInvalida)
                .nullable()
                .required(strings.campoObrigatorio),
        }).required()
    });
}
