import * as yup from 'yup';
import { validateBr } from 'js-brasil';
import {
    PACIENTE,
    CNS,
    RACA,
} from './fieldNames';

export default (strings) => {
    return yup.object().shape({
        [PACIENTE]: yup.object().shape({
            [CNS]: yup
                .string()
                .nullable()
                .test('cns-valido', strings.cnsInvalido, (cns) => {
                    cns = cns || '';
                    if (cns.length === 0) {
                        return false;
                    }
                    return validateBr.cns(cns);
                })
                .required(strings.campoObrigatorio),
            [RACA]: yup
                .object().shape({
                    id: yup.number(),
                    descricao: yup.string(),
                })
                .nullable()
                .required(strings.campoObrigatorio),
        }).required()
    });
}
