import React, { useEffect, useState, useRef, useContext } from 'react';
import {
    SttTabs,
    SttTranslateHook
} from '@stt-componentes/core';
import { makeStyles } from "@material-ui/core/styles";
import PesquisaMotorista from '../../componentes/administrativo/motorista/pesquisa';
import PesquisaMotivoCancelamento from '../../componentes/administrativo/motivo-cancelamento/pesquisa';

const useStyles = makeStyles(theme => ({
    wrapper: {
        padding: theme.spacing(1.5)
    },
}));

const IndexAdministrativo = () => {
    const classes = useStyles();
    const { strings } = useContext(SttTranslateHook.I18nContext);

    const [abas, setAbas] = useState([]);
    const [abaAtiva, setAbaAtiva] = useState('0');
    const stateRef = useRef();

    stateRef.current = abas;

    useEffect(() => {
        let dadosAbas = [
            {
                titulo: strings.motorista,
                conteudo: PesquisaMotorista,
                permanente: true,
            },
            {
                titulo: strings.motivoCancelamento,
                conteudo: PesquisaMotivoCancelamento,
                permanente: true,
            }
        ];

        setAbas(dadosAbas);
    }, []);


    return (
        <SttTabs abas={abas}
            canClose={true}
            className={classes.wrapper}
            handleChangeAbaAtiva={setAbaAtiva}
            abaAtiva={abaAtiva}
            permanente={true}
        />
    );
};

export default IndexAdministrativo;