import TYPES from './types/solicitacao';

const INITIAL_STATE = {
    idPassagem: null
};

const solicitacao = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;
    switch (type) {
        case TYPES.SET_ID_TFD_PASSAGEM:
            return {
                ...state,
                idPassagem: payload
            }

        default:
            return state;
    }
}

export default solicitacao;