import * as yup from 'yup';
import {
    AGENDAMENTO,
    TELEFONE,
    ANEXOS
} from './fieldNames';

export default (strings) => {
    return yup.object().shape({
        [AGENDAMENTO]: yup.object().shape({
            [TELEFONE]: yup
                .string()
                .nullable()
                .test('telefone-valido', strings.telefoneInvalido, (tel) => {
                    tel = (tel || '').replace(/\D+/g, '');
                    if (tel.length === 0) {
                        return true;
                    }
                    return (tel.length === 11 || tel.length === 10);
                })
                .required(strings.campoObrigatorio),
            [ANEXOS]: yup
                .array()
                .test({
                    message: strings.campoObrigatorio,
                    test: (anexos) => (anexos && anexos.length >= 1)
                })
                .test({
                    message: strings.campoObrigatorio,
                    test: (anexos) => {
                        return anexos.some(a => {
                            return ((a instanceof File) || a.id)

                        });
                    }
                })
                .of(
                    yup
                        .mixed()
                        .nullable()
                        .test('formatoArquivo', strings.formatoArquivoInvalido, (value) => {
                            if (!value) {
                                return true;
                            }

                            if (!value || !(value instanceof File)) {
                                return true;
                            }
                            return value && ['image/jpeg', 'image/png', 'application/pdf', 'application/msword', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'].includes(value.type);
                        })
                ),
        }).required()
    });
}