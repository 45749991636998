const stringsComuns = require('./comum');

const dermatoComum = {
    avaliar: 'Avaliar',
    C: 'Câncer da pele',
    concordoP: 'Concordo parcialmente',
    concordoT: 'Concordo totalmente',
    discordoP: 'Discordo parcialmente',
    discordoT: 'Discordo totalmente',
    exposicaoSol: 'Exposição ao sol',
    exposicaoSolHorasDia: 'Exposição ao sol (h/dia)',
    exposicaoSolMesesAno: 'Exposição ao sol (meses/ano)',
    fotoprotetor: 'Fotoprotetor',
    fotoprotetorFrequenciaDiaria: 'Fotoprotetor (frequência diária)',
    fotoprotetorTempoUso: 'Fotoprotetor (tempo de uso)',
    fototipo: 'Fototipo',
    hmf: 'História mórbida familiar (HMF)',
    hmp: 'História mórbida pregressa (HMP)',
    hmpc: 'História mórbida pregressa (HMP) - Pele',
    hmpo: 'História mórbida pregressa (HMP) - Outros',
    infoClinicas: 'Informações clínicas',
    lesao: 'Lesão',
    lesoes: 'Lesões',
    naoConcordoNemDiscordo: 'Não concordo, nem discordo',
    nenhumaLesaoAdicionada: 'Nenhuma lesão adicionada',
    O: 'Outras dermatoses',
    prurido: 'Prurido',
    resultadoBiopsia: 'Resultado de biópsia (anatomopatológico) da lesão',
    semLaudo: 'Sem laudo',
    semLaudo72h: 'Sem laudo há 72h',
    sensibilidadeHanseniase: 'Sensibilidade (em caso de hanseníase)',
    laudosGraves: 'Laudos graves',
};


module.exports = {
    Cabecalho: {
        alterarMeusDados: stringsComuns.alterarMeusDados,
        envioImagens: stringsComuns.envioImagens,
        exames: stringsComuns.exames,
        inicio: stringsComuns.inicio,
        laudo: stringsComuns.laudo,
        modulo: 'Dermatologia',
        nomeSistema: stringsComuns.nomeSistema,
        pendencias: stringsComuns.pendencias,
        sair: stringsComuns.sair,
        solicitacao: stringsComuns.solicitacao,
        laudosGraves: dermatoComum.laudosGraves,
        materiaisSuporte: stringsComuns.materiaisSuporte,
        notasAtualizacao: stringsComuns.notasAtualizacao
    },
    EnvioImagens: {
        anterior: stringsComuns.anterior,
        aproximacaoRegua: 'Aproximação com régua',
        atencao: stringsComuns.atencao,
        BRACO_DIREITO_ANTERIOR: 'BRACO_DIREITO_ANTERIOR',
        BRACO_DIREITO_POSTERIOR: 'BRACO_DIREITO_POSTERIOR',
        BRACO_ESQUERDO_ANTERIOR: 'BRACO_ESQUERDO_ANTERIOR',
        BRACO_ESQUERDO_POSTERIOR: 'BRACO_ESQUERDO_POSTERIOR',
        CABECA: 'CABECA',
        cancelar: stringsComuns.cancelar,
        cancerPele: 'Câncer de pele',
        contato: stringsComuns.contato,
        enviarImagens: stringsComuns.enviarImagens,
        equipamento: stringsComuns.equipamento,
        equipamentoObrigatorio: stringsComuns.campoObrigatorio,
        erro: stringsComuns.erro,
        erroDesconhecido: stringsComuns.mensagemErroGeral,
        erroGerarProtocoloEnvioExame: stringsComuns.erroGerarProtocoloEnvioExame,
        erroGerarTermo: stringsComuns.erroGerarTermo,
        finalizar: stringsComuns.finalizar,
        gerandoNumeroProtocolo: stringsComuns.gerandoNumeroProtocolo,
        gerandoTCLE: stringsComuns.gerandoTCLE,
        imagemInvalida: 'O campo deve conter uma imagem do tipo JPG',
        imagemLesao: 'Imagem da lesão',
        imagemLesaoObrigatoria: stringsComuns.campoObrigatorio,
        mensagemAlertaCancelamento: stringsComuns.mensagemCancelarEnvioImagens,
        mensagemDermatoscopia: 'É possível que o dermatologista solicite a realização do registro fotográfico de contato (dermatoscopia) para esta lesão. Caso isso ocorra, o paciente deve ser encaminhado para o setor que realiza a Teledermatoscopia em sua região.',
        mensagemEnviandoImagens: stringsComuns.mensagemEnviandoImagens,
        mensagemErro: stringsComuns.mensagemErroGeral,
        mensagemSucesso: stringsComuns.exameRegistrado,
        nao: stringsComuns.nao,
        noaExisteEquipamento: 'A instituição do exame não possui nenhum ponto cadastrado. Entre em contato com os administradores.',
        observacoes: stringsComuns.observacoes,
        ok: stringsComuns.ok,
        outrasDermatoses: 'Outras dermatoses',
        PERNA_DIREITA_ANTERIOR: 'PERNA_DIREITA_ANTERIOR',
        PERNA_DIREITA_POSTERIOR: 'PERNA_DIREITA_POSTERIOR',
        PERNA_ESQUERDA_ANTERIOR: 'PERNA_ESQUERDA_ANTERIOR',
        PERNA_ESQUERDA_POSTERIOR: 'PERNA_ESQUERDA_POSTERIOR',
        previewImagem: 'Visualização da imagem',
        proximo: stringsComuns.proximo,
        requisicao: stringsComuns.requisicao,
        requisicaoObrigatoria: stringsComuns.campoObrigatorio,
        sim: stringsComuns.sim,
        Steps: {
            cabeca: 'Cabeça',
            contato: 'Dermatoscopia',
            corpoAnterior: 'Corpo anterior',
            corpoPosterior: 'Corpo posterior',
            imagensGerais: 'Imagens gerais',
            informacoesGerais: 'Informações gerais',
            membrosInferioresAnterior: 'Membros inferiores - anterior',
            membrosInferioresPosterior: 'Membros inferiores - posterior',
            membrosSuperioresAnterior: 'Membros superiores - anterior',
            membrosSuperioresPosterior: 'Membros superiores - posterior',
            panoramicas: 'Panorâmicas',
            regua: 'Aproximação com régua',
            troncoAnterior: 'Tronco anterior',
            troncoPosterior: 'Tronco posterior'
        },
        TIPO: {
            CANCER_PELE: 'C',
            OUTRAS_DERMATOSES: 'O'
        },
        titulo: 'Envio de imagens',
        tituloModalCancelamento: 'Confirmação de cancelamento',
        TRONCO_ANTERIOR: 'TRONCO_ANTERIOR',
        TRONCO_POSTERIOR: 'TRONCO_POSTERIOR'
    },
    Erros: {
        erro: stringsComuns.erro,
        naoAutorizado: 'Não autorizado'
    },
    Exame: {
        adicionar: stringsComuns.adicionar,
        agendado: 'Agendado',
        altura: stringsComuns.altura,
        ate: stringsComuns.dataAte,
        atencao: stringsComuns.atencao,
        avaliar: dermatoComum.avaliar,
        cancelar: stringsComuns.cancelar,
        categoria: 'Categoria',
        cns: stringsComuns.cns,
        comLaudo: 'Com laudo',
        confirmar: stringsComuns.confirmar,
        confirmarInvalidarExame: stringsComuns.confirmarInvalidarExame,
        confirmarPriorizarExame: stringsComuns.confirmarPriorizarExame,
        contraRefercia: 'Contra-referência',
        dadosExame: stringsComuns.dadosExame,
        data: stringsComuns.data,
        dataExame: stringsComuns.dataExame,
        dataFinalExame: stringsComuns.dataFinalExame,
        dataFinalInvalidacao: stringsComuns.dataFinalInvalidacao,
        dataFinalLaudo: stringsComuns.dataFinalLaudo,
        dataHoraAtendimento: 'Data/hora do atendimento',
        dataInicialExame: stringsComuns.dataInicialExame,
        dataInicialInvalidacao: stringsComuns.dataInicialInvalidacao,
        dataInicialLaudo: stringsComuns.dataInicialLaudo,
        dataInvalida: stringsComuns.dataInvalida,
        dataLaudo: stringsComuns.dataLaudo,
        dataNascimento: stringsComuns.dataNascimento,
        de: stringsComuns.dataDe,
        descricaoExame: 'Descrição do exame',
        direcionados: 'Direcionado ao meu usuário',
        erro: stringsComuns.erro,
        erroDataMenorDataInicio: stringsComuns.erroDataMenorDataInicio,
        erroDesconhecido: stringsComuns.mensagemErroGeral,
        erroGerarTermo: stringsComuns.erroGerarTermo,
        erroInvalidarExame: stringsComuns.erroInvalidarExame,
        erroPriorizarExame: stringsComuns.erroPriorizarExame,
        erroTrocarRedeExame: stringsComuns.erroTrocarRedeExame,
        exameInvalidado: stringsComuns.exameInvalidado,
        exameSelecionado: 'Exame selecionado',
        examePriorizado: stringsComuns.examePriorizado,
        examesAgendados: 'Agendados',
        examesComImagens: 'Com imagens',
        examesInvalidos: 'Inválidos',
        examesPacs: 'PACS',
        examesSelecionados: 'Exames selecionados',
        examesValidos: 'Válidos',
        formularioSolicitacaoMed: 'Formulário de solicitação de medicamentos',
        gerandoDocumento: stringsComuns.gerandoDocumento,
        idade: stringsComuns.idade,
        imprimir: stringsComuns.imprimir,
        imprimirProtocolo: stringsComuns.imprimirProtocolo,
        imprimirTermo: stringsComuns.imprimirTermo,
        instituicao: stringsComuns.instituicao,
        invalidandoExame: stringsComuns.invalidandoExame,
        invalidar: stringsComuns.invalidar,
        invalido: 'Inválido',
        laudo: stringsComuns.laudo,
        laudoAguardandoComplemento: 'Com laudo aguardando complemento',
        laudoEmissao: 'Em emissão',
        laudoTemporario: 'Com laudo temporário',
        limpar: stringsComuns.limpar,
        linhasPorPagina: stringsComuns.linhasPorPagina,
        medicoExecutor: 'Médico executor',
        medicoSolicitante: stringsComuns.medicoSolicitante,
        modalidade: stringsComuns.modalidade,
        motivo: stringsComuns.motivo,
        municipio: stringsComuns.municipio,
        nao: stringsComuns.nao,
        nenhumRegistroEncontrado: stringsComuns.nenhumRegistroEncontrado,
        nome: stringsComuns.nome,
        novaRede: 'Nova rede',
        numero_exame: 'Número do exame',
        numeroExame: stringsComuns.numeroExame,
        observacao: stringsComuns.observacao,
        observacaoTecnica: stringsComuns.observacaoTecnica,
        ok: stringsComuns.ok,
        opcoes: 'Opções',
        outrasInformacoes: 'Outras informações',
        paciente: stringsComuns.paciente,
        pacs: 'PACS',
        peso: stringsComuns.peso,
        pesquisar: 'Pesquisar',
        priorizandoExame: stringsComuns.priorizandoExame,
        priorizar: stringsComuns.priorizar,
        profissao: stringsComuns.profissao,
        protocolo: stringsComuns.protocolo,
        protocoloMed: 'Protocolo de medicamento',
        qualquerTermo: 'Qualquer um dos termos',
        receitaMedica: 'Receita médica',
        rede: stringsComuns.rede,
        redeAlterada: 'Rede alterada',
        requisicao: stringsComuns.requisicao,
        responsavel: stringsComuns.responsavel,
        responsavelEnvio: stringsComuns.responsavelEnvio,
        resultadoBiopsia: dermatoComum.resultadoBiopsia,
        semLaudo: dermatoComum.semLaudo,
        semLaudo72h: dermatoComum.semLaudo72h,
        sexo: stringsComuns.sexo,
        sim: stringsComuns.sim,
        situacao: stringsComuns.situacao,
        situacaoExame: stringsComuns.situacaoExame,
        situacaoLaudo: stringsComuns.situacaoLaudo,
        sucesso: stringsComuns.sucesso,
        tecnico: stringsComuns.tecnico,
        termoEsclarecimento: stringsComuns.termoEsclarecimento,
        termosDescricao: 'Termos da descrição do exame',
        textoInvalidar: stringsComuns.textoInvalidarExame,
        todosTermos: 'Todos os termos',
        trocandoRede: stringsComuns.trocandoRede,
        trocarRede: 'Trocar rede',
        uf: stringsComuns.uf,
        ultrapassouTempoPrevisto: 'Ultrapassou o tempo previsto para laudo',
        valido: 'Válido',
        ver: stringsComuns.ver,
        voltar: stringsComuns.voltar
    },
    Externo: {
        logout: 'Você foi desconectado do sistema'
    },
    ItinerarioTerapeutico: {
        adicionar: stringsComuns.adicionar,
        AtendimentoPaciente: {
            anosRetorno: 'Anos para o retorno:',
            apresentacao: 'Apresentação',
            atendimentoRegistrado: 'Atendimento registrado',
            atendimentoRegistrado: 'Dados do atendimento ao paciente registrados',
            diasRetorno: 'Dias para o retorno:',
            documentosImpressos: 'Selecione os documentos a serem impressos.',
            documentosImpressosF: 'Formulário médico para solicitação de medicamento(s)',
            documentosImpressosL: '(LME) Laudo de solicitação, avaliação e autorização de medicamento(s)',
            documentosImpressosR: 'Receita do(s) medicamento(s)',
            documentosImpressosT: 'Termo de esclarecimento e responsabilidade',
            justificativa: 'Justificativa',
            medicamento: 'Medicamento',
            medicamentosAdicionados: 'Medicamentos adicionados',
            medSistemico: 'Medicamentos de uso sistêmico.',
            medTopico: 'Medicamentos de uso tópico.',
            mesesRetorno: 'Meses para o retorno:',
            modeloReceita: 'Modelos de receitas de medicamentos',
            nome: stringsComuns.nome,
            obs: stringsComuns.observacoes,
            observacoesGerais: 'Observações Gerais sobre o uso do(s) medicamento(s).',
            p1: '1 - Possui resultado dos exames físicos, complementares ou laboratoriais?',
            p2: '2 - Há confirmação do CID-10 relacionado à lesão?',
            p3: '3 - Informações e orientações apresentadas e negociadas com a pessoa, em relação ao problema em questão.',
            p4: '4 - Estratégias de seguimento longitudinal e continuado da pessoa e do problema em questão e justificativa de novos encaminhamentos.',
            p5: '5 - Utilizar os modelos de receitas de medicamentos?',
            posologia: 'Posologia',
            resultadoExames: 'Resultado dos exames',
            titulo: 'Atendimento ao paciente - Contra-referência'
        },
        Avaliacao: {
            avaliacao: 'Avaliação',
            avaliar: dermatoComum.avaliar,
            // Pergunta 1
            p1Label: 'O encaminhamento foi realizado conforme protocolo de encaminhamento para consulta especializada de dermatologia?',
            p1Opcao1: 'Regulação estadual conforme aprovados em CIB',
            p1Opcao2: 'Regulação municipal conforme pactuação local',
            p1Opcao3: 'Regulação regional conforme pactuação CIR',
            p1Opcao4: 'Não se aplica',
            p1Opcao5: 'Não sabe',
            // Pergunta 2
            p2Label: 'Você está satisfeito(a) com o resultado do laudo da Teledermatologia?',
            p2Opcao1: dermatoComum.discordoT,
            p2Opcao2: dermatoComum.discordoP,
            p2Opcao3: dermatoComum.naoConcordoNemDiscordo,
            p2Opcao4: dermatoComum.concordoP,
            p2Opcao5: dermatoComum.concordoT,
            // Pergunta 3
            p3Label: 'Significado dos resultados do exame:',
            p3Opcao1: 'Eu entendi perfeitamente o significado dos resultados dos exames',
            p3Opcao2: 'Eu tive dúvida sobre o significado dos resultados do exames',
            p3Opcao3: 'Eu não entendi o significado do resultado do exame',
            // Pergunta 4
            p4Label: 'Após o resultado do laudo, você tem a intenção de encaminhar o paciente?',
            p4Opcao1: dermatoComum.discordoT,
            p4Opcao2: dermatoComum.discordoP,
            p4Opcao3: dermatoComum.naoConcordoNemDiscordo,
            p4Opcao4: dermatoComum.concordoP,
            p4Opcao5: dermatoComum.concordoT,
            titulo: 'Solicitamos a sua avaliação desse laudo para termos maior controle sobre os resultados dados pelos médicos laudadores.'
        },
        avaliacaoRegistrada: 'Avaliação registrada',
        cancelar: stringsComuns.cancelar,
        erroDesconhecido: stringsComuns.erroDesconhecido,
        nao: stringsComuns.nao,
        ok: stringsComuns.ok,
        salvar: stringsComuns.salvar,
        sim: stringsComuns.sim,
        sucesso: stringsComuns.sucesso
    },
    Laudo: {
        altura: stringsComuns.altura,
        anosFuma: stringsComuns.anosFuma,
        assinando: 'Aguarde - assinando digitalmente o laudo...',
        atencao: stringsComuns.atencao,
        C: dermatoComum.C,
        cancelar: stringsComuns.cancelar,
        carregandoExame: 'Carregando exame',
        categoria: 'Categoria',
        cid10: stringsComuns.cid10,
        cigarrosDia: stringsComuns.cigarrosDia,
        classificacaoRisco: 'Classificação de risco',
        classificacaoRiscoObrigatoria: stringsComuns.campoObrigatorio,
        classRiscoDermatose: 'Classificação de risco e Dermatose',
        coloracao: 'Coloração',
        coloracaoObrigatoria: stringsComuns.campoObrigatorio,
        comorbidades: stringsComuns.comorbidades,
        conclusao: 'Conclusão',
        confirmando: 'Confirmando laudo',
        confirmar: stringsComuns.confirmar,
        confirmarInvalidarExame: stringsComuns.confirmarInvalidarExame,
        copiarLaudo: 'Copiar laudo',
        copiarLaudoInfo: 'Os dados preechidos em "Lesão elementar", "Lesão secundária", "Coloração", "Morfologia", "Distribuição", "Tamanho", "Compatibilidade", "Classificação de risco", "Grupo dermatose" e "Dermatose" serão copiados para a lesão informada.',
        copiarPara: 'Copiar para:',
        dadosCopiados: 'Dados copiados',
        dataNascimento: stringsComuns.dataNascimento,
        dermatose: 'Dermatose',
        dermatoseObrigatoria: stringsComuns.campoObrigatorio,
        descricaoEstudo: 'Descrição do estudo',
        descricaoEstudoObrigatorio: stringsComuns.campoObrigatorio,
        descritores: 'Descritores',
        distribuicao: 'Distribuição',
        distribuicaoObrigatoria: stringsComuns.campoObrigatorio,
        erro: stringsComuns.erro,
        erroAssinatura: 'Erro ao assinar digitalmente o laudo',
        erroDesconhecido: stringsComuns.mensagemErroGeral,
        erroIgnorarExame: stringsComuns.erroIgnorarExame,
        erroInvalidarExame: stringsComuns.erroInvalidarExame,
        erroSortearExame: 'Não foi possível sortear o próximo exame para laudo. Tente novamente e se o problema persistir, entre em contato com os administradores.',
        etilismo: stringsComuns.etilismo,
        fototipo: dermatoComum.fototipo,
        gerandoResumo: 'Gerando resumo do laudo',
        grupoDermatose: 'Grupo dermatose',
        grupoDermatoseObrigatorio: stringsComuns.campoObrigatorio,
        hmf: dermatoComum.hmf,
        hmp: dermatoComum.hmp,
        hmpc: dermatoComum.hmpc,
        hmpo: dermatoComum.hmpo,
        ignorandoExame: 'Ignorando exame',
        indicacaoClinica: stringsComuns.indicacaoClinica,
        infeccoes: stringsComuns.infeccoes,
        infoClinicas: dermatoComum.infoClinicas,
        invalidandoExame: stringsComuns.invalidandoExame,
        invalidar: stringsComuns.invalidar,
        laudoAssinado: 'Laudo assinado digitalmente com sucesso',
        laudoEmEmissao: 'Devido à demora na emissão do laudo, este exame foi acessado por outro profissional. O próximo exame será carregado.',
        laudoTextual: 'Laudo textual',
        lesao: dermatoComum.lesao,
        lesaoCompativelCom: 'Lesão Compatível com',
        lesaoCompativelComObrigatoria: stringsComuns.campoObrigatorio,
        lesaoElementar: 'Lesão elementar',
        lesaoElementarObrigatoria: stringsComuns.campoObrigatorio,
        lesaoSecundaria: 'Lesão secundária',
        lesaoSecundariaObrigatoria: stringsComuns.campoObrigatorio,
        lesoes: dermatoComum.lesoes,
        localizacao: 'Localização',
        localizacaoObrigatoria: stringsComuns.campoObrigatorio,
        morfologia: 'Morfologia',
        morfologiaObrigatoria: stringsComuns.campoObrigatorio,
        motivo: stringsComuns.motivo,
        motivoEncaminhamento: stringsComuns.motivoEncaminhamento,
        nao: stringsComuns.nao,
        nenhummExameParaLaudo: 'Nenhum exame disponível para laudo',
        nome: stringsComuns.nome,
        observacao: stringsComuns.observacao,
        observacaoTecnica: stringsComuns.observacaoTecnica,
        observacoes: stringsComuns.observacoes,
        ok: stringsComuns.ok,
        outrasInfosPert: 'Outras informações pertinentes',
        paciente: stringsComuns.paciente,
        peso: stringsComuns.peso,
        profissao: stringsComuns.profissao,
        proximoExame: 'Próximo exame',
        prurido: dermatoComum.prurido,
        publicar: 'Publicar',
        remover: 'Remover',
        resultadoBiopsia: dermatoComum.resultadoBiopsia,
        resumoLaudo: 'Resumo do laudo',
        sensibilidadeHanseniase: dermatoComum.sensibilidadeHanseniase,
        sexo: stringsComuns.sexo,
        sim: stringsComuns.sim,
        subcategoria: 'Subcategoria',
        sucesso: stringsComuns.sucesso,
        tabagismo: stringsComuns.tabagismo,
        tamanhoCm: stringsComuns.tamanhoCm,
        tamanhoCm: stringsComuns.tamanhoCm,
        tamanhoObrigatorio: stringsComuns.campoObrigatorio,
        tempoDoenca: stringsComuns.tempoDoenca,
        tentarNovamente: stringsComuns.tentarNovamente,
        textoInvalidar: stringsComuns.textoInvalidarExame,
        todasImagens: 'Todas as imagens',
        voltar: stringsComuns.voltar
    },
    LaudosGraves: {
        exameNumero: 'Exame nº',
        laudoEmitidoExame: 'Laudo emitido para o exame',
        laudosGraves: dermatoComum.laudosGraves,
        naoFoiPossivelLaudosGraves: 'Não foi possível carregar a lista de laudos graves',
        notificacao: 'Notificação',
        responsavelEnvio: stringsComuns.responsavelEnvio,
    },
    Pendencias: {
        exameMaisAntigoSemLaudo: 'Data do exame mais antigo sem laudo',
        examesComLaudo: stringsComuns.examesComLaudo,
        examesInvalidos: stringsComuns.examesInvalidos,
        examesValidos: stringsComuns.examesValidos,
        laudosEmitidos: 'Laudos emitidos',
        naoFoiPossivelCarregarSemLaudo: 'Não foi possível carregar os dados de exames sem laudo',
        naoFoiPossivelCarregarExamesComLaudo: 'Não foi possível carregar os dados de exames laudados',
        naoFoiPosisvelCarregarExamesInvalidos: 'Não foi possível carregar os dados de exames inválidos',
        naoFoiPossivelCarregarLaudosEmitidos: 'Não foi possível carregar os dados de laudos emitidos',
        naoFoiPossivelCarregarSemLaudo72h: 'Não foi possível carregar os dados de exames com laudo atrasado',
        naoFoiPossivelCarregarTotais: 'Não foi possível carregar os dados de produtividade',
        nenhumRegistroEncontrado: stringsComuns.nenhumRegistroEncontrado,
        semLaudo: dermatoComum.semLaudo,
        semLaudo72h: dermatoComum.semLaudo72h,
        tentarNovamente: stringsComuns.tentarNovamente
    },
    Solicitacao: {
        adicionar: stringsComuns.adicionar,
        alterar: stringsComuns.alterar,
        altura: stringsComuns.altura,
        anosFuma: stringsComuns.anosFuma,
        assinaleLocaisLesoes: 'Assinale o(s) local(is) da(s) lesão(ões)',
        atencao: stringsComuns.atencao,
        ausente: 'Ausente',
        bairro: 'Bairro',
        buscar: stringsComuns.buscar,
        C: dermatoComum.C,
        cadastrar: 'Cadastrar',
        campoObrigatorio: stringsComuns.campoObrigatorio,
        cancelar: stringsComuns.cancelar,
        cancerPele: dermatoComum.C,
        cbc: 'Carcinona Basocelular (CBC)',
        cec: 'Carcinoma Espinocelular (CEC)',
        cid10: stringsComuns.cid10,
        cigarrosDia: stringsComuns.cigarrosDia,
        comorbidades: stringsComuns.comorbidades,
        confirmarRemoverCid10: 'Você confirma a exclusão do CID-10?',
        confirmarRemoverLesao: 'Você confirma a exclusão da lesão?',
        dadosInvalidos: 'Dados inválidos. Verifique os dados informados e tente novamente.',
        dadosPaciente: stringsComuns.dadosPaciente,
        dataNascimento: stringsComuns.dataNascimento,
        dataNascimentoFutura: stringsComuns.erroDataFutura,
        dataNascimentoInvalida: stringsComuns.dataInvalida,
        de: stringsComuns.de,
        diminuida: 'Diminuída',
        duasQuatroVezezMes: '2-4 vezes ao mês',
        duasTresVezesSemana: '2-3 vezez por semana',
        emailInvalido: 'Email inválido',
        enviar: 'Enviar',
        erro: stringsComuns.erro,
        erroDesconhecido: stringsComuns.mensagemErroGeral,
        especificacaoTempo: 'Especificação',
        esposicaoSolHorasDiaObrigatorio: stringsComuns.campoObrigatorio,
        etilismo: 'Etilismo - com qual frequência consome bebidas com álcool?',
        etilismoObrigatorio: stringsComuns.campoObrigatorio,
        exameRegistrado: stringsComuns.exameRegistrado,
        excluir: stringsComuns.excluir,
        exFumante: 'Ex-fumante',
        exposicaoSol: dermatoComum.exposicaoSol,
        exposicaoSolHorasDia: dermatoComum.exposicaoSolHorasDia,
        exposicaoSolMesesAno: dermatoComum.exposicaoSolMesesAno,
        exposicaoSolMesesAnoObrigatorio: stringsComuns.campoObrigatorio,
        fechar: stringsComuns.fechar,
        fotoprotetor: dermatoComum.fotoprotetor,
        fotoprotetorEspecificacaoObrigatorio: stringsComuns.campoObrigatorio,
        fotoprotetorFrequenciaObrigatorio: stringsComuns.campoObrigatorio,
        fotoprotetorPeriodo: 'Tempo de uso',
        fototipo: dermatoComum.fototipo,
        fototipoObrigatorio: stringsComuns.campoObrigatorio,
        frequencia: 'Frequência',
        frequenciaDiaria: 'Frequência diária',
        fumante: 'Fumante',
        hmf: dermatoComum.hmf,
        hmp: dermatoComum.hmp,
        hmpCancerPeleObrigatoria: stringsComuns.campoObrigatorio,
        hmpOutroCancerObrigatoria: stringsComuns.campoObrigatorio,
        imformacoesCompl: 'Informações complementares',
        infeccoes: stringsComuns.infeccoes,
        infoClinicas: dermatoComum.infoClinicas,
        instituicaoObrigatoria: stringsComuns.campoObrigatorio,
        instituicaoSolicitante: 'Instituição solicitante',
        lesaoObrigatoria: 'Pelo menos uma lesão deve ser informada',
        lesoes: dermatoComum.lesoes,
        linhasPorPagina: stringsComuns.linhasPorPagina,
        local: 'Local',
        logradouro: 'Logradouro',
        medicoSolicitante: stringsComuns.medicoSolicitante,
        melanoma: 'Melanoma',
        mensagemConfirmarEnvioExame: stringsComuns.mensagemConfirmarEnvioExame,
        mensagemConfirmarTermo: 'Deseja imprimir o TCLE?',
        mensagemConfirmarTermo_solicitacao: stringsComuns.mensagemConfirmarTermo_solicitacao,
        mensalmente: 'Mensalmente',
        motivoEncaminhamento: stringsComuns.motivoEncaminhamento,
        motivoEncaminhamentoObrigatorio: stringsComuns.campoObrigatorio,
        municipio: stringsComuns.municipio,
        nao: stringsComuns.nao,
        nenhumaLesaoAdicionada: dermatoComum.nenhumaLesaoAdicionada,
        nenhumResultado: stringsComuns.nenhumResultado,
        nome: stringsComuns.nome,
        nunca: stringsComuns.nunca,
        nuncaFumou: stringsComuns.nuncaFumou,
        O: dermatoComum.O,
        observacoes: stringsComuns.observacoes,
        ok: stringsComuns.ok,
        origem: 'Origem',
        outras: stringsComuns.outras,
        outrasDermatoses: dermatoComum.O,
        outrasInfo: stringsComuns.outrasInformacoes,
        outrosCancer: 'Câncer outros',
        pacienteObrigatorio: stringsComuns.pacienteObrigatorio,
        pessoasMorenas: 'V - Pessoas morenas: raramente se queimam, bronzeiam-se muito',
        pessoasNegras: 'VI - Negros: nunca se queimam, profundamente pigmentados',
        pessoasPeleBranca: 'III - A média das pessoas brancas normais: queimam-se moderadamente, bronzeiam-se gradual e uniformemente',
        pessoasPeleClara: 'IV - Pele clara ou morena clara, cabelos castanho-escuros e olhos escuros: queimam-se muito pouco, bronzeiam-se bastante',
        pessoasPeleClaraRuivas: 'II - Pele clara, olhos azuis, verdes ou castanho-claros, cabelos louros ou ruivos: sempre se queimam e, às vezes, se bronzeiam',
        pessoasPeleClaraSardentas: 'I - Pele clara, olhos azuis, sardentos: sempre se queimam e nunca se bronzeiam',
        presente: 'Presente (preservada)',
        profissao: stringsComuns.profissao,
        protocoloCancerPele: 'Protocolo para encaminhamento de pacientes com câncer da pele',
        prurido: dermatoComum.prurido,
        quatroOuMaisSemana: '4 ou mais vezes por semana',
        resultadoBiopsia: dermatoComum.resultadoBiopsia,
        salvandoSolicitacao: stringsComuns.salvandoSolicitacao,
        selecionar: stringsComuns.selecionar,
        semInformacao: stringsComuns.semInformacao,
        sensibilidadeHanseniase: dermatoComum.sensibilidadeHanseniase,
        sexo: stringsComuns.sexo,
        sim: stringsComuns.sim,
        solicitacaoRegistrada: 'Solicitação registrada',
        solicitante: stringsComuns.solicitante,
        solicitanteObrigatorio: stringsComuns.campoObrigatorio,
        sucesso: stringsComuns.sucesso,
        tabagismo: stringsComuns.tabagismo,
        tabagismoAnosFumoInvalido: stringsComuns.campoInvalido,
        tabagismoAnosFumoObrigatorio: stringsComuns.campoObrigatorio,
        tabagismoCigarrosDiaInvalido: stringsComuns.campoInvalido,
        tabagismoCigarrosDiaObrigatorio: stringsComuns.campoObrigatorio,
        tabagismoObrigatorio: stringsComuns.campoObrigatorio,
        tamanhoCm: stringsComuns.tamanhoCm,
        teledermatologia: 'Teledermatologia',
        teledermatoscopia: 'Teledermatoscopia',
        tempoDoenca: stringsComuns.tempoDoenca,
        termoEsclarecimento: stringsComuns.termoEsclarecimento,
        tipo: 'Tipo',
        tipoContato: 'Tipo de contato',
        tipoDermatose: 'Tipo de dermatose',
        tipoDermatoseObrigatorio: stringsComuns.campoObrigatorio,
        tipoExame: 'Tipo de exame',
        tituloConfirmarEnvioExame: stringsComuns.tituloConfirmarEnvioExame,
        uf: stringsComuns.uf,
        ver: stringsComuns.ver
    },
    SolicitacoesEmAberto: {
        altura: stringsComuns.altura,
        anosFuma: stringsComuns.anosFuma,
        ate: stringsComuns.dataAte,
        C: dermatoComum.C,
        cidadeProcedencia: stringsComuns.cidadeProcedencia,
        cigarrosDia: stringsComuns.cigarrosDia,
        cns: stringsComuns.cns,
        comorbidades: stringsComuns.comorbidades,
        dadosExame: stringsComuns.dadosExame,
        dadosPaciente: stringsComuns.dadosPaciente,
        data: stringsComuns.data,
        dataFinalSolicitacao: stringsComuns.dataFinalSolicitacao,
        dataInicialSolicitacao: stringsComuns.dataInicialSolicitacao,
        dataInvalida: stringsComuns.dataInvalida,
        dataNascimento: stringsComuns.dataNascimento,
        dataSolicitacao: stringsComuns.dataSolicitacao,
        de: stringsComuns.dataDe,
        enviarImagens: stringsComuns.enviarImagens,
        erroDataFutura: stringsComuns.erroDataFutura,
        erroDataMenorDataInicio: stringsComuns.erroDataMenorDataInicio,
        etilismo: stringsComuns.etilismo,
        exposicaoSol: stringsComuns.exposicaoSol,
        exposicaoSolHorasDia: dermatoComum.exposicaoSolHorasDia,
        exposicaoSolMesesAno: dermatoComum.exposicaoSolMesesAno,
        fotoprotetor: dermatoComum.fotoprotetor,
        fotoprotetorFrequenciaDiaria: dermatoComum.fotoprotetorFrequenciaDiaria,
        fotoprotetorTempoUso: dermatoComum.fotoprotetorTempoUso,
        fototipo: dermatoComum.fototipo,
        genero: stringsComuns.genero,
        hmf: dermatoComum.hmf,
        hmp: dermatoComum.hmp,
        hmpc: dermatoComum.hmpc,
        hmpo: dermatoComum.hmpo,
        idade: stringsComuns.idade,
        indicacaoClinica: stringsComuns.indicacaoClinica,
        infeccoes: stringsComuns.infeccoes,
        lesao: dermatoComum.lesao,
        lesoes: dermatoComum.lesoes,
        limpar: stringsComuns.limpar,
        linhasPorPagina: stringsComuns.linhasPorPagina,
        modalidade: stringsComuns.modalidade,
        motivoEncaminhamento: stringsComuns.motivoEncaminhamento,
        municipio: stringsComuns.municipio,
        municipio: stringsComuns.municipio,
        nao: stringsComuns.nao,
        nenhumRegistroEncontrado: stringsComuns.nenhumRegistroEncontrado,
        nome: stringsComuns.nome,
        O: dermatoComum.O,
        observacoes: stringsComuns.observacoes,
        ocupacao: stringsComuns.ocupacao,
        paciente: stringsComuns.paciente,
        peso: stringsComuns.peso,
        pesquisar: stringsComuns.pesquisar,
        prurido: dermatoComum.prurido,
        resultadoBiopsia: dermatoComum.resultadoBiopsia,
        sensibilidadeHanseniase: dermatoComum.sensibilidadeHanseniase,
        sim: stringsComuns.sim,
        solicitante: stringsComuns.solicitante,
        tabagismo: stringsComuns.tabagismo,
        tempoDoenca: stringsComuns.tempoDoenca,
        uf: stringsComuns.uf,
        uf: stringsComuns.uf,
        visualizar: stringsComuns.visualizar,
        voltar: stringsComuns.voltar
    }
}