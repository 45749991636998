export default {
    CACHEMANAGER_IFRAME_CLASS: 'stt-iframe-cachemanager',

    LOCALSTORAGE_ITEMS: {
        ACCESS_TOKEN: 'stt-authorization',
        REFRESH_TOKEN: 'refresh_token',
        EVENT_BUS: 'eventbus',
        USER_INFO: 'user_info',
    },

    COOKIES_ITEMS: {
        ACCESS_TOKEN: '_satuid', // [S]TT [A]CCESS [T]OKEN [UID]
        REFRESH_TOKEN: '_srtuid', // [S]TT [R]EFRESH [T]OKEN [UID]
        EVENT_BUS_ACTION: '_seba', // [S]TT [E]VENT [B]US [A]ction
    },

    ACTIONS: {
        SINCRONIZAR_LOCAL_STORAGE: 'SINCRONIZAR_LOCAL_STORAGE',
        REDIRECT_LOGIN: 'REDIRECT_LOGIN',
    },
};
