module.exports = {
    abrirModulo: 'Abrir exame no módulo de',
    adicionarAnexo: 'Adicionar anexo',
    adicionarSecao: 'Adicionar seção',
    agendados: 'Agendados',
    alteracaoModeloLaudo: 'Alteração de modelo de laudo',
    anexoInserido: 'Anexo registrado',
    baixandoExame: 'Baixando exame',
    cadastroModeloLaudo: 'Cadastro de modelo de laudo',
    carregandoExame: 'Carregando o exame',
    carregandoHistoricoExames: 'Carregando histórico do exame',
    categoria: 'Categoria',
    comLaudo: 'Com laudo',
    confirmaRemocaoAnexo: 'Você confirma a remoção do anexo?',
    confirmaRemocaoModeloLaudo: 'Você confirma a remoção do modelo de laudo?',
    confirmaRemocaoSecaoModeloLaudo: 'Você confirma a remoção da seção?',
    contraRefercia: 'Contra-referência',
    copiandoLaudo: 'Copiando laudo',
    copiarLaudo: 'Copiar laudo',
    dataHoraAtendimento: 'Data/hora do atendimento',
    descricaoExame: 'Descrição do exame',
    desejaAdicionarOuSubstituir: 'Adicionar ao modelo atual ou substituir o modelo atual?',
    devolverListaGeral: 'Devolver para lista geral',
    direcionado: 'Direcionado',
    direcionados: 'Direcionado',
    direcionadosAMin: 'Direcionados a mim',
    encaminhamentoRevisao: 'Encaminhamento do laudo para revisão',
    erroDownloadWeasis: 'Download do Weasis indisponível. Tente novamente mais tarde.',
    exameAtual: 'Exame atual',
    exameDevolvidoListaGeral: 'Exame devolvido para lista geral',
    examesAgendados: 'Agendados',
    examesComImagens: 'Com imagens',
    exameSelecionado: 'Exame selecionado',
    exameSemImagens: 'Este exame ainda não possui imagens',
    exameSemPermissaoGaleria: 'A visualização de imagens deste exame não é permitida.',
    examesInvalidos: 'Inválidos',
    examesPacs: 'PACS',
    examesSelecionados: 'Exames selecionados',
    examesValidos: 'Válidos',
    exameValidado: 'Exame validado',
    formularioSolicitacaoMed: 'Formulário de solicitação de medicamentos',
    historicoPaciente: 'Histórico do paciente',
    indicacao: 'Indicação',
    indicacaoClinicaRegistrada: 'Indicação clínica registrada',
    invalido: 'Inválido',
    laudoAguardandoComplemento: 'Laudo aguardando complemento',
    laudoEmissao: 'Laudo em emissão',
    laudoRegistrado: 'Laudo registrado',
    laudosGraves: 'Laudos graves',
    laudoTemporario: 'Laudo temporário',
    medicoExecutor: 'Médico executor',
    modeloLaudo: 'Modelo de laudo',
    modelosLaudoInstituicao: 'Modelo de laudo da instituição',
    modelosLaudoUsuario: 'Modelo de laudo do usuário',
    novaRede: 'Nova rede',
    numero_exame: 'Número do exame',
    numeroLaudo: 'Número do laudo',
    ohif: 'OHIF',
    opcoes: 'Opções',
    oQueDesejaFazer: 'O que deseja fazer?',
    outrasInformacoes: 'Outras informações',
    oviyam: 'Oviyam',
    pacs: 'PACS',
    pdfIndisponivel: 'A geração do PDF deste exame apresentou erros e não está disponível. Tente novamente. Se o erro persistir, entre em contato com o suporte.',
    pesquisaAvancada: 'Pesquisa avançada',
    pesquisar: 'Pesquisar',
    pesquisaSimples: 'Pesquisa simples',
    placeholderSepareTermos: 'Separe os termos com espaço',
    preparandoEstudo: 'Preparando estudo',
    priorizado: 'Priorizado',
    protocoloMed: 'Protocolo de medicamento',
    qualquerExecutor: 'Qualquer médico executor da instituição',
    qualquerTermo: 'Qualquer um dos termos',
    receitaMedica: 'Receita médica',
    redeAlterada: 'Rede alterada',
    removerSecao: 'Remover seção',
    responsavelEnvio: 'Responsável pelo envio',
    resumoLaudo: 'Resumo do laudo',
    rotina: 'Rotina',
    secoesModelo: 'Seções do modelo',
    semImagensExibicao: 'Não há imagens disponíveis para exibição.',
    semLaudo: 'Sem laudo',
    semLaudo72h: 'Sem laudo há 72h',
    situacaoOrigem: 'Situação/Origem',
    somenteImagens: 'Somente exames com imagens',
    substituir: 'Substituir',
    telediagnostico: 'Telediagnóstico',
    termosDescricao: 'Descrição do exame',
    textoSecao: 'Texto da seção',
    tipoBusca: 'Tipo de busca por termo',
    tituloSecao: 'Título da seção',
    todosTermos: 'Todos os termos',
    trocarRede: 'Trocar rede',
    ultrapassouTempoPrevisto: 'Ultrapassou o tempo previsto para laudo',
    valido: 'Válido',
    visualizacaoPreviaModeloLaudo: 'Visualização prévia do modelo',
    weasis: 'WEASIS'
};