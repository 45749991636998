import * as yup from 'yup';
import { validateBr } from 'js-brasil';
import {
    DATA_DE,
    DATA_ATE,
    CPF_PACIENTE
} from './fieldNames';

export default (strings) => {
    return yup.object().shape({
        [DATA_DE]: yup
            .date()
            .typeError(strings.dataInvalida)
            .nullable(),
        [DATA_ATE]: yup
            .date()
            .typeError(strings.dataInvalida)
            .min(yup.ref(DATA_DE), strings.erroDataMenorDataInicial)
            .nullable(),
        [CPF_PACIENTE]: yup
            .string()
            .nullable()
            .test('cpf-valido', strings.cpfInvalido, (cpf) => {
                cpf = (cpf || '').replace(/\D+/g, '');
                if (cpf.length === 0) {
                    return true;
                }
                return validateBr.cpf(cpf);
            })
    });
}
