import React, { useEffect, useState, useContext } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import {
    SttTable,
    SttTableHead,
    SttTableBody,
    SttTableRow,
    SttTableCell,
    SttTableSortLabel,
    SttTablePagination,
    SttCircularProgress,
    SttEditButton,
    SttHidden,
    SttText,
    SttButton,
    SttTranslateHook
} from '@stt-componentes/core';
import axios from 'axios';
import { getHeaders } from '../../../request';
import { Util } from '@stt-utilitarios/core';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import TableContainer from '@material-ui/core/TableContainer';
import FormMotorista from './form';

const useStyles = makeStyles(theme => ({
    tableWrapper: {
        marginTop: theme.spacing(2)
    },
    table: {
        overflow: 'auto'
    },
    notificacao: {
        marginTop: theme.spacing(1.5),
        marginBottom: theme.spacing(1),
    },
    alertTitle: {
        marginBottom: 0
    },
    buttonWrapper: {
        display: 'flex',
        alignItems: 'center'
    },
    chip: {
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(0.5),
        color: 'white',
        fontWeight: 'bold',
        height: 'auto',
        textShadow: '0 0 2px black',
        '& span': {
            whiteSpace: 'pre-wrap'
        }
    }
}));

const ORDENACAO = {
    DIRECAO: {
        ASC: 'asc',
        DESC: 'desc'
    },
    CAMPOS: {
        NOME: 'nome',
        CPF: 'cpf',
        ATIVO: 'ativo',
        MATRICULA: 'matricula'
    }
};

const PesquisaMotorista = () => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const classes = useStyles();

    const [solicitacoes, setSolicitacoes] = useState([]);
    const [page, setPage] = useState(global.gConfig.pagination.start);
    const [count, setCount] = useState(global.gConfig.pagination.count);
    const [totalRegistros, setTotalRegistros] = useState(0);
    const [order, setOrder] = useState(ORDENACAO.DIRECAO.ASC);
    const [orderBy, setOrderBy] = useState(ORDENACAO.CAMPOS.NOME);
    const [buscaEmAndamento, setBuscaEmAndamento] = useState(false);
    const [buscar, setBuscar] = useState(false);

    const [motoristaCadastro, setMotoristaCadastro] = useState(null);

    const TFD_PASSAGENS_API_BASE_URL = global.gConfig.url_base_tfd_passagens;

    const handleClickSort = (campo) => {
        const isAsc = (orderBy === campo) && (order === ORDENACAO.DIRECAO.ASC);
        const newOrder = isAsc ? ORDENACAO.DIRECAO.DESC : ORDENACAO.DIRECAO.ASC;
        setOrder(newOrder);
        setOrderBy(campo);
        setBuscar(true);
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        setBuscar(true);
    };

    const handleChangeRowsPerPage = event => {
        setCount(event.target.value);
        setPage(global.gConfig.pagination.start);
        setBuscar(true);
    };

    const callback = () => {
        setMotoristaCadastro(null);
    }

    const callbackSucesso = () => {
        setBuscar(true);
        setMotoristaCadastro(null);
    }

    useEffect(() => {
        if (buscar) {
            setBuscaEmAndamento(true);

            let params = {};

            if (orderBy && order) {
                params.sort = orderBy;
                params.direction = order;
            }

            const offset = (page * count);
            params.start = offset;
            params.count = count;

            axios.get(`${TFD_PASSAGENS_API_BASE_URL}/motorista`, { params, headers: getHeaders() })
                .then((response) => {
                    const { data } = response.data;
                    setTotalRegistros(parseInt(data.totalRegistros));
                    setSolicitacoes(data.itens);
                })
                .catch(err => {
                    setTotalRegistros(parseInt(0));
                    setSolicitacoes([]);
                })
                .finally(() => {
                    setBuscaEmAndamento(false);
                    setBuscar(false);
                });
        }
    }, [buscar]);

    useEffect(() => {
        setBuscar(true);
    }, []);

    return (
        <>
            <div className={classes.buttonWrapper}>
                <SttButton
                    type="button"
                    variant="contained"
                    color="primary"
                    nomarginleft="true"
                    onClick={() => {
                        setMotoristaCadastro({
                            nome: '',
                            cpf: '',
                            ativo: true,
                            matricula: '',
                            novo: true,
                        });
                    }}
                >
                    {strings.adicionar}
                </SttButton>
            </div>
            <TableContainer className={classes.tableWrapper} component={Paper}>
                <SttTable>
                    <SttTableHead>
                        <SttTableRow>
                            <SttHidden xsDown>
                                <SttTableCell width="45%" sortDirection={orderBy === ORDENACAO.CAMPOS.NOME ? order : false}>
                                    <SttTableSortLabel
                                        active={orderBy === ORDENACAO.CAMPOS.NOME}
                                        direction={orderBy === ORDENACAO.CAMPOS.NOME ? order : ORDENACAO.DIRECAO.ASC}
                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.NOME)}>
                                        {strings.nome}
                                    </SttTableSortLabel>
                                </SttTableCell>
                                <SttTableCell width="20%" sortDirection={orderBy === ORDENACAO.CAMPOS.CPF ? order : false}>
                                    <SttTableSortLabel
                                        active={orderBy === ORDENACAO.CAMPOS.CPF}
                                        direction={orderBy === ORDENACAO.CAMPOS.CPF ? order : ORDENACAO.DIRECAO.ASC}
                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.CPF)}>
                                        {strings.cpf}
                                    </SttTableSortLabel>
                                </SttTableCell>
                                <SttTableCell width="20%" sortDirection={orderBy === ORDENACAO.CAMPOS.MATRICULA ? order : false}>
                                    <SttTableSortLabel
                                        active={orderBy === ORDENACAO.CAMPOS.MATRICULA}
                                        direction={orderBy === ORDENACAO.CAMPOS.MATRICULA ? order : ORDENACAO.DIRECAO.ASC}
                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.MATRICULA)}>
                                        {strings.matricula}
                                    </SttTableSortLabel>
                                </SttTableCell>
                                <SttTableCell width="10%" sortDirection={orderBy === ORDENACAO.CAMPOS.ATIVO ? order : false}>
                                    <SttTableSortLabel
                                        active={orderBy === ORDENACAO.CAMPOS.ATIVO}
                                        direction={orderBy === ORDENACAO.CAMPOS.ATIVO ? order : ORDENACAO.DIRECAO.ASC}
                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.ATIVO)}>
                                        {strings.ativo}
                                    </SttTableSortLabel>
                                </SttTableCell>
                                <SttTableCell width="5%" align="center">
                                    {strings.alterar}
                                </SttTableCell>
                            </SttHidden>
                            <SttHidden smUp>
                                <SttTableCell width="100%" colSpan="2">{strings.motoristas}</SttTableCell>
                            </SttHidden>
                        </SttTableRow>
                    </SttTableHead>
                    <SttTableBody>
                        {
                            buscaEmAndamento
                                ?
                                <SttTableRow key={-1}>
                                    <SttTableCell colSpan={5}
                                        align="center">
                                        <SttCircularProgress />
                                    </SttTableCell>
                                </SttTableRow>
                                :
                                (
                                    solicitacoes.length === 0 ?
                                        <SttTableRow key={-1}>
                                            <SttTableCell colSpan={8} align="center">
                                                {strings.nenhumRegistroEncontrado}
                                            </SttTableCell>
                                        </SttTableRow>
                                        :
                                        solicitacoes.map((row, index) => (
                                            <SttTableRow hover key={index}>
                                                <SttHidden xsDown>
                                                    <SttTableCell>{row.nome}</SttTableCell>
                                                    <SttTableCell>{Util.util.adicionarMascara(`000${row.cpf}`, '000.000.000-00', { reverse: true })}</SttTableCell>
                                                    <SttTableCell>{row.matricula}</SttTableCell>
                                                    <SttTableCell>
                                                        <Chip
                                                            className={classes.chip}
                                                            style={{ backgroundColor: row.ativo ? '#8AC58B' : '#D88080' }}
                                                            label={row.ativo ? strings.sim : strings.nao}
                                                        />
                                                    </SttTableCell>
                                                </SttHidden>
                                                <SttHidden smUp>
                                                    <SttTableCell width="96%">
                                                        <SttText size="small"><b>{strings.nome}:</b> {row.nome}</SttText>
                                                        <SttText size="small"><b>{strings.cpf}:</b> {Util.util.adicionarMascara(`000${row.cpf}`, '000.000.000-00', { reverse: true })}</SttText>
                                                        <SttText size="small"><b>{strings.matricula}:</b> {row.matricula}</SttText>
                                                        <SttText size="small"><b>{strings.ativo}:</b> {row.ativo ? strings.sim : strings.nao}</SttText>
                                                    </SttTableCell>
                                                </SttHidden>
                                                <SttTableCell align="center">
                                                    <SttEditButton
                                                        id={`btn-ver-solicitacao-${index}`}
                                                        onClick={() => {
                                                            row.novo = false;
                                                            setMotoristaCadastro(row);
                                                        }}
                                                    />
                                                </SttTableCell>
                                            </SttTableRow>
                                        ))
                                )
                        }
                    </SttTableBody>
                </SttTable>
            </TableContainer>
            {
                solicitacoes.length > 0 &&
                <SttTablePagination
                    rowsPerPageOptions={[10, 20, 40]}
                    component="div"
                    count={totalRegistros}
                    rowsPerPage={count}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage={strings.linhasPorPagina}
                    labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${strings.de} ${count}`}
                />
            }
            {
                motoristaCadastro &&
                <FormMotorista callback={callback}
                    callbackSucesso={callbackSucesso}
                    motorista={motoristaCadastro}
                />
            }
        </>

    )
}

export default PesquisaMotorista;