'use strict';

const Wreck = require('@hapi/wreck');
const StringMask = require('string-mask');
const { TIPO_CONTATO } = require('../constantes/index');

const Util = {

    verificarMaioridade: async (dataNascimento) => {
        const idade = await _geradorIdade(dataNascimento);
        return idade > 18;
    },

    verificarSenha: async (senha) => {
        if (senha.length < 8) {
            return false;
        }

        if (senha.includes(' ') || /(.)\1/.test(senha)) {
            return false;
        }

        if (!/[A-Z]/.test(senha)) {
            return false;
        }

        if (!/\d/.test(senha)) {
            return false;
        }

        if (!/[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/.test(senha)) {
            return false;
        }

        return true;
    },

    gerarTokenApiTelessaude: async (config) => {
        const payload_ = {
            'grant_type': 'client_credentials',
            'client_id': config.api_telessaude_client_id,
            'client_secret': config.api_telessaude_client_secret
        };
        const headers = { 'Content-Type': 'application/json' };
        const { payload } = await Wreck.post(
            `${config.url_base_api_telessaude}/oauth2/auth/token?grant_type=client_credentials`,
            { payload: payload_, headers, timeout: 10000 }
        );
        const authorization = JSON.parse(payload.toString());
        return `${authorization['token_type']} ${authorization['access_token']}`;
    },

    removerMascaraNumero: async (inputComMascara) => {
        if (!inputComMascara) {
            return null;
        }
        return inputComMascara.replace(/\D/g, '');
    },

    adicionarMascara: (input, mask, opcoes) => {
        let formatter;
        if (opcoes) {
            formatter = new StringMask(mask, opcoes);
        } else {
            formatter = new StringMask(mask);
        }

        return formatter.apply(input);
    },

    adicionarMascaraContato: (contato, categoria) => {

        switch (categoria) {
            case TIPO_CONTATO.TELEFONE_RESIDENCIAL:
            case TIPO_CONTATO.TELEFONE_COMERCIAL:
                contato = contato.replace(/[^a-zA-Z0-9]/g, '');
                return Util.adicionarMascara(contato, '(00) 0000-0000', { reverse: true })

            case TIPO_CONTATO.TELEFONE_CELULAR:
                contato = contato.replace(/[^a-zA-Z0-9]/g, '');
                return Util.adicionarMascara(contato, '(00) 00000-0000', { reverse: true })

            default:
                return contato;
        }
    },

    trimHtmlCkeditor: (campo) => {
        if (!campo) {
            return null;
        }

        campo = campo.replace(/^(<p>&nbsp;<\/p>)*|(<p>&nbsp;<\/p>)+$/g, '');

        return campo || null;
    }

};

const _geradorIdade = async (dataNascimento) => {
    return Math.floor((new Date() - new Date(dataNascimento).getTime()) / 3.15576e+10);
};

module.exports = Util;