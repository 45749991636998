import React, { useRef, useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
    SttButton,
    SttLoading,
    SttGrid,
    SttInput,
    SttAutocomplete,
    SttTranslateHook
} from '@stt-componentes/core';
import {
    Formik,
    FastField,
    Field
} from 'formik';
import HttpStatus from 'http-status-codes';
import * as yup from 'yup';
import axios from 'axios';
import { getHeaders } from '../../../request';
import { PERFIL } from '../../../common/Constants';

const initialValues = {
    justificativa: '',
    regulador: null
};

const validationSchema = (strings) => {
    return yup.object().shape({
        'justificativa': yup
            .string()
            .min(10, strings.justificativaInvalida)
            .trim()
            .nullable()
            .required(strings.campoObrigatorio),
        'regulador': yup
            .object().shape({
                id: yup.number(),
                descricao: yup.string(),
                exige_complemento: yup.string(),
                etapa_fluxo: yup.string(),
            })
            .nullable()
            .required(strings.campoObrigatorio)
    });
}

const useStyles = makeStyles(theme => ({
    button: {
        marginBottom: theme.spacing(1)
    }
}));

const OperacaoEncaminharRegulador = ({ callback, callbackSucesso, id, alerta }) => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const schema = validationSchema(strings);
    const formRef = useRef();
    const classes = useStyles();

    const TFD_PASSAGENS_API_BASE_URL = global.gConfig.url_base_tfd_passagens;
    const UTILITARIOS_API_BASE_URL = global.gConfig.url_base_utilitarios;

    const [reguladores, setReguladores] = useState([]);

    //Alerta 
    const {
        setMostrarAlerta,
        setTituloAlerta,
        setMensagemAlerta,
        setTipoAlerta,
        setOpcoesAlerta,
        setOnCloseAlerta
    } = alerta;

    useEffect(() => {
        axios.get(`${UTILITARIOS_API_BASE_URL}/funcionario?sigla=${PERFIL.TFD_REGULADOR}`, { headers: getHeaders() })
            .then((response) => {
                if (response.data) {
                    const { itens } = response.data.data;
                    setReguladores(itens);
                }
            })
            .catch(err => console.log(err));
    }, []);

    return (
        <Formik
            innerRef={formRef}
            initialValues={initialValues}
            validationSchema={schema}
            onSubmit={(dados, { setSubmitting, resetForm }) => {
                setSubmitting(true);

                dados.justificativa = dados.justificativa.trim();
                dados.id = id;

                axios.post(`${TFD_PASSAGENS_API_BASE_URL}/selecionar-regulador-processo`, dados, { headers: getHeaders() })
                    .then((response) => {

                        const { data } = response;
                        if (response.status === HttpStatus.OK) {
                            setTituloAlerta(strings.sucesso);
                            setMensagemAlerta(data.message ? data.message : strings.operacaoSucesso);
                            setTipoAlerta('success');
                            setOpcoesAlerta([
                                {
                                    title: strings.ok,
                                    onClick: () => {
                                        setMostrarAlerta(false);
                                        resetForm({ values: initialValues });
                                        callbackSucesso();
                                    }
                                }
                            ]);
                            setOnCloseAlerta(() => callbackSucesso);
                            setMostrarAlerta(true);
                        } else {
                            setTituloAlerta(strings.erro);
                            setMensagemAlerta(data && data.message ? data.message : strings.msgErroEncaminharRegulador);
                            setTipoAlerta('error');
                            setOpcoesAlerta([
                                {
                                    title: strings.ok,
                                    onClick: () => {
                                        setMostrarAlerta(false);
                                    }
                                }
                            ]);
                            setMostrarAlerta(true);
                        }
                    })
                    .catch(err => {
                        console.log(err);
                        const { data } = err;
                        setTituloAlerta(strings.erro);
                        setMensagemAlerta(data && data.message ? data.message : strings.msgErroEncaminharRegulador);
                        setTipoAlerta('error');
                        setOpcoesAlerta([
                            {
                                title: strings.ok,
                                onClick: () => {
                                    setMostrarAlerta(false);
                                }
                            }
                        ]);
                        setMostrarAlerta(true);
                    })
                    .finally(() => {
                        setSubmitting(false);
                    });
            }}
        >
            {
                ({
                    isSubmitting,
                    handleSubmit,
                    resetForm,
                }) => {
                    return (
                        <form onSubmit={handleSubmit} noValidate>
                            <SttGrid container spacing={3}>
                                <SttGrid item xs={12}>
                                    <Field name='regulador'>
                                        {({
                                            field: { name, value, onBlur },
                                            form: { setFieldValue },
                                            meta,
                                        }) => (
                                            <SttAutocomplete
                                                inputprops={{
                                                    name: name,
                                                    label: strings.regulador,
                                                    required: true,
                                                    error: meta.touched && meta.error ? meta.error : undefined
                                                }}
                                                getOptionLabel={option => (option && option.nome) || ''}
                                                getOptionSelected={(option, val) => option && val && option.id === val.id}
                                                options={reguladores}
                                                value={value}
                                                onBlur={onBlur}
                                                onChange={(e, item) => setFieldValue('regulador', item || null)}
                                            />
                                        )}
                                    </Field>
                                </SttGrid>
                                <SttGrid item xs={12}>
                                    <FastField name='justificativa'>
                                        {({
                                            field,
                                            meta
                                        }) => (
                                            <SttInput
                                                multiline
                                                rows={5}
                                                required={true}
                                                {...field}
                                                onInput={(e) => e.target.value = ("" + e.target.value).toUpperCase()}
                                                label={strings.justificativa}
                                                error={meta.touched && meta.error ? true : false}
                                                helperText={meta.touched && meta.error ? meta.error : undefined}
                                            />
                                        )}
                                    </FastField>
                                </SttGrid>
                                <SttLoading
                                    open={isSubmitting}
                                    text={strings.salvando}
                                />
                            </SttGrid>
                            <SttGrid container spacing={3}>
                                <SttGrid item xs={12}>
                                    <SttButton
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        nomarginleft="true"
                                        className={classes.button}
                                        disabled={isSubmitting}
                                    >
                                        {strings.confirmar}
                                    </SttButton>
                                    <SttButton
                                        type="button"
                                        variant="outlined"
                                        color="primary"
                                        disabled={isSubmitting}
                                        className={classes.button}
                                        onClick={() => {
                                            resetForm({ values: initialValues });
                                        }}
                                    >
                                        {strings.limpar}
                                    </SttButton>
                                    <SttButton
                                        type="button"
                                        variant="outlined"
                                        color="primary"
                                        disabled={isSubmitting}
                                        className={classes.button}
                                        onClick={() => {
                                            resetForm({ values: initialValues });
                                            callback();
                                        }}
                                    >
                                        {strings.fechar}
                                    </SttButton>
                                </SttGrid>
                            </SttGrid>
                        </form>
                    )
                }
            }
        </Formik>
    );
}

export default OperacaoEncaminharRegulador;