export const ACOMPANHANTE = 'acompanhante';
export const NUMERO_ACOMPANHANTES = 'numeroAcompanhantes';
export const NUMERO_ACOMPANHANTES_ORIGINAL = 'numeroAcompanhantesOriginal';
export const TROCA_ACOMPANHANTE = 'trocaAcompanhante';
export const ACOMPANHANTE_MODIFICADO = 'acompanhanteModificado';
export const JUSTIFICATIVA = 'justificativa';
export const ANEXOS = 'anexos';
export const ANEXOS_EXCLUIDOS = 'anexosExcluidos';
// ACOMPANHANTES
export const ACOMPANHANTES = 'acompanhantes';
export const NOME = 'nome';
export const CPF = 'cpf';
export const CNS = 'cns';
export const RACA = 'raca';
export const DATA_NASCIMENTO = 'dataNascimento';
export const DOADOR = 'doador';
export const CEP = 'cep';
export const LOGRADOURO = 'logradouro';
export const NUMERO = 'numero';
export const BAIRRO = 'bairro';
export const COMPLEMENTO = 'complemento';
export const CIDADE = 'cidade';
export const IBGE_CIDADE = 'ibgeCidade';
export const ID_CIDADE = 'idCidade';
export const UF = 'uf';
export const PARENTESCO = 'parentesco';
export const EXIGE_COMPLEMENTO = 'exigeComplemento';
export const COMPLEMENTO_PARENTESCO = 'complementoParentesco';
export const SEXO = 'sexo';
