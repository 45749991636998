import * as yup from 'yup';
import { TRANSPORTE_RECOMENDAVEL } from '../../../../common/Constants';
import {
    TRANSPORTE,
    TIPO_TRANSPORTE,
    UF_ORIGEM,
    CIDADE_ORIGEM,
    UF_DESTINO,
    CIDADE_DESTINO,
    JUSTIFICATIVA,
    ANEXOS,
    TRANSPORTE_MODIFICADO,
    PONTO_REFERENCIA
} from './fieldNames';

export default (strings) => {
    return yup.object().shape({
        [TRANSPORTE]: yup.object().shape({
            [TIPO_TRANSPORTE]: yup
                .object().shape({
                    id: yup.number(),
                    descricao: yup.string(),
                    sigla: yup.string(),
                    exige_justificativa: yup.boolean(),
                })
                .nullable()
                .required(strings.campoObrigatorio),
            [UF_ORIGEM]: yup
                .string()
                .nullable()
                .required(strings.campoObrigatorio),
            [CIDADE_ORIGEM]: yup
                .string()
                .nullable()
                .required(strings.campoObrigatorio),
            [UF_DESTINO]: yup
                .string()
                .nullable()
                .required(strings.campoObrigatorio),
            [CIDADE_DESTINO]: yup
                .string()
                .nullable()
                .required(strings.campoObrigatorio),
            [PONTO_REFERENCIA]: yup
                .string()
                .nullable()
                .when(TIPO_TRANSPORTE, {
                    is: (val) => val?.sigla === TRANSPORTE_RECOMENDAVEL.AMBULANCIA,
                    then: yup
                        .string()
                        .nullable()
                        .trim()
                        .required(strings.campoObrigatorio),
                }),
            [JUSTIFICATIVA]: yup
                .string()
                .nullable()
                .trim()
                .when(TRANSPORTE_MODIFICADO, {
                    is: (val) => val,
                    then: yup
                        .string()
                        .when(ANEXOS, {
                            is: (val) => !val || val.length === 0 || (val.some(a => (!(a instanceof File) || a.id))),
                            then: yup
                                .string()
                                .required(strings.obrigatorioJustificativaTextual)
                        })
                }),
            [ANEXOS]: yup
                .array()
                .when(TRANSPORTE_MODIFICADO, {
                    is: (val) => val,
                    then: yup
                        .array()
                        .when(JUSTIFICATIVA, {
                            is: (val) => (!val || val.trim().length === 0),
                            then: yup
                                .array()
                                .test('anexo-valido', strings.obrigatorioJustificativaTextual, (anexos) => (anexos && anexos.length >= 1))
                                .test('anexo-valido', strings.obrigatorioJustificativaTextual, (anexos) => {
                                    return anexos.some(a => {
                                        return ((a instanceof File) || a.id)

                                    });
                                })
                                .required(strings.obrigatorioJustificativaTextual)
                        })
                })
                .of(
                    yup
                        .mixed()
                        .nullable()
                        .test('formatoArquivo', strings.formatoArquivoInvalido, (value) => {
                            if (!value) {
                                return true;
                            }

                            if (!value || !(value instanceof File)) {
                                return true;
                            }
                            return value && ['image/jpeg', 'image/png', 'application/pdf', 'application/msword', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'].includes(value.type);
                        })
                ),
        }).required()
    });
}