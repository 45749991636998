import React, { useRef, useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
    SttButton,
    SttLoading,
    SttGrid,
    SttMaskedInput,
    SttNumberInput,
    SttFormControl,
    SttRadioGroup,
    SttFormControlLabel,
    SttRadioButton,
    SttHeading,
    SttInput,
    SttModal,
    SttAlerta,
    SttTranslateHook
} from '@stt-componentes/core';
import {
    Formik,
    FastField,
    Field
} from 'formik';
import { validateBr } from 'js-brasil';
import HttpStatus from 'http-status-codes';
import * as yup from 'yup';
import axios from 'axios';
import { getHeaders } from '../../../request';

const validationSchema = (strings) => {
    return yup.object().shape({
        'nome': yup
            .string()
            .trim()
            .min(1)
            .max(255)
            .matches(/(\w.+\s).+/i, strings.informeNomeCompleto)
            .nullable()
            .required(strings.campoObrigatorio),
        'cpf': yup
            .string()
            .test('cpf-valido', strings.cpfInvalido, (cpf) => {
                cpf = (cpf || '').replace(/\D+/g, '');
                if (cpf.length === 0) {
                    return true;
                }
                return validateBr.cpf(cpf);
            })
            .nullable()
            .required(strings.campoObrigatorio),
        'matricula': yup
            .string()
            .min(1)
            .max(20)
            .nullable()
            .required(strings.campoObrigatorio),
    });
}

const useStyles = makeStyles(theme => ({
    button: {
        marginBottom: theme.spacing(1)
    }
}));

const FormMotorista = ({ callback, callbackSucesso, motorista }) => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const schema = validationSchema(strings);
    const formRef = useRef();
    const classes = useStyles();

    const TFD_PASSAGENS_API_BASE_URL = global.gConfig.url_base_tfd_passagens;

    const handleCloseAlerta = () => {
        setMostrarAlerta(false);
    }

    //Alerta 
    const [mostrarAlerta, setMostrarAlerta] = useState(false);
    const [tituloAlerta, setTituloAlerta] = useState('');
    const [mensagemAlerta, setMensagemAlerta] = useState('');
    const [tipoAlerta, setTipoAlerta] = useState('alert');
    const [opcoesAlerta, setOpcoesAlerta] = useState([]);
    const [onCloseAlerta, setOnCloseAlerta] = useState(() => handleCloseAlerta);

    return (
        <>
            <SttModal
                title={strings.cadastroMotorista}
                open={true}
                maxWidth="md"
                outModalClose={callback}
                iconClose={callback}
                fullWidth={true}
                children={
                    <Formik
                        innerRef={formRef}
                        initialValues={motorista}
                        validationSchema={schema}
                        onSubmit={(dados, { setSubmitting }) => {
                            setSubmitting(true);

                            axios.post(`${TFD_PASSAGENS_API_BASE_URL}/motorista`, dados, { headers: getHeaders() })
                                .then((response) => {
                                    const { data } = response;
                                    if (response.status === HttpStatus.OK) {
                                        setTituloAlerta(strings.sucesso);
                                        setMensagemAlerta(data?.message ? data.message : strings.operacaoSucesso);
                                        setTipoAlerta('success');
                                        setOpcoesAlerta([
                                            {
                                                title: strings.ok,
                                                onClick: () => {
                                                    setMostrarAlerta(false);
                                                    callbackSucesso();
                                                }
                                            }
                                        ]);
                                        setOnCloseAlerta(() => callbackSucesso);
                                    } else {
                                        setTituloAlerta(strings.erro);
                                        setMensagemAlerta(data?.message ? data.message : strings.erroSalvarMotorista);
                                        setTipoAlerta('error');
                                        setOpcoesAlerta([
                                            {
                                                title: strings.ok,
                                                onClick: () => {
                                                    setMostrarAlerta(false);
                                                }
                                            }
                                        ]);
                                    }
                                })
                                .catch(err => {
                                    const { response } = err;
                                    let mensagem = strings.erro;
                                    if (response) {
                                        const { data } = response;

                                        mensagem = data?.message ? data.message : strings.erroSalvarMotorista;
                                        if (response.status === HttpStatus.BAD_REQUEST || response.status === HttpStatus.INTERNAL_SERVER_ERROR) {
                                            let arrMensagem = [];
                                            data.errors.forEach(error => {
                                                arrMensagem.push(`- ${error.message}`);
                                            });
                                            if (arrMensagem.length > 0) {
                                                mensagem = arrMensagem.join('\n');
                                            }
                                        }
                                    }

                                    setTituloAlerta(strings.erro);
                                    setMensagemAlerta(mensagem);
                                    setTipoAlerta('error');
                                    setOpcoesAlerta([
                                        {
                                            title: strings.ok,
                                            onClick: () => {
                                                setMostrarAlerta(false);
                                            }
                                        }
                                    ]);
                                })
                                .finally(() => {
                                    setSubmitting(false);
                                    setMostrarAlerta(true);
                                });
                        }}
                    >
                        {
                            ({
                                isSubmitting,
                                handleSubmit,
                                resetForm,
                            }) => {
                                return (
                                    <form onSubmit={handleSubmit} noValidate>
                                        <SttGrid container spacing={3}>
                                            <SttGrid item xs={12} sm={6}>
                                                <FastField name="nome">
                                                    {({
                                                        meta,
                                                        field,
                                                    }) => (
                                                        <SttInput
                                                            inputProps={{
                                                                maxLength: 255
                                                            }}
                                                            {...field}
                                                            required
                                                            label={strings.nomeCompleto}
                                                            error={meta.touched && meta.error ? true : false}
                                                            helperText={meta.touched && meta.error ? meta.error : undefined}
                                                        />
                                                    )}
                                                </FastField>
                                            </SttGrid>
                                            <SttGrid item xs={12} sm={6}>
                                                <FastField name="cpf">
                                                    {({
                                                        meta,
                                                        field,
                                                    }) => (
                                                        <SttMaskedInput
                                                            {...field}
                                                            mask="cpf"
                                                            required
                                                            disabled={!motorista.novo}
                                                            label={strings.cpf}
                                                            error={meta.touched && meta.error ? true : false}
                                                            helperText={meta.touched && meta.error ? meta.error : undefined}
                                                        />
                                                    )}
                                                </FastField>
                                            </SttGrid>
                                            <SttGrid item xs={12} sm={6}>
                                                <FastField name="matricula">
                                                    {({
                                                        field,
                                                        meta,
                                                    }) => (
                                                        <SttNumberInput
                                                            inputProps={{
                                                                maxLength: 20
                                                            }}
                                                            {...field}
                                                            label={strings.matricula}
                                                            required
                                                            error={meta.touched && meta.error ? true : false}
                                                            helperText={meta.touched && meta.error ? meta.error : undefined}
                                                        />
                                                    )}
                                                </FastField>
                                            </SttGrid>
                                            <SttGrid item xs={12} sm={6}>
                                                <SttHeading required variant="h5" color="primary">{strings.ativo}</SttHeading>
                                                <Field name='ativo'>
                                                    {({
                                                        field,
                                                        form: { setFieldValue },
                                                    }) => (
                                                        <SttFormControl variant="outlined" disabled={motorista.novo}>
                                                            <SttRadioGroup row
                                                                onChange={(e, item) => {
                                                                    setFieldValue('ativo', item === 'true')
                                                                }
                                                                }
                                                            >
                                                                <SttFormControlLabel
                                                                    {...field}
                                                                    control={
                                                                        <SttRadioButton
                                                                            type="radio"
                                                                            value={true}
                                                                            color="primary"
                                                                            checked={field.value}
                                                                        />
                                                                    }
                                                                    label={strings.sim}
                                                                />
                                                                <SttFormControlLabel
                                                                    {...field}
                                                                    control={
                                                                        <SttRadioButton
                                                                            type="radio"
                                                                            value={false}
                                                                            color="primary"
                                                                            checked={!field.value}
                                                                        />
                                                                    }
                                                                    label={strings.nao}
                                                                />
                                                            </SttRadioGroup>
                                                        </SttFormControl>
                                                    )}
                                                </Field>
                                            </SttGrid>
                                        </SttGrid>
                                        <SttLoading
                                            open={isSubmitting}
                                            text={strings.salvandoMensagemEspera}
                                        />
                                        <SttGrid container spacing={3}>
                                            <SttGrid item xs={12}>
                                                <SttButton
                                                    type="submit"
                                                    variant="contained"
                                                    color="primary"
                                                    nomarginleft="true"
                                                    className={classes.button}
                                                    disabled={isSubmitting}
                                                >
                                                    {strings.salvar}
                                                </SttButton>
                                                <SttButton
                                                    type="button"
                                                    variant="outlined"
                                                    color="primary"
                                                    disabled={isSubmitting}
                                                    className={classes.button}
                                                    onClick={() => {
                                                        resetForm({ values: motorista });
                                                    }}
                                                >
                                                    {strings.limpar}
                                                </SttButton>
                                                <SttButton
                                                    type="button"
                                                    variant="outlined"
                                                    color="primary"
                                                    disabled={isSubmitting}
                                                    className={classes.button}
                                                    onClick={() => {
                                                        callback();
                                                    }}
                                                >
                                                    {strings.fechar}
                                                </SttButton>
                                            </SttGrid>
                                        </SttGrid>
                                    </form>
                                )
                            }
                        }
                    </Formik>
                }
            />
            <SttAlerta
                open={mostrarAlerta}
                title={tituloAlerta}
                message={mensagemAlerta}
                type={tipoAlerta}
                options={opcoesAlerta}
                onClose={onCloseAlerta}
            />
        </>
    );
}

export default FormMotorista;