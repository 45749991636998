import React, { useRef, memo, useContext } from "react";
import { makeStyles } from '@material-ui/core/styles';
import {
    SttButton,
    SttLoading,
    SttGrid,
    SttFileSelect,
    SttHeading,
    SttDatePicker,
    SttTranslateHook
} from '@stt-componentes/core';
import {
    Formik,
    Field,
    FastField,
    FieldArray
} from 'formik';
import HttpStatus from 'http-status-codes';
import * as yup from 'yup';
import axios from 'axios';
import { getHeaders } from '../../../request';

const validationSchema = (strings) => {
    return yup.object().shape({
        'dataVolta': yup
            .date()
            .nullable()
            .when('altaHospitalar', {
                is: (val) => val,
                then: yup
                    .date()
                    .typeError(strings.dataInvalida)
                    .nullable()
                    .required(strings.campoObrigatorio)
            }),
    });
}

const useStyles = makeStyles(theme => ({
    botaoMais: {
        minWidth: 0,
        marginLeft: 0
    },
    divExternaImagens: {
        marginTop: theme.spacing(0.5),
        marginBottom: theme.spacing(0.5),
        overflowY: 'hidden'
    },
    botaoMenos: {
        minWidth: 0
    },
    container: {
        marginBottom: 0
    },
    button: {
        marginBottom: theme.spacing(1)
    }
}));

const FileSelect = memo((props) => {
    return (
        <SttFileSelect {...props} headers={getHeaders()} />
    )
});

const OperacaoAnexarPassagem = ({ callback, callbackSucesso, alerta, tfd }) => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const classes = useStyles();
    const schema = validationSchema(strings);
    const formRef = useRef();

    //Alerta 
    const {
        setMostrarAlerta,
        setTituloAlerta,
        setMensagemAlerta,
        setTipoAlerta,
        setOpcoesAlerta,
        setOnCloseAlerta
    } = alerta;

    const initialValues = {
        anexosPassagem: [{}],
        altaHospitalar: tfd.alta_hospitalar,
        dataVolta: null,
    };

    const validarAnexo = (value) => {
        let error;

        if (!value || !(value instanceof File)) {
            return null;
        }
        if (value.type !== 'image/jpeg' && value.type !== 'image/png' && value.type !== 'application/pdf' && value.type !== 'application/msword' &&
            value.type !== 'application/vnd.ms-excel' && value.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
            return strings.formatoArquivoInvalido;
        }
        return error;
    }

    return (
        <Formik
            innerRef={formRef}
            initialValues={initialValues}
            validationSchema={schema}
            onSubmit={(dados, { setSubmitting, resetForm }) => {
                setSubmitting(true);

                const formData = new FormData();
                formData.append('id', tfd.id);

                if (dados.dataVolta) {
                    formData.append('dataVolta', dados.dataVolta);
                }

                dados.anexosPassagem.forEach((anexo, index) => {
                    if (anexo && (anexo instanceof File)) {
                        formData.append(`anexosPassagem.${index}`, anexo);
                    }
                });

                const TFD_PASSAGENS_API_BASE_URL = global.gConfig.url_base_tfd_passagens;

                axios.post(`${TFD_PASSAGENS_API_BASE_URL}/anexar-passagem-processo`, formData,  { headers: { ...getHeaders(), 'Content-Type': 'multipart/form-data' } })
                    .then((response) => {
                        const { data } = response;
                        if (response.status === HttpStatus.OK) {
                            setTituloAlerta(strings.sucesso);
                            setMensagemAlerta(data.message ? data.message : strings.operacaoSucesso);
                            setTipoAlerta('success');
                            setOpcoesAlerta([
                                {
                                    title: strings.ok,
                                    onClick: () => {
                                        setMostrarAlerta(false);
                                        resetForm({ values: initialValues });
                                        callbackSucesso();
                                    }
                                }
                            ]);
                            setMostrarAlerta(true);
                            setOnCloseAlerta(() => callbackSucesso);
                        } else {
                            setTituloAlerta(strings.erro);
                            setMensagemAlerta(data && data.message ? data.message : strings.msgErroAnexarComprovante);
                            setTipoAlerta('error');
                            setOpcoesAlerta([
                                {
                                    title: strings.ok,
                                    onClick: () => {
                                        setMostrarAlerta(false);
                                    }
                                }
                            ]);
                            setMostrarAlerta(true);
                        }


                    })
                    .catch(err => {
                        console.log(err);
                        const { data } = err;
                        setTituloAlerta(strings.erro);
                        setMensagemAlerta(data && data.message ? data.message : strings.msgErroAnexarComprovante);
                        setTipoAlerta('error');
                        setOpcoesAlerta([
                            {
                                title: strings.ok,
                                onClick: () => {
                                    setMostrarAlerta(false);
                                }
                            }
                        ]);
                        setMostrarAlerta(true);
                    })
                    .finally(() => {
                        setSubmitting(false);
                    });
            }}
        >
            {
                ({
                    isSubmitting,
                    handleSubmit,
                    resetForm,
                    values
                }) => {
                    return (
                        <form onSubmit={handleSubmit} noValidate>
                            <SttGrid container spacing={3} className={classes.container}>
                                {
                                    tfd.alta_hospitalar &&
                                    <SttGrid item sm={12} md={4}>
                                        <FastField name='dataVolta'>
                                            {({
                                                field: { name, value },
                                                form: { setFieldValue, setFieldError, setFieldTouched },
                                                meta
                                            }) => {
                                                return (
                                                    <SttDatePicker
                                                        label={strings.dataRetorno}
                                                        inputprops={{
                                                            name: name
                                                        }}
                                                        error={(meta.touched && meta.error) ? true : false}
                                                        onError={error => {
                                                            setFieldError('dataVolta', meta.error || error);
                                                        }}
                                                        required
                                                        value={value}
                                                        onBlur={() => {
                                                            setFieldTouched('dataVolta', true);
                                                        }}
                                                        helperText={(meta.touched && meta.error) ? meta.error : undefined}
                                                        onChange={date => setFieldValue('dataVolta', date, true)}
                                                        onClose={() => setFieldTouched('dataVolta', true)}
                                                    />
                                                );
                                            }}
                                        </FastField>
                                    </SttGrid>
                                }
                                <SttGrid item xs={12}>
                                    <SttHeading variant="h5" color="primary">{strings.anexosPassagens}</SttHeading>
                                    <FieldArray name="anexosPassagem" render={
                                        ({ remove, push }) => (
                                            <>
                                                <div className={classes.divExternaImagens}>
                                                    {
                                                        values.anexosPassagem.map((anexo, indice) => (
                                                            <Field key={indice} name={`anexosPassagem.${indice}`} validate={validarAnexo}>
                                                                {({
                                                                    field: { name, value },
                                                                    form: { setFieldValue },
                                                                    meta
                                                                }) => (
                                                                    <>
                                                                        <FileSelect
                                                                            onFileChange={(event) => {
                                                                                //Caso a imagem tenha sido excluída, remove a mesma do array de imagens no formulário e retira do array de imagens
                                                                                if (!event) {
                                                                                    if (values.anexosPassagem.length > 1) {
                                                                                        remove(indice);
                                                                                        return;
                                                                                    }
                                                                                    setFieldValue(`anexosPassagem[${indice}]`, {});
                                                                                    return;
                                                                                }

                                                                                if (event.target.files[0]) {
                                                                                    //Seta o valor da imagem no campo correspondente do formulário
                                                                                    setFieldValue(`anexosPassagem[${indice}]`, event.target.files[0]);
                                                                                }
                                                                            }}
                                                                            file={value}
                                                                            inputprops={{
                                                                                name: name,
                                                                                label: strings.formatosArquivo,
                                                                                value: value?.name,
                                                                                error: meta.touched && meta.error ? meta.error : undefined
                                                                            }}
                                                                            accept={["image/*", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "application/vnd.ms-excel", "application/pdf", 'application/vnd.ms-excel']}
                                                                        />
                                                                    </>
                                                                )}
                                                            </Field>
                                                        ))
                                                    }
                                                </div>
                                                <SttButton
                                                    className={classes.botaoMais}
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => {
                                                        let chave = (values.anexosPassagem.length + 1);
                                                        let obj = {};
                                                        obj[chave] = {};
                                                        push({});
                                                    }}
                                                >
                                                    +
                                                </SttButton>
                                                <SttButton
                                                    className={classes.botaoMenos}
                                                    variant="contained"
                                                    disabled={values.anexosPassagem.length === 1}
                                                    onClick={() => {
                                                        if (values.anexosPassagem.length > 1) {
                                                            let indice = values.anexosPassagem.length - 1;
                                                            remove(indice);
                                                        }
                                                    }}
                                                    color="primary">
                                                    -
                                                </SttButton>
                                            </>
                                        )}
                                    />
                                </SttGrid>
                            </SttGrid>
                            <SttLoading
                                open={isSubmitting}
                                text={strings.salvando}
                            />
                            <SttGrid container spacing={3}>
                                <SttGrid item xs={12}>
                                    <SttButton
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        nomarginleft="true"
                                        className={classes.button}
                                        disabled={isSubmitting}
                                    >
                                        {strings.confirmar}
                                    </SttButton>
                                    <SttButton
                                        type="button"
                                        variant="outlined"
                                        color="primary"
                                        disabled={isSubmitting}
                                        className={classes.button}
                                        onClick={() => {
                                            resetForm({ values: initialValues });
                                        }}
                                    >
                                        {strings.limpar}
                                    </SttButton>
                                    <SttButton
                                        type="button"
                                        variant="outlined"
                                        color="primary"
                                        disabled={isSubmitting}
                                        className={classes.button}
                                        onClick={() => {
                                            resetForm({ values: initialValues });
                                            callback();
                                        }}
                                    >
                                        {strings.fechar}
                                    </SttButton>
                                </SttGrid>
                            </SttGrid>
                        </form>
                    )
                }
            }
        </Formik>
    );
}

export default OperacaoAnexarPassagem;