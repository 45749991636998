import { temPermissaoRBAC } from '../secutity/acl';
import {
    SITUACAO_PROCESSO,
    TIPO_PROCESSO,
    PERMISSOES,
    TRANSPORTE_RECOMENDAVEL
} from './Constants';
import moment from 'moment';
import { Util } from '@stt-utilitarios/core';
import { GERAL } from '../componentes/solicitacao/geral/form/fieldNames';
import { ITINERARIO } from '../componentes/solicitacao/itinerario/form/fieldNames';
import { PACIENTE } from '../componentes/solicitacao/paciente/form/fieldNames';
import { TRANSPORTE } from '../componentes/solicitacao/transporte/form/fieldNames';
import { ACOMPANHANTE } from '../componentes/solicitacao/acompanhante/form/fieldNames';
import { AGENDAMENTO } from '../componentes/solicitacao/agendamento/form/fieldNames';
import { ESTADUAL } from '../componentes/solicitacao/estadual/form/fieldNames';

const { SITUACAO } = SITUACAO_PROCESSO;

const acoes = (user, situacaoProcesso, tipoProcesso) => {
    let podeArquivarProcesso = false;
    let podeAlterar = false;
    let podeSalvarRascunho = false;
    let podeEncaminhar = false;
    let podeCancelar = false;
    let podeDevolverEstadual = false;
    let podeEncaminharRegulador = false;
    let podeAprovarRegulador = false;
    let podeDevolverRegulador = false;
    let podeEncaminharCompraPassagem = false;
    let podeSelecionarMotorista = false;
    let podeAgendarViagem = false;
    let podeEncaminharFaturaPassagem = false;
    let podeReabrir = false;
    let podeAutorizar = false;
    let podeSuspender = false;
    let podeVoltarProcesso = false;
    let podeImprimir = false;
    let podeCriarProcessoEspelho = false;

    if (temPermissaoRBAC(user, PERMISSOES.VISUALIZACAO)) {
        if (situacaoProcesso !== SITUACAO.RASCUNHO) {
            podeImprimir = true;
        }
    }

    if (temPermissaoRBAC(user, PERMISSOES.REGULAR)) {
        if (situacaoProcesso === SITUACAO.AGUARDANDO_AVALIACAO_REGULADOR) {
            podeAprovarRegulador = true;
            podeDevolverRegulador = true;
        }
    }

    if (temPermissaoRBAC(user, PERMISSOES.SOLICITACAO)) {
        switch (situacaoProcesso) {
            case SITUACAO.RASCUNHO:
                podeAlterar = true;
                podeSalvarRascunho = true;
                podeEncaminhar = true;
                podeCancelar = true;
                break;
            case SITUACAO.DEVOLVIDO_ESTADUAL:
            case SITUACAO.DEVOLVIDO_REGULADOR:
                podeAlterar = true;
                podeEncaminhar = true;
                podeCancelar = true;
                break;
        }

        if (tipoProcesso === TIPO_PROCESSO.TIPO.IDA && situacaoProcesso !== SITUACAO.CANCELADO) {
            podeCriarProcessoEspelho = true;
        }
    }

    if (temPermissaoRBAC(user, PERMISSOES.ENCAMINHAR_REGULADOR)) {
        podeAlterar = true;
        podeCancelar = true;

        switch (situacaoProcesso) {
            case SITUACAO.AGUARDANDO_AVALIACAO_REGULADOR:
            case SITUACAO.AGUARDANDO_COMPRA_PASSAGEM:
            case SITUACAO.PASSAGEM_COMPRADA:
            case SITUACAO.APROVADA_REGULADOR:
            case SITUACAO.FATURADO:
            case SITUACAO.VIAGEM_AGENDADA:
            case SITUACAO.AGUARDANDO_ESCALA:
                podeDevolverEstadual = true;
                break;
            case SITUACAO.AGUARDANDO_AVALIACAO_ESTADUAL:
                podeEncaminharRegulador = true;
                podeDevolverEstadual = true;
        }
    }

    if (temPermissaoRBAC(user, PERMISSOES.ENCAMINHAR)) {
        podeAlterar = true;
        podeCancelar = true;

        switch (situacaoProcesso) {
            case SITUACAO.PASSAGEM_COMPRADA:
            case SITUACAO.VIAGEM_AGENDADA:
            case SITUACAO.AGUARDANDO_ESCALA:
                podeVoltarProcesso = true;
                break;
            case SITUACAO.APROVADA_REGULADOR:
            case SITUACAO.AGUARDANDO_AVALIACAO_ESTADUAL:
                podeEncaminharCompraPassagem = true;
        }
    }

    if (temPermissaoRBAC(user, PERMISSOES.ARQUIVAMENTO)) {
        podeCancelar = true;

        switch (situacaoProcesso) {
            case SITUACAO.CANCELADO:
                podeAlterar = false;
                podeReabrir = true;
                podeCancelar = false;
                break;
            case SITUACAO.ARQUIVADO:
                podeAlterar = false;
                podeReabrir = true;
                break;
            case SITUACAO.AGUARDANDO_AVALIACAO_ESTADUAL:
            case SITUACAO.AGUARDANDO_COMPRA_PASSAGEM:
            case SITUACAO.PASSAGEM_COMPRADA:
            case SITUACAO.APROVADA_REGULADOR:
            case SITUACAO.FATURADO:
            case SITUACAO.VIAGEM_AGENDADA:
            case SITUACAO.AGUARDANDO_ESCALA:
                podeArquivarProcesso = true;
        }
    }

    if (temPermissaoRBAC(user, PERMISSOES.MOTORISTA)) {
        if (situacaoProcesso === SITUACAO.AGUARDANDO_ESCALA) {
            podeSelecionarMotorista = true;
        }
    }

    if (temPermissaoRBAC(user, PERMISSOES.FATURA)) {
        switch (situacaoProcesso) {
            case SITUACAO.AGUARDANDO_COMPRA_PASSAGEM:
                podeEncaminharFaturaPassagem = true;
                break;
            case SITUACAO.PASSAGEM_COMPRADA:
            case SITUACAO.FATURADO:
                podeAutorizar = true;
        }
    }

    if (temPermissaoRBAC(user, PERMISSOES.SUSPENDER)) {
        switch (situacaoProcesso) {
            case SITUACAO.PASSAGEM_COMPRADA:
            case SITUACAO.FATURADO:
                podeSuspender = true;
            case SITUACAO.SUSPENSO:
                podeEncaminharFaturaPassagem = true;
                podeArquivarProcesso = true;
                podeCancelar = true;
                podeAutorizar = true;
        }
    }

    return {
        podeArquivarProcesso,
        podeAlterar,
        podeSalvarRascunho,
        podeEncaminhar,
        podeCancelar,
        podeDevolverEstadual,
        podeEncaminharRegulador,
        podeAprovarRegulador,
        podeDevolverRegulador,
        podeEncaminharCompraPassagem,
        podeSelecionarMotorista,
        podeAgendarViagem,
        podeEncaminharFaturaPassagem,
        podeReabrir,
        podeAutorizar,
        podeVoltarProcesso,
        podeImprimir,
        podeCriarProcessoEspelho,
        podeSuspender
    }
};

const formatarTfdParaAlteracao = (tfd) => {
    const tfdFormatado = {
        id: tfd.id,
        idTfd: tfd.id_tfd,
        situacao: tfd.situacao,
        sequencial: tfd.sequencial,
        geral: {},
        itinerario: {},
        paciente: {},
        transporte: {},
        acompanhante: {},
        agendamento: {},
        estadual: {
            motivo: '',
            anexos: []
        }
    };

    // Dados gerais
    tfdFormatado.geral.requisicao = tfd.requisicao;
    tfdFormatado.geral.dataLaudoMedico = _getDateOrNull(tfd.data_preenchimento_laudo_medico);
    tfdFormatado.geral.dataAgendamento = _getDateOrNull(tfd.data_agendamento);
    tfdFormatado.geral.dataEmissaoAgendamento = _getDateOrNull(tfd.data_emissao_agendamento);
    tfdFormatado.geral.diferencaDias = '';
    if (tfd.data_formatada && tfd.data_agendamento_formatada) {
        const dataSol = moment(tfd.data);
        const dataAgend = moment(tfd.data_agendamento);

        tfdFormatado.geral.dataSolicitacao = _getDateOrNull(tfd.data);
        const msg = `${dataAgend.diff(dataSol, 'days')} DIA(S)`;
        tfdFormatado.geral.diferencaDias = msg.replace('-', '');
    }

    // Itinerário
    tfdFormatado.itinerario.tipoProcesso = _getValueOrNull(tfd.tipo_processo);
    tfdFormatado.itinerario.dataIda = _getDateOrNull(tfd.data_ida);
    tfdFormatado.itinerario.dataVolta = _getDateOrNull(tfd.data_volta);
    tfdFormatado.itinerario.altaHospitalar = _getValueOrFalse(tfd.alta_hospitalar);
    tfdFormatado.itinerario.dadosAdicionais = _getValueOrEmptyString(tfd.observacao_tipo_processo);

    // Dados paciente 
    tfdFormatado.paciente.id = tfd.id_paciente;
    tfdFormatado.paciente.nome = _getValueOrEmptyString(tfd.nome_paciente);
    tfdFormatado.paciente.cpf = Util.util.adicionarMascara(`000${tfd.cpf_paciente}`, '000.000.000-00', { reverse: true }) || '';
    tfdFormatado.paciente.raca = null;
    if (tfd.id_raca_paciente) {
        tfdFormatado.paciente.raca = {
            id: tfd.id_raca_paciente,
            descricao: tfd.raca_paciente
        }
    }
    tfdFormatado.paciente.regionalSaude = _getValueOrEmptyString(tfd.gerencia_saude);
    tfdFormatado.paciente.dataNascimento = _getValueOrEmptyString(tfd.data_nascimento_paciente);
    tfdFormatado.paciente.cns = _getValueOrEmptyString(tfd.cns_paciente);

    // Transporte
    tfdFormatado.transporte.tipoTransporte = _getValueOrNull(tfd.id_tfd_cadastro_transporte_recomendavel);
    tfdFormatado.transporte.pontoReferencia = _getValueOrEmptyString(tfd.ponto_referencia);
    tfdFormatado.transporte.tipoTransporteOriginal = _getValueOrNull(tfd.transporte_recomendavel_original);
    tfdFormatado.transporte.justificativa = _getValueOrEmptyString(tfd.transporte_recomendavel_justificativa);
    tfdFormatado.transporte.transporteModificado = false;
    if ((tfd.tipo_transporte && tfd.tipo_transporte !== tfd.transporte_recomendavel_original) ||
        (tfd.transporte_recomendavel_justificativa && tfd.transporte_recomendavel_justificativa !== '') ||
        (tfd.anexos.transporte?.length > 0)) {
        tfdFormatado.transporte.transporteModificado = true;
    }
    tfdFormatado.transporte.ufOrigem = _getValueOrNull(tfd.id_estado_origem);
    tfdFormatado.transporte.cidadeOrigem = _getValueOrNull(tfd.id_cidade_origem);
    tfdFormatado.transporte.ufDestino = _getValueOrNull(tfd.id_estado_destino);
    tfdFormatado.transporte.cidadeDestino = _getValueOrNull(tfd.id_cidade_destino);
    tfdFormatado.transporte.anexos = [];
    tfdFormatado.transporte.anexosExcluidos = [];
    if (tfd.anexos.transporte?.length > 0) {
        tfd.anexos.transporte.forEach(anexo => {
            tfdFormatado.transporte.anexos.push({
                id: anexo.id,
                name: anexo.nome,
                url: anexo.url
            });
        });
    }

    // Acompanhante
    tfdFormatado.acompanhante.numeroAcompanhantes = _getValueOrEmptyString(tfd.numero_acompanhantes);
    tfdFormatado.acompanhante.numeroAcompanhantesOriginal = _getValueOrEmptyString(tfd.numero_acompanhantes_original);
    tfdFormatado.acompanhante.trocaAcompanhante = _getValueOrFalse(tfd.troca_acompanhante);
    tfdFormatado.acompanhante.justificativa = _getValueOrEmptyString(tfd.justificativa_acompanhante);
    tfdFormatado.acompanhante.acompanhanteModificado = false;
    if ((tfd.numero_acompanhantes !== tfd.numero_acompanhantes_original) ||
        (tfd.justificativa_acompanhante && tfd.justificativa_acompanhante !== '') ||
        (tfd.anexos.acompanhante?.length > 0)) {
        tfdFormatado.acompanhante.acompanhanteModificado = true;
    }
    tfdFormatado.acompanhante.anexos = [];
    tfdFormatado.acompanhante.anexosExcluidos = [];
    if (tfd.anexos.acompanhante?.length > 0) {
        tfd.anexos.acompanhante.forEach(anexo => {
            tfdFormatado.acompanhante.anexos.push({
                id: anexo.id,
                name: anexo.nome,
                url: anexo.url
            });
        });
    }
    tfdFormatado.acompanhante.acompanhantes = [];
    if (tfd.acompanhantes?.length > 0) {
        tfd.acompanhantes.forEach(acomp => {
            tfdFormatado.acompanhante.acompanhantes.push({
                nome: _getValueOrEmptyString(acomp.nome),
                cpf: Util.util.adicionarMascara(`000${acomp.cpf}`, '000.000.000-00', { reverse: true }) || '',
                cns: _getValueOrEmptyString(acomp.cartao_sus),
                doador: acomp.doador || false,
                dataNascimento: _getDateOrNull(acomp.data_nascimento),
                cep: Util.util.adicionarMascara(`00${acomp.cep}`, '00000-000', { reverse: true }) || '',
                logradouro: _getValueOrEmptyString(acomp.logradouro),
                numero: _getValueOrEmptyString(acomp.numero),
                bairro: _getValueOrEmptyString(acomp.bairro),
                complemento: _getValueOrEmptyString(acomp.complemento),
                idCidade: _getValueOrNull(acomp.id_cidade),
                cidade: _getValueOrEmptyString(acomp.nome_cidade),
                uf: _getValueOrEmptyString(acomp.sigla_estado),
                ibgeCidade: '',
                parentesco: _getValueOrNull(acomp.id_parentesco),
                complementoParentesco: _getValueOrEmptyString(acomp.complemento_parentesco),
                exigeComplemento: acomp.complemento_parentesco ? true : false,
                sexo: _getValueOrNull(acomp.id_sexo),
                raca: _getValueOrNull(acomp.id_raca),
            });
        });
    }

    const diffAcomp = tfdFormatado.acompanhante.numeroAcompanhantes - tfdFormatado.acompanhante.acompanhantes.length;
    for (let i = 0; i < diffAcomp; i++) {
        tfdFormatado.acompanhante.acompanhantes.push({
            nome: '',
            cpf: '',
            raca: null,
            cns: '',
            doador: false,
            dataNascimento: null,
            cep: '',
            logradouro: '',
            numero: '',
            bairro: '',
            complemento: '',
            idCidade: '',
            cidade: '',
            uf: '',
            ibgeCidade: '',
            sexo: null,
            parentesco: null,
            complementoParentesco: '',
            exigeComplemento: false
        });
    }

    // Agendamento
    tfdFormatado.agendamento.telefone = _getValueOrEmptyString(tfd.contato_setor_solicitante);
    tfdFormatado.agendamento.anexos = [];
    tfdFormatado.agendamento.anexosExcluidos = [];
    if (tfd.anexos.agendamento?.length > 0) {
        tfd.anexos.agendamento.forEach(anexo => {
            tfdFormatado.agendamento.anexos.push({
                id: anexo.id,
                name: anexo.nome,
                url: anexo.url
            });
        });
    }
    // console.log(tfdFormatado)

    return tfdFormatado;
}

const formatarTfdParaSalvar = (data, tipoSubmit, idProcessoVinculo) => {
    const formData = new FormData();
    formData.append('tipoSubmit', tipoSubmit);
    formData.append('situacao', data.situacao);
    formData.append('sequencial', data.sequencial);

    idProcessoVinculo && formData.append('idProcessoVinculo', idProcessoVinculo);

    // ========== Dados gerais ==========
    data.geral.dataAgendamento && formData.append('dataAgendamento', new Date(data.geral.dataAgendamento));
    data.geral.dataEmissaoAgendamento && formData.append('dataEmissaoAgendamento', new Date(data.geral.dataEmissaoAgendamento));
    data.geral.dataSolicitacao && formData.append('dataSolicitacao', new Date(data.geral.dataSolicitacao));
    // ========== Dados gerais ==========

    // ========== Itinerário ==========
    data.itinerario.dataIda && formData.append('dataIda', new Date(data.itinerario.dataIda));
    data.itinerario.dataVolta && formData.append('dataVolta', new Date(data.itinerario.dataVolta));
    data.itinerario.tipoProcesso && formData.append('tipoProcesso', data.itinerario.tipoProcesso);
    formData.append('altaHospitalar', data.itinerario.altaHospitalar);
    formData.append('dadosAdicionais', data.itinerario.dadosAdicionais.trim());
    // ========== Itinerário ==========

    // ========== Paciente ==========
    formData.append('idPaciente', data.paciente.id);
    data.paciente.raca && formData.append('idRacaPaciente', data.paciente.raca.id);
    formData.append('cnsPaciente', data.paciente.cns);
    // ========== Paciente ==========

    // ========== Transporte ==========
    if (data.transporte.tipoTransporte) {
        formData.append('tipoTransporte', data.transporte.tipoTransporte.id);
        const siglaTransporte = data.transporte.tipoTransporte.sigla;
        formData.append('siglaTipoTransporte', siglaTransporte);

        if (siglaTransporte === TRANSPORTE_RECOMENDAVEL.AMBULANCIA) {
            data.transporte.pontoReferencia && formData.append('pontoReferencia', data.transporte.pontoReferencia.trim());
        }
    }
    data.transporte.cidadeOrigem?.id && formData.append('cidadeOrigem', data.transporte.cidadeOrigem.id);
    data.transporte.cidadeDestino?.id && formData.append('cidadeDestino', data.transporte.cidadeDestino.id);
    data.transporte.transporteModificado && formData.append('justificativaTransporte', data.transporte.justificativa.trim());

    _adicionarAnexos(formData, data.transporte.anexos, 'Transporte');
    _adicionarAnexosExcluidos(formData, data.transporte.anexosExcluidos, 'Transporte');
    // ========== Transporte ==========

    // ========== Acompanhante ==========
    formData.append('numeroAcompanhantes', data.acompanhante.numeroAcompanhantes);
    formData.append('trocaAcompanhante', data.acompanhante.trocaAcompanhante);
    data.acompanhante.acompanhanteModificado && formData.append('justificativaAcompanhante', data.acompanhante.justificativa.trim());

    data.acompanhante.acompanhantes.forEach((acomp, index) => {
        formData.append(`acompanhante.${index}.nome`, acomp.nome.trim());
        formData.append(`acompanhante.${index}.cpf`, `${acomp.cpf}`.replace(/\D+/g, ''));
        formData.append(`acompanhante.${index}.cns`, acomp.cns);
        formData.append(`acompanhante.${index}.cep`, `${acomp.cep}`.replace(/\D+/g, ''));
        formData.append(`acompanhante.${index}.logradouro`, acomp.logradouro.trim());
        formData.append(`acompanhante.${index}.complemento`, acomp.complemento.trim());
        formData.append(`acompanhante.${index}.numero`, acomp.numero.trim());
        formData.append(`acompanhante.${index}.bairro`, acomp.bairro.trim());
        formData.append(`acompanhante.${index}.doador`, acomp.doador);

        acomp.dataNascimento && formData.append(`acompanhante.${index}.dataNascimento`, new Date(acomp.dataNascimento));
        acomp.idCidade && formData.append(`acompanhante.${index}.idCidade`, acomp.idCidade);
        acomp.ibgeCidade && formData.append(`acompanhante.${index}.ibgeCidade`, acomp.ibgeCidade);
        acomp.sexo?.id && formData.append(`acompanhante.${index}.sexo`, acomp.sexo.id);
        acomp.raca?.id && formData.append(`acompanhante.${index}.raca`, acomp.raca.id);

        if (acomp.parentesco) {
            formData.append(`acompanhante.${index}.parentesco`, acomp.parentesco.id);
            acomp.parentesco.exige_complemento && formData.append(`acompanhante.${index}.complementoParentesco`, acomp.complementoParentesco.trim());
        }
    });

    _adicionarAnexos(formData, data.acompanhante.anexos, 'Acompanhante');
    _adicionarAnexosExcluidos(formData, data.acompanhante.anexosExcluidos, 'Acompanhante');
    // ========== Acompanhante ==========

    // ========== Agendamento ==========
    formData.append('telefoneSetor', (data.agendamento.telefone || '').replace(/\D+/g, ''));

    _adicionarAnexos(formData, data.agendamento.anexos, 'Agendamento');
    _adicionarAnexosExcluidos(formData, data.agendamento.anexosExcluidos, 'Agendamento');
    // ========== Agendamento ==========

    // ========== Estadual ==========
    if (data.estadual.motivo.trim()) {
        formData.append('motivoAlteracaoEstadual', data.estadual.motivo.trim());

        _adicionarAnexos(formData, data.estadual.anexos, 'Estadual');
    }
    // ========== Estadual ==========

    return formData;
}

const verificarErrosOrdemPrecedencia = (erros, secao) => {
    const ordemPrecedencia = [
        GERAL,
        ITINERARIO,
        PACIENTE,
        TRANSPORTE,
        ACOMPANHANTE,
        AGENDAMENTO,
        ESTADUAL
    ];

    for (const ordem of ordemPrecedencia) {
        if (secao === ordem) {
            return false;
        }

        if (erros[ordem]) {
            return true;
        }
    }
}

/**
 * Função que adicionar anexos do tipo "File" ao formulário que será enviado ao backend.
 * 
 * @param {FormData} formData formulário a ser enviado ao backend
 * @param {*} arrayAnexo array de anexos a ser adicionado ao formulário
 * @param {string} chave chave usada para indexar o 'anexo' ao formulário
 */
const _adicionarAnexos = (formData, arrayAnexo, chave) => {
    if (arrayAnexo.length > 0) {
        let quantAnexos = 0;
        arrayAnexo.forEach(anexo => {
            if (anexo && (anexo instanceof File)) {
                formData.append(`anexo${chave}.${quantAnexos}`, anexo);
                quantAnexos++;
            }
        });
        if (quantAnexos > 0) {
            formData.append(`numeroAnexo${chave}`, quantAnexos);
        }
    }
}

/**
 * Função que adicionar os anexos excluídos pelo usuário ao formulário que será enviado ao backend.
 * 
 * @param {FormData} formData formulário a ser enviado ao backend
 * @param {*} arrayAnexo array de anexos excluídos a ser adicionado ao formulário
 * @param {string} chave chave usada para indexar o 'anexo excluído' ao formulário
 */
const _adicionarAnexosExcluidos = (formData, arrayAnexoExcluido, chave) => {
    if (arrayAnexoExcluido?.length > 0) {
        formData.append(`anexo${chave}Excluido`, JSON.stringify(arrayAnexoExcluido));
    }
}

const _getDateOrNull = (value) => {
    if (!value) {
        return null;
    }
    if (value && moment(value).isValid()) {
        value = moment.utc(value);
        return value.set({ h: 12 }).toISOString();
    }

    return value;
}

const _getValueOrNull = (value) => {
    return value ?? null;
}

const _getValueOrFalse = (value) => {
    return value || false;
}

const _getValueOrEmptyString = (value) => {
    return value ?? '';
}

export default {
    acoes,
    formatarTfdParaAlteracao,
    formatarTfdParaSalvar,
    verificarErrosOrdemPrecedencia
};