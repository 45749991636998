import React, { useContext, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
    SttButton,
    SttLoading,
    SttGrid,
    SttMaskedInput,
    SttNumberInput,
    SttFormControl,
    SttRadioGroup,
    SttCurrencyInput,
    SttDatePicker,
    SttFormControlLabel,
    SttRadioButton,
    SttHeading,
    SttTranslateHook
} from '@stt-componentes/core';
import {
    Formik,
    FastField,
    Field
} from 'formik';
import HttpStatus from 'http-status-codes';
import * as yup from 'yup';
import axios from 'axios';
import { getHeaders } from '../../../request';
import moment from 'moment';

const validationSchema = (strings) => {
    return yup.object().shape({
        'valorPassagem': yup
            .number()
            .min(0)
            .max(99999, strings.valorMaximoPassagem)
            .nullable()
            .required(strings.campoObrigatorio),
        'dataCompra': yup
            .date()
            .nullable()
            .max((new Date()), strings.erroDataFutura)
            .typeError(strings.dataInvalida)
            .required(strings.campoObrigatorio),
        'quantidadePassagem': yup
            .number()
            .min(0)
            .max(99)
            .nullable()
            .required(strings.campoObrigatorio),
        'numeroFatura': yup
            .string()
            .trim()
            .nullable()
            .test('numero-fatura-valido', strings.numeroFaturaInvalido, (fatura) => {
                fatura = (fatura || '').trim();
                return fatura.length === 11;
            })
            .required(strings.campoObrigatorio),
        'reembolso': yup
            .string()
            .matches(/[SN]/)
            .required(strings.campoObrigatorio),
        'tipoReembolso': yup
            .string()
            .when('reembolso', {
                is: (val) => val === REEMBOLSO.SIM,
                then: yup.string()
                    .matches(/[TP]/)
                    .required(strings.campoObrigatorio)
            }),
        'valorReembolso': yup
            .number()
            .when('reembolso', {
                is: (val) => val === REEMBOLSO.SIM,
                then: yup.number()
                    .min(0)
                    .max(99999, strings.valorMaximoReembolso)
                    .required(strings.campoObrigatorio)
            }),
    });
}

const TIPO_REEMBOLSO = {
    PARCIAL: 'P',
    TOTAL: 'T'
};

const REEMBOLSO = {
    SIM: 'S',
    NAO: 'N'
}

const getDateOrNull = (value) => {
    if (!value) {
        return null;
    }
    if (value && moment(value).isValid()) {
        value = moment.utc(value);
        return value.set({ h: 12 }).toISOString();
    }

    return value;
}

const useStyles = makeStyles(theme => ({
    button: {
        marginBottom: theme.spacing(1)
    }
}));

const OperacaoFatura = ({ callback, callbackSucesso, tfd, alerta }) => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const schema = validationSchema(strings);
    const formRef = useRef();
    const classes = useStyles();

    const TFD_PASSAGENS_API_BASE_URL = global.gConfig.url_base_tfd_passagens;

    const initialValues = {
        valorPassagem: tfd.valor_passagens || '',
        quantidadePassagem: tfd.quantidade_passagens || '',
        dataCompra: getDateOrNull(tfd.data_compra_passagem),
        numeroFatura: tfd.fatura || '',
        reembolso: tfd.reembolso ? REEMBOLSO.SIM : REEMBOLSO.NAO,
        tipoReembolso: tfd.tipo_reembolso || '',
        valorReembolso: tfd.valor_reembolso || '',
        id: tfd.id
    };

    //Alerta 
    const {
        setMostrarAlerta,
        setTituloAlerta,
        setMensagemAlerta,
        setTipoAlerta,
        setOpcoesAlerta,
        setOnCloseAlerta
    } = alerta;

    return (
        <Formik
            innerRef={formRef}
            initialValues={initialValues}
            validationSchema={schema}
            onSubmit={(dados, { setSubmitting, resetForm }) => {
                setSubmitting(true);

                axios.post(`${TFD_PASSAGENS_API_BASE_URL}/autorizar-processo`, dados, { headers: getHeaders() })
                    .then((response) => {
                        const { data } = response;
                        if (response.status === HttpStatus.OK) {
                            setTituloAlerta(strings.sucesso);
                            setMensagemAlerta(data.message ? data.message : strings.operacaoSucesso);
                            setTipoAlerta('success');
                            setOpcoesAlerta([
                                {
                                    title: strings.ok,
                                    onClick: () => {
                                        setMostrarAlerta(false);
                                        resetForm({ values: initialValues });
                                        callbackSucesso();
                                    }
                                }
                            ]);
                            setOnCloseAlerta(() => callbackSucesso);
                            setMostrarAlerta(true);
                        } else {
                            setTituloAlerta(strings.erro);
                            setMensagemAlerta(data && data.message ? data.message : strings.msgErroAdicionarFatura);
                            setTipoAlerta('error');
                            setOpcoesAlerta([
                                {
                                    title: strings.ok,
                                    onClick: () => {
                                        setMostrarAlerta(false);
                                    }
                                }
                            ]);
                            setMostrarAlerta(true);
                        }
                    })
                    .catch(err => {
                        console.log(err);
                        const { data } = err;
                        setTituloAlerta(strings.erro);
                        setMensagemAlerta(data && data.message ? data.message : strings.msgErroAdicionarFatura);
                        setTipoAlerta('error');
                        setOpcoesAlerta([
                            {
                                title: strings.ok,
                                onClick: () => {
                                    setMostrarAlerta(false);
                                }
                            }
                        ]);
                        setMostrarAlerta(true);
                    })
                    .finally(() => {
                        setSubmitting(false);
                    });
            }}
        >
            {
                ({
                    isSubmitting,
                    handleSubmit,
                    resetForm,
                }) => {
                    return (
                        <form onSubmit={handleSubmit} noValidate>
                            <SttGrid container spacing={3}>
                                <SttGrid item xs={12} sm={6} md={3}>
                                    <FastField name='valorPassagem'>
                                        {({
                                            field,
                                            form: { setFieldValue },
                                            meta
                                        }) => (
                                            <SttCurrencyInput
                                                required={true}
                                                {...field}
                                                label={strings.valorPassagem}
                                                error={meta.touched && meta.error ? true : false}
                                                helperText={meta.touched && meta.error ? meta.error : undefined}
                                                onChange={(e, value) => setFieldValue('valorPassagem', value || null)}
                                            />
                                        )}
                                    </FastField>
                                </SttGrid>
                                <SttGrid item xs={12} sm={6} md={3}>
                                    <FastField name='quantidadePassagem'>
                                        {({
                                            field,
                                            meta,
                                        }) => (
                                            <SttNumberInput
                                                required={true}
                                                inputProps={{
                                                    maxLength: 2
                                                }}
                                                {...field}
                                                label={strings.quantidadePassagens}
                                                error={meta.touched && meta.error ? true : false}
                                                helperText={meta.touched && meta.error ? meta.error : undefined}
                                            />
                                        )}
                                    </FastField>
                                </SttGrid>
                                <SttGrid item xs={12} sm={6} md={3}>
                                    <FastField name='dataCompra'>
                                        {({
                                            field: { name, value },
                                            form: { setFieldValue, setFieldError, setFieldTouched },
                                            meta
                                        }) => {
                                            return (
                                                <SttDatePicker
                                                    label={strings.dataCompra}
                                                    inputprops={{
                                                        name: name
                                                    }}
                                                    maxDate={new Date()}
                                                    maxDateMessage={strings.erroDataFutura}
                                                    error={(meta.touched && meta.error) ? true : false}
                                                    onError={error => {
                                                        setFieldError('dataCompra', meta.error || error);
                                                    }}
                                                    required
                                                    value={value}
                                                    onBlur={() => {
                                                        setFieldTouched('dataCompra', true);
                                                    }}
                                                    helperText={(meta.touched && meta.error) ? meta.error : undefined}
                                                    onChange={date => setFieldValue('dataCompra', date, true)}
                                                    onClose={() => setFieldTouched('dataCompra', true)}
                                                />
                                            );
                                        }}
                                    </FastField>
                                </SttGrid>
                                <SttGrid item xs={12} sm={6} md={3}>
                                    <FastField name='numeroFatura'>
                                        {({
                                            field,
                                            form: { setFieldError, setFieldTouched },
                                            meta
                                        }) => (
                                            <SttMaskedInput
                                                required={true}
                                                {...field}
                                                mask="faturaPassagem"
                                                label={strings.numeroFatura}
                                                error={meta.touched && meta.error ? true : false}
                                                onError={error => {
                                                    setFieldError('numeroFatura', meta.error || error);
                                                }}
                                                onBlur={() => {
                                                    setFieldTouched('numeroFatura', true);
                                                }}
                                                helperText={meta.touched && meta.error ? meta.error : undefined}
                                            />
                                        )}
                                    </FastField>
                                </SttGrid>
                            </SttGrid>
                            <SttGrid container spacing={3}>
                                <SttGrid item xs={12} sm={6} md={4}>
                                    <SttHeading required variant="h5" color="primary">{strings.ehReembolso}</SttHeading>
                                    <Field name='reembolso'>
                                        {({
                                            field,
                                            form: { setFieldValue },
                                        }) => (
                                            <SttFormControl variant="outlined">
                                                <SttRadioGroup row
                                                    onChange={(e, item) => {
                                                        setFieldValue('tipoReembolso', '')
                                                        setFieldValue('valorReembolso', '')
                                                    }
                                                    }
                                                >
                                                    <SttFormControlLabel
                                                        {...field}
                                                        control={
                                                            <SttRadioButton
                                                                type="radio"
                                                                value={REEMBOLSO.SIM}
                                                                color="primary"
                                                                checked={field.value === REEMBOLSO.SIM}
                                                            />
                                                        }
                                                        label={strings.sim}
                                                    />
                                                    <SttFormControlLabel
                                                        {...field}
                                                        control={
                                                            <SttRadioButton
                                                                type="radio"
                                                                value={REEMBOLSO.NAO}
                                                                color="primary"
                                                                checked={field.value === REEMBOLSO.NAO}
                                                            />
                                                        }
                                                        label={strings.nao}
                                                    />
                                                </SttRadioGroup>
                                            </SttFormControl>
                                        )}
                                    </Field>
                                </SttGrid>
                                <SttGrid item xs={12} sm={6} md={4}>
                                    <Field name='tipoReembolso'>
                                        {({
                                            field,
                                            form: { values },
                                            meta,
                                        }) => (
                                            values.reembolso === REEMBOLSO.SIM &&
                                            <div>
                                                <SttHeading required variant="h5" color="primary">{strings.tipoReembolso}</SttHeading>
                                                <SttFormControl required error={meta.touched && meta.error ? true : false} variant="outlined">
                                                    <SttRadioGroup row>
                                                        <SttFormControlLabel
                                                            {...field}
                                                            control={
                                                                <SttRadioButton
                                                                    type="radio"
                                                                    value={TIPO_REEMBOLSO.PARCIAL}
                                                                    color="primary"
                                                                    checked={field.value === TIPO_REEMBOLSO.PARCIAL}
                                                                />
                                                            }
                                                            label={strings.parcial}
                                                        />
                                                        <SttFormControlLabel
                                                            {...field}
                                                            control={
                                                                <SttRadioButton
                                                                    type="radio"
                                                                    value={TIPO_REEMBOLSO.TOTAL}
                                                                    color="primary"
                                                                    checked={field.value === TIPO_REEMBOLSO.TOTAL}
                                                                />
                                                            }
                                                            label={strings.total}
                                                        />
                                                    </SttRadioGroup>
                                                </SttFormControl>
                                            </div>

                                        )}
                                    </Field>
                                </SttGrid>
                                <SttGrid item xs={12} sm={6} md={4}>
                                    <Field name='valorReembolso'>
                                        {({
                                            field,
                                            form: { setFieldValue, values },
                                            meta
                                        }) => (
                                            values.reembolso === REEMBOLSO.SIM &&
                                            <SttCurrencyInput
                                                required={true}
                                                {...field}
                                                label={strings.valorReembolsoRs}
                                                error={meta.touched && meta.error ? true : false}
                                                helperText={meta.touched && meta.error ? meta.error : undefined}
                                                onChange={(e, value) => setFieldValue('valorReembolso', value || null)}
                                            />
                                        )}
                                    </Field>
                                </SttGrid>
                                <SttLoading
                                    open={isSubmitting}
                                    text={strings.salvando}
                                />
                            </SttGrid>
                            <SttGrid container spacing={3}>
                                <SttGrid item xs={12}>
                                    <SttButton
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        nomarginleft="true"
                                        className={classes.button}
                                        disabled={isSubmitting}
                                    >
                                        {strings.confirmar}
                                    </SttButton>
                                    <SttButton
                                        type="button"
                                        variant="outlined"
                                        color="primary"
                                        disabled={isSubmitting}
                                        className={classes.button}
                                        onClick={() => {
                                            resetForm({ values: initialValues });
                                        }}
                                    >
                                        {strings.limpar}
                                    </SttButton>
                                    <SttButton
                                        type="button"
                                        variant="outlined"
                                        color="primary"
                                        disabled={isSubmitting}
                                        className={classes.button}
                                        onClick={() => {
                                            resetForm({ values: initialValues });
                                            callback();
                                        }}
                                    >
                                        {strings.fechar}
                                    </SttButton>
                                </SttGrid>
                            </SttGrid>
                        </form>
                    )
                }
            }
        </Formik>
    );
}

export default OperacaoFatura;