'use strict';
const MdlRbac = require('../modelos/rbac');

const VINCULO_ESTADUAL = 'E';

const Rbac = {
    temPerfil: async (usuario, perfil) => {
        return usuario.perfisRBAC.some(p => {
            return p.identificador === perfil;
        });
    },
    
    temPermissao: async (usuario, permissao) => {
        if(usuario && usuario.permissoesRBAC) {
            return usuario.permissoesRBAC.includes(permissao);
        }
    },

    /**
     * Função que retorna se o usuário tem algum perfil a nível estadual relacionado ao módulo em questão (Teleconsultorias)
     * @param {*} perfis lista de perfis RBAC do usuário.
     */
    possuiVinculoEstadual: async (perfis = [], modulosAcesso, knex) => {
        let perfisEstaduais = perfis.filter(p => p.vinculo === VINCULO_ESTADUAL);

        if(perfisEstaduais.length === 0) {
            return false;
        }

        perfisEstaduais = perfisEstaduais.map(p => p.identificador);
        
        const resultadoContagem = await MdlRbac.contarPerfisVinculoModulo(perfisEstaduais, modulosAcesso, knex);
        const { count } = resultadoContagem[0];

        return parseInt(count) > 0;
    },

    buscarEstadosPerfisVinculoModulo: async (idUsuario, modulosAcesso, knex) => {
        return await MdlRbac.buscarEstadosPerfisVinculoModulo(idUsuario, modulosAcesso, knex);
    },
}

module.exports = Rbac;