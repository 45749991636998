import * as yup from 'yup'

import geralSchema from '../../componentes/solicitacao/geral/form/validationSchema';
import itinerarioSchema from '../../componentes/solicitacao/itinerario/form/validationSchema';
import pacienteSchema from '../../componentes/solicitacao/paciente/form/validationSchema';
import transporteSchema from '../../componentes/solicitacao/transporte/form/validationSchema';
import acompanhanteSchema from '../../componentes/solicitacao/acompanhante/form/validationSchema';
import agendamentoSchema from '../../componentes/solicitacao/agendamento/form/validationSchema';
import estadualSchema from '../../componentes/solicitacao/estadual/form/validationSchema';
import { temPermissaoRBAC } from '../../secutity/acl';
import { PERMISSOES } from '../../common/Constants';

export default (strings, user) => {
    let schema = yup.object().shape({});

    const geral = geralSchema(strings);
    const itinerario = itinerarioSchema(strings);
    const paciente = pacienteSchema(strings);
    const transporte = transporteSchema(strings);
    const acompanhante = acompanhanteSchema(strings);
    const agendamento = agendamentoSchema(strings);

    schema = schema
        .concat(geral)
        .concat(itinerario)
        .concat(paciente)
        .concat(transporte)
        .concat(acompanhante)
        .concat(agendamento);

    if (temPermissaoRBAC(user, [PERMISSOES.ENCAMINHAR, PERMISSOES.ENCAMINHAR_REGULADOR, PERMISSOES.ARQUIVAMENTO])) {
        const estadual = estadualSchema(strings);
        schema = schema.concat(estadual);
    }

    return schema;
}