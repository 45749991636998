import { combineReducers } from 'redux';

import TYPES from './types';
import pesquisa from './pesquisa';
import solicitacao from './solicitacao';

const INITIAL_STATE = {
    isAuthenticated: false,
    message: '',
    user: {
        nome: '',
        email: '',
        foto: ''
    }
};

const index = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;
    switch (type) {
        case TYPES.SET_AUTHENTICATED:
            return {
                ...state,
                isAuthenticated: payload.authenticated
            }
        case TYPES.SET_USER:
            return {
                ...state,
                user: payload.user
            }
        case TYPES.LOGOUT:
            return INITIAL_STATE;
        case TYPES.SET_MESSAGE:
            return {
                ...state,
                message: payload.message
            }
        default:
            return state;
    }
}

export const Reducers = combineReducers({
    index,
    pesquisa,
    solicitacao
});
