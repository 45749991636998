module.exports = {
    abrir: 'Abrir',
    abrirNovaAba: 'Abrir anexo em nova aba',
    achados: 'Achados',
    adicionarNovosAnexos: 'CLIQUE AQUI para adicionar novas imagens ao exame.',
    agendado: 'Agendado',
    alteracaoAntecedente: 'Alteração de antecendente',
    anexarArquivo: 'Anexar arquivos',
    anexos: 'Anexos',
    anexosInseridosSucesso: 'Anexos inseridos com sucesso.',
    antecedente: 'Antecedente',
    antecedenteDuplicado: 'Já existe outro antecedente com a mesma descrição informada.',
    antecedenteObrigatorio: 'É obrigado a informar ao menos um antecedente',
    antecedentes: 'Antecedentes',
    antecedentesTitulo: 'Antecedentes',
    assinando: 'Aguarde - assinando digitalmente o laudo...',
    ausente: 'Ausente',
    avaliar: 'Avaliar',
    BuscaFuncionario: {},
    cadastroAntecedente: 'Cadastro de novo antecedente',
    carregandoExame: 'Carregando exame',
    categoria: 'Categoria',
    cidadeProcedencia: 'Município de procedência',
    comLaudo: 'Com laudo',
    comorbidadesTitulo: 'Comorbidades',
    conclusao: 'Conclusão',
    concordoP: 'Concordo parcialmente',
    concordoT: 'Concordo totalmente',
    confirmando: 'Confirmando laudo',
    confirmarAlteracaoAntecedente: 'Confirma a alteração do antecedente?',
    confirmarInsercaoAntecedente: 'Confirma a inserção do novo antecedente?',
    controleTratamento: 'Controle de tratamento',
    dadosInvalidos: 'Dados inválidos. Verifique os dados informados e tente novamente.',
    dataHoraAtendimento: 'Data/hora do atendimento',
    dataHoraTeste: 'Data/hora do teste',
    dermatose: 'Dermatose',
    descricaoEstudo: 'Descrição do estudo',
    descricaoExame: 'Descrição do exame',
    descritores: 'Descritores',
    direcionados: 'Direcionado ao meu usuário',
    discordoP: 'Discordo parcialmente',
    discordoT: 'Discordo totalmente',
    distribuicao: 'Distribuição',
    emailInvalido: 'Email inválido',
    enviar: 'Enviar',
    erroAssinatura: 'Erro ao assinar digitalmente o laudo',
    erroGerarTermo: 'Não foi possível gerar o termo de autorização. Tente imprimir o termo através da tela de exames.',
    Erros: {
        naoAutorizado: 'Não autorizado'
    },
    erroSortearExame: 'Não foi possível sortear o próximo exame para laudo. Tente novamente e se o problema persistir, entre em contato com os administradores.',
    especificacaoTempo: 'Especificação',
    exameMaisAntigoSemLaudo: 'Data do exame mais antigo sem laudo',
    examesAgendados: 'Agendados',
    examesComImagens: 'Com imagens',
    exameSelecionado: 'Exame selecionado',
    exameSemImagens: 'Este exame não possui imagens anexadas até o momento',
    examesInvalidos: 'Inválidos',
    examesPacs: 'PACS',
    examesSelecionados: 'Exames selecionados',
    examesValidos: 'Válidos',
    exFumante: 'Ex-fumante',
    anexoPrincipal: 'Anexo principal',
    anexoEnvioImagens: 'Anexo (.jpg, .jpeg, .png, .bmp, .pdf)',
    outroAnexo: 'Outro anexo',
    fumante: 'Fumante',
    outroAnexoExame: 'Outro anexo (.zip, .rar)',
    gerandoResumo: 'Gerando resumo do laudo',
    historiaClinica: 'História clínica',
    ignorandoExame: 'Ignorando exame',
    imagemInvalida: 'O campo deve conter uma imagem do tipo JPG',
    imformacoesCompl: 'Informações complementares',
    inclusaoImagemOpcional: 'A inclusão de imagens no envio de exames de polissonografia é obrigatória. Em adição, um arquivo externo representando o exame pode ser adicionado na forma de .ZIP ou .RAR',
    inclusaoNovosAnexos: 'Inclusão de novas imagens',
    indicacaoExame: 'Indicacao do exame',
    infoClinicas: 'Informações clínicas',
    instituicaoSolicitante: 'Instituição solicitante',
    invalido: 'Inválido',
    investigacaoDiagnostica: 'Investigação diagnóstica',
    laudoAguardandoComplemento: 'Com laudo aguardando complemento',
    laudoAssinado: 'Laudo assinado digitalmente com sucesso',
    laudoEmEmissao: 'Devido à demora na emissão do laudo, este exame foi acessado por outro profissional. O próximo exame será carregado.',
    laudoEmissao: 'Em emissão',
    laudosEmitidos: 'Laudos emitidos',
    laudoTemporario: 'Com laudo temporário',
    laudoTextual: 'Laudo textual',
    local: 'Local',
    localizacao: 'Localização',
    logradouro: 'Logradouro',
    medicamentosEmUso: 'Medicamentos em uso',
    medicamentosUso: 'Medicamentos em uso',
    medicoExecutor: 'Médico executor',
    mensagemAlertaCancelamento: 'Você confirma o cancelamento do envio de imagens?',
    mensagemConfirmarEnvioExame: 'Deseja enviar as imagens do exame agora?',
    mensagemConfirmarTermo: 'Deseja imprimir o TCLE?',
    modulo: 'Polissonografia',
    motivoExame: 'Motivo do exame',
    naoFoiPosisvelCarregarExamesInvalidos: 'Não foi possível carregar os dados de exames inválidos',
    naoFoiPossivelCarregarExamesComLaudo: 'Não foi possível carregar os dados de exames laudados',
    naoFoiPossivelCarregarLaudosEmitidos: 'Não foi possível carregar os dados de laudos emitidos',
    naoFoiPossivelCarregarSemLaudo: 'Não foi possível carregar os dados de exames sem laudo',
    naoFoiPossivelCarregarSemLaudo72h: 'Não foi possível carregar os dados de exames com laudo atrasado',
    naoFoiPossivelCarregarTotais: 'Não foi possível carregar os dados de produtividade',
    nenhumAnexo: 'Nenhum anexo encontrado',
    nenhummExameParaLaudo: 'Nenhum exame disponível para laudo',
    noaExisteEquipamento: 'A instituição do exame não possui nenhum ponto cadastrado. Entre em contato com os administradores.',
    notificacaoAntecedentes: 'Cadastra um antecedente que será utilizado para especificar o(s) antecendentes(s) que o paciente possui na tela de solicitação de exames',
    notificacaoComorbidades: 'Cadastra uma comorbidade que será utilizada para especificar a(s) comorbidade(s) que o paciente possui na tela de solicitação de exames.',
    novaRede: 'Nova rede',
    numeroExame: 'Número do exame',
    ocupacional: 'Ocupacional',
    opcoes: 'Opções',
    origem: 'Origem',
    outrasInformacoes: 'Outras informações',
    outrasInfosPert: 'Outras informações pertinentes',
    pacs: 'PACS',
    pesquisar: 'Pesquisar',
    previewImagem: 'Visualização da imagem',
    proximoExame: 'Próximo exame',
    publicar: 'Publicar',
    qualquerTermo: 'Qualquer um dos termos',
    redeAlterada: 'Rede alterada',
    remover: 'Remover',
    responsavelEnvio: 'Responsável pelo envio',
    resultadoExames: 'Resultado de exames anteriores',
    resultadosExamesAnteriores: 'Resultado de exames anteriores',
    resumoHistoria: 'Resumo da história clínica',
    resumoLaudo: 'Resumo do laudo',
    salvandoAntecedente: 'Salvando antecedente',
    semLaudo: 'Sem laudo',
    semLaudo72h: 'Sem laudo há 72h',
    solicitacaoRegistrada: 'Solicitação registrada',
    subcategoria: 'Subcategoria',
    termosDescricao: 'Termos da descrição do exame',
    teste: 'Teste',
    tipo: 'Tipo',
    tipoContato: 'Tipo de contato',
    tipoExame: 'Tipo de exame',
    titulo: 'Envio de imagens',
    tituloConfirmarEnvioExame: 'Deseja enviar as imagens?',
    tituloModalCancelamento: 'Confirmação de cancelamento',
    todasImagens: 'Todas as imagens',
    todosTermos: 'Todos os termos',
    trocarRede: 'Trocar rede',
    ultrapassouTempoPrevisto: 'Ultrapassou o tempo previsto para laudo',
    valido: 'Válido',
    tituloSenteDificuldadesDormirTresVezesSemana: 'O paciente sente as seguintes dificuldades para dormir pelo menos três vezes por semana?',
    demoraIniciarSono: 'Demora para iniciar o sono',
    haQuantoTempo: 'Há quanto tempo?',
    ha: 'há',
    variosDespertaresMeioSono: 'Vários despertares no meio do sono',
    despertaCedoDemais: 'Desperta cedo demais',
    tituloSenteSonolenciaDuranteDia: 'Intensidade de sonolência ou fadiga/cansaço que o paciente sente durante o dia',
    nenhuma: 'Nenhuma',
    leve: 'Leve',
    moderada: 'Moderada',
    intensa: 'Intensa',
    tituloRonca: 'O paciente ronca',
    simTodasNoites: 'Sim, todas as noites',
    deVezEnquando: 'De vez enquando',
    naoRonco: 'Não ronca',
    naoSei: 'Não sei',
    tituloRelatouDificuldadeRespirar: 'O paciente tem dificuldade de respirar (apneias) ao dormir',
    tituloApresentaComportamentosRelacionadosSono: 'O paciente apresenta ou apresentou nos últimos 6 meses os seguintes comportamentos/sintomas relacionados ao sono?',
    movimentosRepetidosDuranteSono: 'Movimentos repetidos involuntários das pernas durante o sono percebidos pelo parceiro(a)',
    desconfortoPernas: 'Desconforto nas pernas, com necessidade de movimentação para alívio, ocorrendo especialmente ao deitar para dormir',
    todasNoites: 'Todas as noites',
    algumasNoites: 'Algumas noites',
    tituloListeProblemasSaude: 'Por favor, liste aqui os problemas de saúde do paciente (coração, pulmão, diabetes, sono, etc)',
    problemasSaude: 'Problemas de saúde',
    tituloAdicioneMedicamentosMotivos: 'Adicione os medicamentos utilizados pelo paciente nas últimas 48 horas, em conjunto com o motivo de uso',
    medicamentos48horas: 'Medicamentos utilizados pelo paciente nas últimas 48 horas',
    medicamentos: 'Medicamentos',
    motivo: 'Motivo',
    grauDesconfortoAparelhoPolissonografia: 'Qual o grau de desconforto em dormir com o aparelho de polissonografia?',
    desconfortoAparelhoPolissonografia: 'Desconforto com o aparelho de polissonografia',
    descricaoCausasDesconforto: 'Causas de desconforto',
    nenhumIncomodo: 'Nenhum incômodo',
    incomodoLeve: 'Incomodo leve',
    incomodoModerado: 'Incomodo moderado',
    incomodoIntenso: 'Incomodo intenso',
    tituloEspecificacaoCausaDesconforto: 'Se o paciente sentiu-se desconfortável, por favor especifique a causa do desconforto:',
    causasDesconforto: 'Especifique as causas do desconforto',
    informacoesNoiteExame: 'Informações sobre a noite do exame:',
    horasDeitouDormir: 'Que horas o paciente se deitou para dormir?',
    tempoDemorouIniciarSono: 'Quanto tempo o paciente demorou para iniciar o sono?',
    vezesDespertouDuranteNoite: 'Quantas vezes o paciente despertou durante a noite?',
    tempoAchaDormiuEstaNoite: 'Quanto tempo o paciente acha que dormiu esta noite?',
    comparadoSonoHabitualSonoNoite: 'Comparado com o sono habitual do paciente, como foi esta noite de sono?',
    anexoExterno: 'Anexo externo',
    totalVezes: 'Total de vezes',
    dentroNormalidade: 'Dentro da normalidade',
    aumentadoGrauLeve: 'Aumentado em grau leve',
    aumentadoGrauModerado: 'Aumentado em grau moderado',
    aumentadoGrauAcentuado: 'Aumentado em grau acentuado',
    ierHora: 'Taxa IER/hora',
    spo2Porcentagem: 'SpO2 mínima %',
    indiceEventosRespiratorios: 'Índice de Eventos Respiratórios (IER)'
};
