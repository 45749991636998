/** Exportação de Bibliotecas para serem usados em outros sistemas. */
'use strict';
const Constantes = require('./constantes');
const Servicos = require('./servicos');
const Modelos = require('./modelos');
const Util = require('./util');
const Acl = require('./util/acl');
const Rbac = require('./util/rbac');
const Erros = require('./erros');
const ServicosMonitorados = require('./util/servicosMonitorados');
const Strings = require('./strings');

module.exports = {
    Constantes,
    Servicos,
    Modelos,
    Util,
    Acl,
    Rbac,
    Erros,
    ServicosMonitorados,
    Strings
}
