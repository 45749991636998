export const estadoInicialForm = {
    requisicao: '',
    numero: '',
    de: new Date(),
    ate: null,
    ufOrigem: null,
    ufDestino: null,
    cidadeOrigem: null,
    cidadeDestino: null,
    regional: null,
    nome: '',
    cpf: '',
    transporte: null,
    situacao: null,
    altaHospitalar: false,
    numeroFatura: '',
    motivoCancelamento: null
}