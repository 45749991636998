import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/apm';

const initializeApp = (config) => {
    /* eslint-disable */
    global.gConfig = config;

    ReactDOM.render(<App modulo={'tfdPassagens'}/>, document.getElementById('root'));
}

Sentry.init({
    dsn: "https://1c4bceb00595457b92ee7012d59bac4e@sentry.telessaude.ufsc.br/9",
    integrations: [
        new Integrations.Tracing(),
    ],
    tracesSampleRate: 1.0,
});

fetch(`${process.env.PUBLIC_URL}/config/config.json`, { headers: { 'Content-Type': 'application/json' } })
    .then((response) => response.json())
    .then((config) => initializeApp(config));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
