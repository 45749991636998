export const CONTATO = {
    CATEGORIA: {
        TELEFONE_RESIDENCIAL: 1,
        TELEFONE_CELULAR: 2,
        TELEFONE_COMERCIAL: 3,
        EMAIL: 4,
        FAX: 5,
        OUTRO: 6
    }
}

export const PERFIL = {
    ADMIN_TELEMEDICINA: 'adminTelemedicina',
    ADMINISTRADOR: 'administrador',
    TFD_AGENDADOR: 'tfdAgendador',
    TFD_ESTADUAL: 'tfdEstadual',
    TFD_MUNICIPAL: 'tfdMunicipal',
    TFD_REGULADOR: 'tfdRegulador',
    TFD_VISUALIZADOR: 'tfdVisualizador'
};

export const PERMISSOES = {
    ADMINISTRATIVO: 'TFDPASSAGENS_ADMINISTRATIVO',
    ARQUIVAMENTO: 'TFDPASSAGENS_ARQUIVAMENTO',
    DEVOLUCAO: 'TFDPASSAGENS_DEVOLUCAO',
    ENCAMINHAR: 'TFDPASSAGENS_ENCAMINHAR',
    ENCAMINHAR_REGULADOR: 'TFDPASSAGENS_ENCAMINHAR_REGULADOR',
    FATURA: 'TFDPASSAGENS_FATURA',
    SUSPENDER: 'TFDPASSAGENS_SUSPENDER',
    MOTORISTA: 'TFDPASSAGENS_MOTORISTA',
    PASSAGENS: 'TFDPASSAGENS_TFDPASSAGENS',
    REGULAR: 'TFDPASSAGENS_REGULAR',
    SOLICITACAO: 'TFDPASSAGENS_SOLICITACAO',
    VISUALIZACAO: 'TFDPASSAGENS_VISUALIZACAO'
};

const situacao = {
    CANCELADO: -10,
    RASCUNHO: 0,
    AGUARDANDO_AVALIACAO_ESTADUAL: 5,
    DEVOLVIDO_ESTADUAL: 10,
    AGUARDANDO_COMPRA_PASSAGEM: 15,
    AGUARDANDO_AVALIACAO_REGULADOR: 20,
    DEVOLVIDO_REGULADOR: 23,
    APROVADA_REGULADOR: 25,
    PASSAGEM_COMPRADA: 30,
    FATURADO: 35,
    SUSPENSO: 38,
    ARQUIVADO: 40,
    VIAGEM_AGENDADA: 45,
    AGUARDANDO_ESCALA: 50
}

export const TIPO_TRANSPORTE = {
    AEREO: 'AE',
    AMBULANCIA: 'AM',
    RODOVIARIO: 'RO'
}

export const TRANSPORTE_RECOMENDAVEL = {
    AMBULANCIA: 'AM',
    ONIBUS_LEITO: 'OL'
}

export const SITUACAO_PROCESSO = {
    SITUACAO: { ...situacao },
    LABEL: {
        [situacao.CANCELADO]: 'Cancelado',
        [situacao.RASCUNHO]: 'Rascunho',
        [situacao.AGUARDANDO_AVALIACAO_ESTADUAL]: 'Aguardando avaliação estadual',
        [situacao.DEVOLVIDO_ESTADUAL]: 'Devolvido pelo estadual',
        [situacao.AGUARDANDO_COMPRA_PASSAGEM]: 'Aguardando compra/solicitação de viagem',
        [situacao.AGUARDANDO_AVALIACAO_REGULADOR]: 'Aguardando avaliação pelo regulador',
        [situacao.DEVOLVIDO_REGULADOR]: 'Devolvido pelo regulador',
        [situacao.APROVADA_REGULADOR]: 'Aprovado pelo regulador',
        [situacao.PASSAGEM_COMPRADA]: 'Passagem comprada (aguardando fatura)',
        [situacao.FATURADO]: 'Faturado',
        [situacao.SUSPENSO]: 'Suspenso',
        [situacao.ARQUIVADO]: 'Arquivado',
        [situacao.VIAGEM_AGENDADA]: 'Viagem agendada',
        [situacao.AGUARDANDO_ESCALA]: 'Aguardando escala'
    },
    COR: {
        [situacao.CANCELADO]: '#828282',
        [situacao.RASCUNHO]: '#E3C5FC',
        [situacao.AGUARDANDO_AVALIACAO_ESTADUAL]: '#b1b551',
        [situacao.DEVOLVIDO_ESTADUAL]: '#F3A46B',
        [situacao.AGUARDANDO_COMPRA_PASSAGEM]: '#b2b3a6',
        [situacao.AGUARDANDO_AVALIACAO_REGULADOR]: '#7d8038',
        [situacao.DEVOLVIDO_REGULADOR]: '#F3A46B',
        [situacao.APROVADA_REGULADOR]: '#729fcf',
        [situacao.PASSAGEM_COMPRADA]: '#9CACD9',
        [situacao.SUSPENSO]: '#D8D8D8',
        [situacao.FATURADO]: '#8AC58B',
        [situacao.ARQUIVADO]: '#ad7fa8',
        [situacao.VIAGEM_AGENDADA]: '#7f987b',
        [situacao.AGUARDANDO_ESCALA]: '#a3a2a2'
    }
}

const tipoProcesso = {
    IDA: 'I',
    VOLTA: 'V',
    AMBOS: 'A'
}

export const TIPO_PROCESSO = {
    TIPO: { ...tipoProcesso },
    LABEL: {
        [tipoProcesso.IDA]: 'Ida',
        [tipoProcesso.VOLTA]: 'Volta',
        [tipoProcesso.AMBOS]: 'Ida e Volta',
    }
}

export const TIPO_USUARIO = {
    INTERNO: 'INTERNO',
    EXTERNO: 'EXTERNO'
}

export const OPERACAO = {
    ALTERAR: 'ALTERAR',
    ANEXAR_COMPROVANTE_PASSAGEM: 'ANEXAR_COMPROVANTE_PASSAGEM',
    APROVAR: 'APROVAR',
    ARQUIVAR: 'ARQUIVAR',
    CANCELAR: 'CANCELAR',
    CRIAR_PROCESSO_ESPELHO: 'CRIAR_PROCESSO_ESPELHO',
    DEVOLVER: 'DEVOLVER',
    ENCAMINHAR_APROVACAO_REGULADOR: 'ENCAMINHAR_APROVACAO_REGULADOR',
    ENCAMINHAR_SOLICITACAO_VIAGEM: 'ENCAMINHAR_SOLICITACAO_VIAGEM',
    FATURA: 'FATURA',
    SUSPENDER: 'SUSPENDER',
    REABRIR: 'REABRIR',
    SELECIONAR_MOTORISTA: 'SELECIONAR_MOTORISTA',
    VOLTAR_PROCESSO: 'VOLTAR_PROCESSO'
}

export const ETAPA_FLUXO_DEVOLUCAO = {
    ESTADUAL: 'E',
    REGULADOR: 'M',
    AMBOS: 'A'
};

export const ETAPA_FLUXO_CANCELAMENTO = {
    ANTES: 'N',
    DEPOIS: 'D',
    AMBOS: 'A'
};