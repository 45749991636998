import * as yup from 'yup';
import {
    ITINERARIO,
    DATA_IDA,
    DATA_VOLTA,
    TIPO_PROCESSO,
    ALTA_HOSPITALAR
} from './fieldNames';
import * as Constantes from '../../../../common/Constants';

const TIPO_PROCESSO_CONST = Constantes.TIPO_PROCESSO;

export default (strings) => {
    return yup.object().shape({
        [ITINERARIO]: yup.object().shape({
            [TIPO_PROCESSO]: yup
                .string()
                .nullable()
                .required(strings.campoObrigatorio),
            [DATA_IDA]: yup
                .date()
                .nullable()
                .typeError(strings.dataInvalida)
                .when(TIPO_PROCESSO, {
                    is: (val) => val && (val === TIPO_PROCESSO_CONST.TIPO.AMBOS),
                    then: yup
                        .date()
                        .when(DATA_VOLTA, {
                            is: (val) => val,
                            then: yup
                                .date()
                                .max(yup.ref(DATA_VOLTA), strings.erroDataFutura)
                        })
                        .nullable()
                        .required(strings.campoObrigatorio),
                    otherwise: yup
                        .date()
                        .when(TIPO_PROCESSO, {
                            is: (val) => val && (val === TIPO_PROCESSO_CONST.TIPO.IDA),
                            then: yup
                                .date()
                                .nullable()
                                .required(strings.campoObrigatorio)
                        })
                }),
            [DATA_VOLTA]: yup
                .date()
                .typeError(strings.dataInvalida)
                .nullable()
                .when(TIPO_PROCESSO, {
                    is: (val) => val && (val === TIPO_PROCESSO_CONST.TIPO.AMBOS),
                    then: yup
                        .date()
                        .nullable()
                        .required(strings.campoObrigatorio),
                    otherwise: yup
                        .date()
                        .when(TIPO_PROCESSO, {
                            is: (val) => val && (val === TIPO_PROCESSO_CONST.TIPO.VOLTA),
                            then: yup
                                .date()
                                .nullable()
                                .when(ALTA_HOSPITALAR, {
                                    is: (val) => !val,
                                    then: yup
                                        .date()
                                        .nullable()
                                        .required(strings.campoObrigatorio)
                                })
                        })
                }),
        }).required()
    });
}
