export const REQUISICAO = 'requisicao';
export const NUMERO = 'numero';
export const DATA_DE = 'de';
export const DATA_ATE = 'ate';
export const UF_ORIGEM = 'ufOrigem';
export const UF_DESTINO = 'ufDestino';
export const CIDADE_ORIGEM = 'cidadeOrigem';
export const CIDADE_DESTINO = 'cidadeDestino';
export const NOME_PACIENTE = 'nome';
export const CPF_PACIENTE = 'cpf';
export const TRANSPORTE = 'transporte';
export const SITUACAO = 'situacao';
export const REGIONAL = 'regional';
export const ALTA_HOSPITALAR = 'altaHospitalar';
export const NUMERO_FATURA = 'numeroFatura';
export const MOTIVO_CANCELAMENTO = 'motivoCancelamento';