import React, { useRef, useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
    SttButton,
    SttLoading,
    SttGrid,
    SttFormControl,
    SttRadioGroup,
    SttFormControlLabel,
    SttRadioButton,
    SttHeading,
    SttInput,
    SttModal,
    SttAlerta,
    SttTranslateHook
} from '@stt-componentes/core';
import {
    Formik,
    FastField,
    Field
} from 'formik';
import HttpStatus from 'http-status-codes';
import * as yup from 'yup';
import axios from 'axios';
import { getHeaders } from '../../../request';

const validationSchema = (strings) => {
    return yup.object().shape({
        'descricao': yup
            .string()
            .trim()
            .min(10)
            .max(255)
            .matches(/(\w.+\s).+/i, strings.erroConteudoHtml)
            .nullable()
            .required(strings.campoObrigatorio),
    });
}

const useStyles = makeStyles(theme => ({
    button: {
        marginBottom: theme.spacing(1)
    }
}));

const FormMotivoCancelamento = ({ callback, callbackSucesso, motivo }) => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const schema = validationSchema(strings);
    const formRef = useRef();
    const classes = useStyles();

    const TFD_PASSAGENS_API_BASE_URL = global.gConfig.url_base_tfd_passagens;

    const handleCloseAlerta = () => {
        setMostrarAlerta(false);
    }

    //Alerta 
    const [mostrarAlerta, setMostrarAlerta] = useState(false);
    const [tituloAlerta, setTituloAlerta] = useState('');
    const [mensagemAlerta, setMensagemAlerta] = useState('');
    const [tipoAlerta, setTipoAlerta] = useState('alert');
    const [opcoesAlerta, setOpcoesAlerta] = useState([]);
    const [onCloseAlerta, setOnCloseAlerta] = useState(() => handleCloseAlerta);

    return (
        <>
            <SttModal
                title={strings.cadastroMotivoCancelamento}
                open={true}
                maxWidth="md"
                outModalClose={callback}
                iconClose={callback}
                fullWidth={true}
                children={
                    <Formik
                        innerRef={formRef}
                        initialValues={motivo}
                        validationSchema={schema}
                        onSubmit={(dados, { setSubmitting }) => {
                            setSubmitting(true);

                            axios.post(`${TFD_PASSAGENS_API_BASE_URL}/motivo-cancelamento`, dados, { headers: getHeaders() })
                                .then((response) => {
                                    const { data } = response;
                                    if (response.status === HttpStatus.OK) {
                                        setTituloAlerta(strings.sucesso);
                                        setMensagemAlerta(data?.message ? data.message : strings.operacaoSucesso);
                                        setTipoAlerta('success');
                                        setOpcoesAlerta([
                                            {
                                                title: strings.ok,
                                                onClick: () => {
                                                    setMostrarAlerta(false);
                                                    callbackSucesso();
                                                }
                                            }
                                        ]);
                                        setOnCloseAlerta(() => callbackSucesso);
                                        setMostrarAlerta(true);
                                    } else {
                                        setTituloAlerta(strings.erro);
                                        setMensagemAlerta(data?.message ? data.message : strings.erroSalvarMotivoCancelamento);
                                        setTipoAlerta('error');
                                        setOpcoesAlerta([
                                            {
                                                title: strings.ok,
                                                onClick: () => {
                                                    setMostrarAlerta(false);
                                                }
                                            }
                                        ]);
                                        setMostrarAlerta(true);
                                    }
                                })
                                .catch(err => {
                                    console.log(err);
                                    const { data } = err;
                                    setTituloAlerta(strings.erro);
                                    setMensagemAlerta(data?.message ? data.message : strings.erroSalvarMotivoCancelamento);
                                    setTipoAlerta('error');
                                    setOpcoesAlerta([
                                        {
                                            title: strings.ok,
                                            onClick: () => {
                                                setMostrarAlerta(false);
                                            }
                                        }
                                    ]);
                                    setMostrarAlerta(true);
                                })
                                .finally(() => {
                                    setSubmitting(false);
                                });
                        }}
                    >
                        {
                            ({
                                isSubmitting,
                                handleSubmit,
                                resetForm,
                            }) => {
                                return (
                                    <form onSubmit={handleSubmit} noValidate>
                                        <SttGrid container spacing={3}>
                                            <SttGrid item xs={12}>
                                                <FastField name="descricao">
                                                    {({
                                                        meta,
                                                        field,
                                                    }) => (
                                                        <SttInput
                                                            inputProps={{
                                                                maxLength: 255
                                                            }}
                                                            {...field}
                                                            required
                                                            label={strings.descricao}
                                                            error={meta.touched && meta.error ? true : false}
                                                            helperText={meta.touched && meta.error ? meta.error : undefined}
                                                        />
                                                    )}
                                                </FastField>
                                            </SttGrid>
                                            <SttGrid item xs={12} sm={6}>
                                                <SttHeading required variant="h5" color="primary">{strings.exigeComplemento}</SttHeading>
                                                <Field name='exigeComplemento'>
                                                    {({
                                                        field,
                                                        form: { setFieldValue },
                                                    }) => (
                                                        <SttFormControl variant="outlined">
                                                            <SttRadioGroup row
                                                                onChange={(e, item) => {
                                                                    setFieldValue('exigeComplemento', item === 'true')
                                                                }
                                                                }
                                                            >
                                                                <SttFormControlLabel
                                                                    {...field}
                                                                    control={
                                                                        <SttRadioButton
                                                                            type="radio"
                                                                            value={true}
                                                                            color="primary"
                                                                            checked={field.value}
                                                                        />
                                                                    }
                                                                    label={strings.sim}
                                                                />
                                                                <SttFormControlLabel
                                                                    {...field}
                                                                    control={
                                                                        <SttRadioButton
                                                                            type="radio"
                                                                            value={false}
                                                                            color="primary"
                                                                            checked={!field.value}
                                                                        />
                                                                    }
                                                                    label={strings.nao}
                                                                />
                                                            </SttRadioGroup>
                                                        </SttFormControl>
                                                    )}
                                                </Field>
                                            </SttGrid>
                                            <SttGrid item xs={12} sm={6}>
                                                <SttHeading required variant="h5" color="primary">{strings.ativo}</SttHeading>
                                                <Field name='ativo'>
                                                    {({
                                                        field,
                                                        form: { setFieldValue },
                                                    }) => (
                                                        <SttFormControl variant="outlined" disabled={motivo.novo}>
                                                            <SttRadioGroup row
                                                                onChange={(e, item) => {
                                                                    setFieldValue('ativo', item === 'true')
                                                                }
                                                                }
                                                            >
                                                                <SttFormControlLabel
                                                                    {...field}
                                                                    control={
                                                                        <SttRadioButton
                                                                            type="radio"
                                                                            value={true}
                                                                            color="primary"
                                                                            checked={field.value}
                                                                        />
                                                                    }
                                                                    label={strings.sim}
                                                                />
                                                                <SttFormControlLabel
                                                                    {...field}
                                                                    control={
                                                                        <SttRadioButton
                                                                            type="radio"
                                                                            value={false}
                                                                            color="primary"
                                                                            checked={!field.value}
                                                                        />
                                                                    }
                                                                    label={strings.nao}
                                                                />
                                                            </SttRadioGroup>
                                                        </SttFormControl>
                                                    )}
                                                </Field>
                                            </SttGrid>
                                        </SttGrid>
                                        <SttLoading
                                            open={isSubmitting}
                                            text={strings.salvandoMensagemEspera}
                                        />
                                        <SttGrid container spacing={3}>
                                            <SttGrid item xs={12}>
                                                <SttButton
                                                    type="submit"
                                                    variant="contained"
                                                    color="primary"
                                                    nomarginleft="true"
                                                    className={classes.button}
                                                    disabled={isSubmitting}
                                                >
                                                    {strings.salvar}
                                                </SttButton>
                                                <SttButton
                                                    type="button"
                                                    variant="outlined"
                                                    color="primary"
                                                    disabled={isSubmitting}
                                                    className={classes.button}
                                                    onClick={() => {
                                                        resetForm({ values: motivo });
                                                    }}
                                                >
                                                    {strings.limpar}
                                                </SttButton>
                                                <SttButton
                                                    type="button"
                                                    variant="outlined"
                                                    color="primary"
                                                    disabled={isSubmitting}
                                                    className={classes.button}
                                                    onClick={() => {
                                                        callback();
                                                    }}
                                                >
                                                    {strings.fechar}
                                                </SttButton>
                                            </SttGrid>
                                        </SttGrid>
                                    </form>
                                )
                            }
                        }
                    </Formik>
                }
            />
            <SttAlerta
                open={mostrarAlerta}
                title={tituloAlerta}
                message={mensagemAlerta}
                type={tipoAlerta}
                options={opcoesAlerta}
                onClose={onCloseAlerta}
            />
        </>
    );
}

export default FormMotivoCancelamento;