import TYPES from './types/pesquisa';
import { estadoInicialForm } from '../componentes/pesquisa/estadoInicialForm';

const INITIAL_STATE = {
    parametrosPesquisa: estadoInicialForm,
    executarBusca: false
};

const pesquisa = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;
    switch (type) {
        case TYPES.SET_PARAMETROS_PESQUISA:
            return {
                ...state,
                parametrosPesquisa: payload.parametros
            };
        case TYPES.SET_EXECUTAR_BUSCA:
            return {
                ...state,
                executarBusca: payload.executar
            };
        case TYPES.LIMPAR_PARAMETROS_PESQUISA:
            return INITIAL_STATE;

        default:
            return state; 
    }
}

export default pesquisa;