import React, { useEffect } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { connect } from 'react-redux';
import { setAuthenticated, setUser } from '../reducers/actions';
import { Switch, Route, useHistory } from 'react-router-dom';
import Menu from '../componentes/menu';
import Solicitacao from './solicitacao';
import Administrativo from './administrativo';
import SolicitacoesTfdPassagens from './pesquisa';
import {
    SttCircularProgress,
    SttCacheManager
} from '@stt-componentes/core';
import { Constants } from '@stt-componentes/cache';
import Erro401 from './erro/401';

const useStyles = makeStyles(theme => ({
    wrapper: {
        paddingBottom: theme.spacing(4)
    },
    loading: {
        display: 'flex',
        justifyContent: 'center',
        paddingTop: '300px'
    }
}));

const Interno = ({ isAuthenticated, setAuthenticated, setUser, idPassagem }) => {
    const classes = useStyles();
    const history = useHistory();

    useEffect(() => {
        if (idPassagem) {
            history.push("/solicitacao");
        }
    }, [idPassagem]);

    return (
        <div>
            {
                isAuthenticated
                    ?
                    <div className={classes.wrapper}>
                        <Menu />
                        <Switch>
                            <Route path="/nao-autorizado" exact component={Erro401} />
                            <Route path="/solicitacao" exact component={Solicitacao} />
                            <Route path="/administrativo" exact component={Administrativo} />
                            <Route path={['/', '/pesquisa']} exact component={SolicitacoesTfdPassagens} />
                        </Switch>
                    </div>
                    :
                    <>
                        <div className={classes.loading}>
                            <SttCircularProgress />
                        </div>
                        <SttCacheManager config={global.gConfig} onSync={loaded => {
                            const user = JSON.parse(localStorage.getItem(Constants.LOCALSTORAGE_ITEMS.USER_INFO));
                            setUser(user);
                            setAuthenticated(loaded);
                        }} />
                    </>
            }
        </div>
    );

};

const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.index.isAuthenticated,
        idPassagem: state.solicitacao.idPassagem
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setAuthenticated: (authenticated) => dispatch(setAuthenticated(authenticated)),
        setUser: (user) => dispatch(setUser(user))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Interno);