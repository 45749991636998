const temPerfilRBAC = (usuario, perfil) => {
    if (!perfil || !usuario) {
        return false;
    }
    if (Array.isArray(perfil)) {
        return perfil.some(p => usuario.perfisRBAC.some(prbac => prbac.identificador === p));
    }
    return usuario.perfisRBAC.some(p => p.identificador === perfil);
};

const temPermissaoRBAC = (usuario, permissao) => {
    if (!permissao || !usuario) {
        return false;
    }
    if (Array.isArray(permissao)) {
        return permissao.some(p => usuario.permissoesRBAC.includes(p));
    }
    return usuario.permissoesRBAC.includes(permissao);
};

export { temPerfilRBAC, temPermissaoRBAC };