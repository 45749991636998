const stringsComuns = require("./comum");

module.exports = {
  Comuns: {
    ...stringsComuns,
    paginaInicial: "Página Inicial",
    erro: "Erro",
    alerta: "Alerta",
    sucesso: "Sucesso",
    adicionarFiltro: "Adicionar filtro",
    adicioneFiltros: "Adicione filtros",
    carregando: "Carregando",
    nomeRelatorio: "Nome do relatório",
    removerFiltro: "Remover filtro",
    executarConsulta: "Executar consulta",
    exportar: "Exportar",
    salvar: "Salvar",
    cancelar: "Cancelar",
    salvarRelatorio: "Salvar relatório",
    selecioneCubo: "Selecione um cubo",
    selecioneVariaveis: "Selecione as variáveis",
    tipoArquivo: "Tipo de arquivo",
    variaveis: "Variáveis",
    renomearColuna: "Renomear coluna",
    adicionarMetrica: "Adicionar Métrica",
    ultimoProcessamento: "Término do último processamento",
    tabelaNormal: "Tabela normal",
    tabelaDinamica: "Tabela dinâmica",
    expTabelaDinamica: "Exportar tabela dinâmica",
  },
  PaginaInicial: {
    monitoracaoEventos: "Mapa Interativo",
    monitoracaoEventosTeledermato: "Mapa Interativo para Teledermatologia",
    dashboardTeleconsultoria: "Teleconsultorias - Gestão Municipal",
    dashboardTeleconsultoriaEstadual: "Teleconsultorias - Gestão Estadual",
    dashboardAlofono: "alôfono",
    dashboardAlofonoIndividual: "alôfono paciente",
    dashboardItinerarioExames: "Exames",
    dashboardItinerarioSolicitacoes: "Solicitações",
    dashboardItinerarioCidades: "Cidades",
    dashboardItinerarioCidadesUfsc:
      "Cidades (exceto municípios de Santa Catarina)",
    dashboardItinerarioPontos: "Pontos",
    pivottable: "Tabela de Dados",
    agendamento: "Agendamento",
    administrativo: "Administrativo",
    saiku: "Saiku",
    loading: "Loading...",
    aplicativos: "Aplicativos",
    relatoriosSalvos: "Relatórios Salvos",
  },
  Mensagens: {
    err001: "Ocorreu um erro no sistema. Tente novamente mais tarde.",
    err002: "Ocorreu um erro no acesso ao cubo. Tente novamente mais tarde.",
    ale001: "Nenhum resultado encontrado!",
    suc001: "Relatório salvo com sucesso!",
    mensagemCon001:
      "Nenhum filtro foi adicionado à sua consulta. Você deseja prosseguir sem aplicar filtros?",
    tituloCon001: "Deseja continuar?",
    mensagemCon002:
      "Foram encontradas {num} de linhas no resultado da busca. Visualizar tantos dados pode afetar o desempenho da Tabela de Dados. Você gostaria de prosseguir com a exibição dos resultados?",
    tituloCon002: "Deseja continuar?",
    tituloCon003: "Deseja continuar?",
    tituloCon004: "Deseja continuar?",
    mensagemCon003:
      "Já existe um relatório com o mesmo nome. Tem certeza que deseja continuar?",
    mensagemCon004: "Tem certeza que deseja excluir?",
  },
  Relatorio: {
    abrir: "Abrir",
    abrirTooltip: "Abrir relatório para execução",
    excluir: "Excluir",
    excluirTooltip: "Excluir Relatório",
  },
  TabelaDados: {
    outrosCubos: "Outros cubos",
  },
  Agendamento: {
    ativado: "Ativado",
    ativo: "Ativo",
    ativoModal: "Ativa *",
    alterar: "Alterar",
    agendamento: "Agendamento",
    atualizadoComSucesso: "Agendamento atualizado com sucesso!",
    cadaMes: "de cada mês, às",
    cadaSemana: "de cada semana, às",
    cadastrar: "Cadastrar",
    cadastrarRelatorio: "Cadastrar Relatório",
    desativado: "Desativado",
    descricao: "Descrição",
    dia: "dia",
    de: "de",
    descricao: "Descrição",
    diaSemana: "Dia da semana",
    dtFinalExecucao: "Data final de execução",
    dtInicialExecucao: "Data inicial de execução",
    email: "Email",
    execucao: "Próxima execução",
    deletar: "Deletar",
    deletarRelatorio: "Deletando relatório...",
    testarEmail: "Enviando e-mail...",
    hora: "hora",
    intervaloPeriodica: "Intervalo",
    linhasPorPagina: "Linhas por página",
    limpar: "Limpar",
    nao: "não",
    nenhumRegistroEncontrado: "Nenhum Registro Encontrado",
    periodicidade: "Periodicidade",
    pesquisar: "Pesquisar",
    repetir: "Repetir",
    salvar: "Salvar",
    salvarCadastro: "Salvando...",
    sim: "sim",
    testar: "Testar",
    toda: "Toda",
    todoDia: "Todo dia",
    tipoRelatorio: "Tipo de Relatório",
    titulo: "Agendamento de execução",
    tituloCadastro: "Título",
    variavelPeriodica: "Variável periódica",
    validadorTemplate: "Template é obrigatório",
    validadorFiltroIntervaloVariavel:
      "Para acessar Variável Periódica é preciso preencher Tipo de Relatório",
    validadorFiltroIntervaloValor: "Intervalo é obrigatório",
    validadorTipoCron: "Periodicidade é obrigatória",
    validadorTituto: "Título é obrigatório",
    validadorInicialExecucao:
      "A data inicial de execução é obrigatória. Por favor, insira uma data válida.",
    instrucaoAgendamentoPadronizado: `<span>- Permite o cadastro e a pesquisa de agendamentos futuros para envio automatizado de relatórios padronizados.</span>
    <span>- Configure agendamentos para envio recorrente via e-mail, com opções de ativação conforme necessidade.</span><span>- Ajuste e visualize agendamentos existentes, com detalhes sobre periodicidade, destinatários e status.</span>`,
  },
  AgendamentoTabelaDeDados: {
    agendado: "Agendado",
    ativo: "Ativo",
    ativado: "Ativado",
    cron: "Cron",
    cubo: "Cubo",
    desativado: "Desativado",
    dtInicialExecucao: "Data inicial de execução",
    email: "Email",
    execucao: "Próxima execução",
    instrucaoTabelaDeDados: `<span>- Permite a busca de agendamentos programados para o envio automatizado de relatórios extraídos da Tabela de Dados.</span>
    <span>- Configure agendamentos para envio recorrente via e-mail, com opções de ativação conforme necessidade.</span><span>- Facilita a visualização de agendamentos atuais, exibindo informações detalhadas como o cubo de dados relacionado, o tipo de tabela escolhido (Dinâmica ou Normal), o usuário que programou e a data da próxima execução.</span>`,
    intervaloPeriodica: "Intervalo",
    login: "Login",
    nenhumRegistroEncontrado: "Nenhum Registro Encontrado",
    periodicidade: "Periodicidade",
    repetir: "Repetir",
    salvar: "Salvar",
    tabelaDinamica: "Tabela dinâmica",
    tabelaNormal: "Tabela Normal",
    testar: "Testar",
    tipo: "Tipo",
    titulo: "Título",
    tituloAgendamento: "Agendamento de execução",
    tituloHeader: "Agendamento Tabela De Dados",
    variavelPeriodica: "Variável periódica",
    validadorIntervaloVariavel: "Variável periódica é obrigátorio",
    validadorIntervaloValor: "Variável intervalo é obrigátorio",
  },
};
