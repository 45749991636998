import React, { useEffect, useState, memo, useContext } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';
import { getHeaders } from '../../request';
import moment from 'moment';
import {
    SttButton,
    SttExpansionPanel,
    SttTextItem,
    SttHeading,
    SttContainer,
    SttCircularProgress,
    SttCarimboTempo,
    SttLink,
    SttDivider,
    SttNotification,
    SttLoading,
    SttTranslateHook
} from '@stt-componentes/core';
import { Util } from '@stt-utilitarios/core';
import { setIdTfdPassagem } from '../../reducers/actions/solicitacao';
import {
    TIPO_PROCESSO,
    SITUACAO_PROCESSO,
    OPERACAO,
    TRANSPORTE_RECOMENDAVEL
} from '../../common/Constants';
import Functions from '../../common/Functions';
import OperacaoTfd from './operacoes';

const useStyles = makeStyles(theme => ({
    carregando: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%'
    },
    textoQuebraLinha: {
        wordBreak: 'break-all',
        whiteSpace: 'pre-line',
    },
    link: {
        padding: '5px'
    },
    buttonWrapper: {
        marginTop: theme.spacing(1.5)
    },
    button: {
        marginLeft: 0,
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    notificacao: {
        marginTop: theme.spacing(1.5),
        marginBottom: theme.spacing(1.5),
    },
}));

const useStylesHeading = makeStyles(theme => ({
    root: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(2)
    }
}));

const Divider = memo((props) => {
    return (
        <SttDivider {...props} />
    )
});

const openFileUrl = (response) => {
    if (response.data) {
        const fileURL = URL.createObjectURL(response.data);
        window.open(fileURL);
    }
}

const ExibicaoAnexo = ({ titulo, anexos, classe, strings }) => {
    return (
        <div>
            {
                anexos && anexos.length > 0 &&
                <SttHeading variant="h4" color="primary" className={classe}>{titulo}</SttHeading>
            }
            {
                anexos && anexos.length > 0 &&
                anexos.map((anexo, index) => (
                    <div key={index}>
                        {
                            anexo.nome &&
                            <SttTextItem key={`A_${index}`} title={`${strings.anexo} ${index + 1}`} content={
                                <SttLink href={anexo.url} target="_blank"
                                    onClick={e => {
                                        e.preventDefault();

                                        axios.get(anexo.url, { headers: getHeaders(), responseType: 'blob' })
                                            .then((response) => openFileUrl(response))
                                            .catch(err => { console.log(err) });
                                    }}>
                                    {anexo.nome}
                                </SttLink>
                            } />
                        }
                    </div>
                ))
            }
        </div>
    )
}

const Detalhes = ({ user, id, voltar, setIdSolicitacaoSelecionada, setIdTfdPassagem }) => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const classes = useStyles();
    const classesHeading = useStylesHeading();

    const [tfd, setTfd] = useState(null);
    const [operacao, setOperacao] = useState(null);
    const [exibirModalOperacao, setExibirModalOperacao] = useState(false);
    const [exibirModalForm, setExibirModalForm] = useState(true);
    const [idProcessoEspelho, setIdProcessoEspelho] = useState(null);
    const [imprimindoTfd, setImprimindoTfd] = useState(false);

    const TFD_PASSAGENS_API_BASE_URL = global.gConfig.url_base_tfd_passagens;

    const imprimirTfd = () => {
        if (!imprimindoTfd) {
            setImprimindoTfd(true);
        }
    };

    const realizarOperacao = (op, exibirForm = true) => {
        setOperacao(op);
        setExibirModalForm(exibirForm);
        setExibirModalOperacao(true);
    }

    useEffect(() => {
        if (imprimindoTfd) {
            const idCodificado = window.btoa(id);

            const params = {
                idCodificado
            };

            axios.get(`${TFD_PASSAGENS_API_BASE_URL}/impressao`, { params, headers: getHeaders(), responseType: 'blob' })
                .then((response) => openFileUrl(response))
                .catch(err => { console.log(err) })
                .finally(() => {
                    setImprimindoTfd(false);
                });
        }
    }, [imprimindoTfd]);

    useEffect(() => {
        if (id) {
            setIdProcessoEspelho(null);
            axios.get(`${TFD_PASSAGENS_API_BASE_URL}/processo/${id}`, { headers: getHeaders() })
                .then((response) => {
                    if (response.data) {
                        const { data } = response.data;

                        data.acoes = Functions.acoes(user, data.situacao, data.tipo_processo);

                        if (data.valor_passagens) {
                            data.valor_passagens_formatado = data.valor_passagens.replace(/\D/g, '');
                        }
                        if (data.valor_reembolso) {
                            data.valor_reembolso_formatado = data.valor_reembolso.replace(/\D/g, '');
                        }

                        if (data.data_formatada && data.data_agendamento_formatada) {
                            const dataSol = moment(data.data);
                            const dataAgend = moment(data.data_agendamento);
                            const msg = `${dataAgend.diff(dataSol, 'days')} ${strings.dias}`;
                            data.diff_datas = msg.replace('-', '');
                        }

                        setTfd(data);
                    }
                })
                .catch(err => console.log(err));
        }
    }, [id]);

    useEffect(() => {
        if (idProcessoEspelho) {
            setExibirModalOperacao(false);
            setIdSolicitacaoSelecionada(idProcessoEspelho);
        }
    }, [idProcessoEspelho]);

    return (
        <SttContainer>
            <SttButton
                type="button"
                variant="outlined"
                color="primary"
                className={classes.button}
                onClick={voltar}
                nomarginleft="true"
            >
                {strings.voltar}
            </SttButton>
            {
                !tfd
                    ?
                    <div className={classes.carregando}>
                        <SttCircularProgress color="primary" />
                    </div>
                    :
                    <>
                        <SttHeading variant="h1" color="primary" align="center" >{strings.solicitacaoDeTfdPassagem}{tfd.id}</SttHeading>

                        <SttExpansionPanel
                            title={strings.dadosPaciente}
                            children={
                                <div>
                                    <SttTextItem title={strings.nome} content={tfd.nome_paciente} />
                                    <SttTextItem title={strings.cns} content={tfd.cns_paciente} />
                                    <SttTextItem title={strings.cpf} content={Util.util.adicionarMascara(`000${tfd.cpf_paciente}`, '000.000.000-00', { reverse: true })} />
                                    {
                                        tfd.raca_paciente &&
                                        <SttTextItem title={strings.racaEtnia} content={tfd.raca_paciente} />
                                    }
                                    {
                                        tfd.procedimento_solicitado &&
                                        <SttTextItem title={strings.procedimentoSolicitado} content={tfd.procedimento_solicitado} />
                                    }
                                    <SttTextItem title={strings.regionalSaude} content={tfd.gerencia_saude} />
                                </div>
                            }
                        />
                        <Divider />
                        <SttExpansionPanel
                            title={strings.dadosGeraisProcesso}
                            children={
                                <div>
                                    {
                                        tfd.acoes.podeCriarProcessoEspelho && !tfd.processoVinculo &&
                                        <SttButton
                                            type="button"
                                            variant="contained"
                                            nomarginleft="true"
                                            className={classes.button}
                                            color="primary"
                                            onClick={() => realizarOperacao(OPERACAO.CRIAR_PROCESSO_ESPELHO, false)}
                                        >
                                            {strings.criarEspelho}
                                        </SttButton>
                                    }
                                    {
                                        tfd.requisicao &&
                                        <SttTextItem title={strings.requisicao} content={tfd.requisicao} />
                                    }
                                    {
                                        tfd.data_formatada &&
                                        <SttTextItem title={strings.dataSolicitacao} content={tfd.data_formatada} />
                                    }
                                    {
                                        tfd.numero_autos_processo_sgpe &&
                                        <SttTextItem title={strings.numeroAutos} content={Util.util.adicionarMascara(tfd.numero_autos_processo_sgpe, '0000000-00.0000.0.00.0000')} />
                                    }
                                    {
                                        tfd.ano_processo_sgpe &&
                                        <SttTextItem title={strings.anoAberturaAutos} content={tfd.ano_processo_sgpe} />
                                    }
                                    {
                                        tfd.numero_processo_sgpe &&
                                        <SttTextItem title={strings.numeroProcessoSGPE} content={tfd.numero_processo_sgpe} />
                                    }
                                    {
                                        tfd.data_agendamento_formatada &&
                                        <SttTextItem title={strings.dataAgendamento} content={tfd.data_agendamento_formatada} />
                                    }
                                    {
                                        tfd.contato_setor_solicitante &&
                                        <SttTextItem title={strings.contatoSetor} content={Util.util.adicionarMascara(tfd.contato_setor_solicitante, '(00) 90000-0000')} />
                                    }
                                    {
                                        tfd.data_emissao_agendamento_formatada &&
                                        <SttTextItem title={strings.dataEmissaoAgendamento} content={tfd.data_emissao_agendamento_formatada} />
                                    }
                                    {
                                        tfd.diff_datas &&
                                        <SttTextItem title={strings.intervaloSolicitacaoAgendamento} content={tfd.diff_datas} />
                                    }
                                    {
                                        tfd.tipo_processo &&
                                        <SttTextItem title={strings.trechoTransporte} content={TIPO_PROCESSO.LABEL[tfd.tipo_processo]} />
                                    }
                                    {
                                        tfd.unidade_executante &&
                                        <SttTextItem title={strings.unidadeExecutante} content={tfd.unidade_executante} />
                                    }
                                    {
                                        tfd.observacao_tipo_processo &&
                                        <SttTextItem title={strings.dadosAdicionais} content={tfd.observacao_tipo_processo} />
                                    }
                                    {
                                        tfd.data_ida_formatada &&
                                        <SttTextItem title={strings.dataIda} content={tfd.data_ida_formatada} />
                                    }
                                    {
                                        tfd.data_volta_formatada &&
                                        <SttTextItem title={strings.dataRetorno} content={tfd.data_volta_formatada} />
                                    }
                                    {
                                        tfd.alta_hospitalar !== null &&
                                        <SttTextItem title={strings.altaHospitalar} content={tfd.alta_hospitalar ? strings.sim : strings.nao} />
                                    }
                                    {
                                        tfd.nome_cidade_origem &&
                                        <SttTextItem title={strings.cidadeOrigem} content={`${tfd.nome_cidade_origem} / ${tfd.sigla_estado_origem}`} />
                                    }
                                    {
                                        tfd.nome_cidade_destino &&
                                        <SttTextItem title={strings.cidadeDestino} content={`${tfd.nome_cidade_destino} / ${tfd.sigla_estado_destino}`} />
                                    }
                                </div>
                            }
                        />
                        <Divider />
                        <SttExpansionPanel
                            title={strings.transporte}
                            children={
                                <div>
                                    {
                                        tfd.tipo_transporte !== tfd.transporte_recomendavel_original &&
                                        <SttNotification severity="warning" className={classes.notificacao}>
                                            {strings.avisoTransporteModificado}
                                        </SttNotification>
                                    }
                                    {
                                        tfd.transporte_recomendavel &&
                                        <SttTextItem title={strings.tipoTransporte} content={tfd.transporte_recomendavel} />
                                    }
                                    {
                                        tfd.tipo_transporte === TRANSPORTE_RECOMENDAVEL.AMBULANCIA && tfd.ponto_referencia &&
                                        <SttTextItem title={strings.pontoReferencia} content={tfd.ponto_referencia} />
                                    }
                                    {
                                        tfd.transporte_recomendavel_justificativa &&
                                        <SttTextItem title={strings.justificativaTrocaTransporte} content={tfd.transporte_recomendavel_justificativa} />
                                    }
                                    {
                                        tfd.nome_motorista &&
                                        <SttTextItem title={strings.motorista} content={tfd.nome_motorista} />
                                    }
                                    {
                                        tfd.quilometragem &&
                                        <>
                                            <SttTextItem title={strings.km} content={tfd.quilometragem} />
                                            <SttTextItem title={strings.bpa} content={Math.round(parseInt(tfd.quilometragem) / 50 * 4.95)} />
                                        </>
                                    }
                                    <ExibicaoAnexo anexos={tfd.anexos.transporte} titulo={strings.anexosTransporte} classe={classesHeading.root} strings={strings} />
                                </div>
                            }
                        />
                        <Divider />
                        <SttExpansionPanel
                            title={strings.acompanhantes}
                            children={
                                <div>
                                    {
                                        tfd.numero_acompanhantes != null &&
                                        <SttTextItem title={strings.numeroAcompanhantes} content={tfd.numero_acompanhantes} />
                                    }
                                    {
                                        tfd.troca_acompanhante != null &&
                                        <SttTextItem title={strings.trocaAcompanhantes} content={tfd.troca_acompanhante ? strings.sim : strings.nao} />
                                    }
                                    {
                                        tfd.justificativa_acompanhante != null &&
                                        <SttTextItem title={strings.justificativaTrocaAcompanhantes} content={tfd.justificativa_acompanhante} />
                                    }
                                    {
                                        tfd.acompanhantes.map((acomp, index) => (
                                            acomp.nome ?
                                                <div key={index}>
                                                    <SttHeading variant="h4" color="primary" className={classesHeading.root}>{`${strings.acompanhantes} ${index + 1}`}</SttHeading>
                                                    <SttTextItem key={`A_${index}`} title={strings.nome} content={acomp.nome} />
                                                    {
                                                        acomp.cpf &&
                                                        <SttTextItem key={`B_${index}`} title={strings.cpf} content={Util.util.adicionarMascara(`000${acomp.cpf}`, '000.000.000-00', { reverse: true })} />
                                                    }
                                                    {
                                                        acomp.cns &&
                                                        <SttTextItem key={`D_${index}`} title={strings.cns} content={acomp.cns} />
                                                    }
                                                    {
                                                        acomp.descricao_raca &&
                                                        <SttTextItem key={`C_${index}`} title={strings.racaEtnia} content={acomp.descricao_raca} />
                                                    }
                                                    {
                                                        acomp.data_nascimento_formatada &&
                                                        <SttTextItem key={`E_${index}`} title={strings.dataNascimento} content={acomp.data_nascimento_formatada} />
                                                    }
                                                    {
                                                        acomp.logradouro &&
                                                        <SttTextItem key={`F_${index}`} title={strings.logradouro} content={acomp.logradouro} />
                                                    }
                                                    {
                                                        acomp.numero &&
                                                        <SttTextItem key={`Z_${index}`} title={strings.numeroCasa} content={acomp.numero} />
                                                    }
                                                    {
                                                        acomp.complemento &&
                                                        <SttTextItem key={`G_${index}`} title={strings.complemento} content={acomp.complemento} />
                                                    }
                                                    {
                                                        acomp.bairro &&
                                                        <SttTextItem key={`H_${index}`} title={strings.bairro} content={acomp.bairro} />
                                                    }
                                                    {
                                                        acomp.cep &&
                                                        <SttTextItem key={`I_${index}`} title={strings.cep} content={Util.util.adicionarMascara(acomp.cep, '00.000-000', { reverse: true })} />
                                                    }
                                                    {
                                                        acomp.nome_cidade &&
                                                        <SttTextItem key={`J_${index}`} title={strings.localizacao} content={`${acomp.nome_cidade} / ${acomp.sigla_estado}`} />
                                                    }
                                                    {
                                                        acomp.doador != null &&
                                                        <SttTextItem key={`K_${index}`} title={strings.doador} content={acomp.doador ? strings.sim : strings.nao} />
                                                    }
                                                    {
                                                        acomp.descricao_sexo != null &&
                                                        <SttTextItem key={`L_${index}`} title={strings.genero} content={acomp.descricao_sexo} />
                                                    }
                                                    {
                                                        acomp.descricao_parentesco != null &&
                                                        <SttTextItem key={`M_${index}`} title={strings.parentesco} content={acomp.descricao_parentesco} />
                                                    }
                                                    {
                                                        acomp.complemento_parentesco != null &&
                                                        <SttTextItem key={`N_${index}`} title={strings.complementoParentesco} content={acomp.complemento_parentesco} />
                                                    }
                                                </div>
                                                :
                                                null
                                        ))
                                    }
                                    <ExibicaoAnexo anexos={tfd.anexos.acompanhante} titulo={strings.anexosAcompanhantes} classe={classesHeading.root} strings={strings} />
                                </div>
                            }
                        />
                        {
                            tfd.movimentacoes.length > 0 &&
                            <>
                                <Divider />
                                <SttExpansionPanel
                                    title={strings.movimentacoes}
                                    children={
                                        <div>
                                            {
                                                tfd.movimentacoes.map((mov, index) => (
                                                    <div key={index}>
                                                        <SttHeading variant="h4" color="primary" className={classesHeading.root}>{`${strings.movimentacao} ${tfd.movimentacoes.length - index}`}</SttHeading>
                                                        <SttTextItem key={`A_${index}`} title={strings.dataHora} content={mov.data_hora_formatada} />
                                                        <SttTextItem key={`B_${index}`} title={strings.responsavel} content={mov.nome_funcionario} />
                                                        <SttTextItem key={`C_${index}`} title={strings.movimentacao} content={mov.conteudo.replace(/<br>/g, '\n')} classContent={classes.textoQuebraLinha} />
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    }
                                />
                            </>
                        }
                        {
                            tfd.carimbosTempo.length > 0 &&
                            <>
                                <Divider />
                                <SttExpansionPanel
                                    title={strings.carimbo}
                                    children={
                                        <div>
                                            {
                                                tfd.carimbosTempo.map((ct, index) => (
                                                    <SttCarimboTempo
                                                        key={index}
                                                        text={ct.hash_carimbo}
                                                        date={ct.data_hora_formatada}
                                                        url={`${TFD_PASSAGENS_API_BASE_URL}/carimbo-tempo/${ct.id_carimbo_tempo}/${id}`}
                                                    />
                                                ))
                                            }
                                        </div>
                                    }
                                />
                            </>
                        }
                        <Divider />
                        <SttExpansionPanel
                            title={strings.dadosFuncionarios}
                            children={
                                <div>
                                    {
                                        tfd.nome_funcionario_solicitante &&
                                        <SttTextItem title={strings.funcionarioSolicitante} content={tfd.nome_funcionario_solicitante} />
                                    }
                                    {
                                        tfd.nome_funcionario_preenchedor &&
                                        <SttTextItem title={strings.funcionarioPreenchedor} content={tfd.nome_funcionario_preenchedor} />
                                    }
                                    {
                                        tfd.nome_funcionario_vinculo &&
                                        <SttTextItem title={strings.funcionarioVinculo} content={tfd.nome_funcionario_vinculo} />
                                    }
                                    {
                                        tfd.nome_funcionario_regulador &&
                                        <SttTextItem title={strings.funcionarioRegulador} content={tfd.nome_funcionario_regulador} />
                                    }
                                </div>
                            }
                        />
                        {
                            tfd.processoVinculo &&
                            <>
                                <Divider />
                                <SttExpansionPanel
                                    title={strings.processoVinculo}
                                    children={
                                        <div>
                                            <SttTextItem title={strings.sequencial} content={tfd.processoVinculo.sequencial} />
                                            {
                                                tfd.processoVinculo.requisicao &&
                                                <SttTextItem title={strings.requisicao} content={tfd.processoVinculo.requisicao} />
                                            }
                                            {
                                                tfd.processoVinculo.id &&
                                                <SttLink href="#" className={classes.link}
                                                    onClick={() => {
                                                        setIdSolicitacaoSelecionada(tfd.processoVinculo.id)
                                                    }} >
                                                    {strings.prosseguirVinculo}
                                                </SttLink>

                                            }
                                        </div>
                                    }
                                />
                            </>
                        }
                        {
                            tfd.motivo_cancelamento && tfd.situacao === SITUACAO_PROCESSO.SITUACAO.CANCELADO &&
                            <>
                                <SttExpansionPanel
                                    title={strings.cancelamento}
                                    children={
                                        <div>
                                            <SttTextItem title={strings.motivoCancelamento} content={tfd.motivo_cancelamento} />
                                        </div>
                                    }
                                />
                                <Divider />
                            </>
                        }
                        {
                            tfd.motivo_devolucao && (tfd.situacao === SITUACAO_PROCESSO.SITUACAO.DEVOLVIDO_ESTADUAL || tfd.situacao === SITUACAO_PROCESSO.SITUACAO.DEVOLVIDO_REGULADOR) &&
                            <SttExpansionPanel
                                title={strings.devolucao}
                                children={
                                    <div>
                                        <SttTextItem title={strings.motivoDevolucao} content={tfd.motivo_devolucao} />
                                    </div>
                                }
                            />
                        }
                        {
                            (
                                (tfd.anexos.arquivamento?.length > 0) ||
                                (tfd.anexos.agendamento?.length > 0) ||
                                (tfd.anexos.passagem?.length > 0) ||
                                (tfd.anexos.devolucao?.length > 0) ||
                                (tfd.anexos.estadual?.length > 0) ||
                                (tfd.anexos.cancelamento?.length > 0)
                            ) &&
                            <>
                                <Divider />
                                <SttExpansionPanel
                                    title={strings.anexosGerais}
                                    children={
                                        <>
                                            <ExibicaoAnexo anexos={tfd.anexos.passagem} titulo={strings.anexosPassagens} classe={classesHeading.root} strings={strings} />
                                            <ExibicaoAnexo anexos={tfd.anexos.agendamento} titulo={strings.anexosAgendamentos} classe={classesHeading.root} strings={strings} />
                                            <ExibicaoAnexo anexos={tfd.anexos.arquivamento} titulo={strings.anexosArquivamento} classe={classesHeading.root} strings={strings} />
                                            <ExibicaoAnexo anexos={tfd.anexos.devolucao} titulo={strings.anexosDevolucao} classe={classesHeading.root} strings={strings} />
                                            <ExibicaoAnexo anexos={tfd.anexos.estadual} titulo={strings.anexosEstadual} classe={classesHeading.root} strings={strings} />
                                            <ExibicaoAnexo anexos={tfd.anexos.cancelamento} titulo={strings.anexosCancelamento} classe={classesHeading.root} strings={strings} />
                                        </>
                                    }
                                />
                            </>
                        }
                        {
                            tfd.nome_funcionario_autorizador &&
                            <>
                                <Divider />
                                <SttExpansionPanel
                                    title={strings.dadosFatura}
                                    children={
                                        <div>
                                            {
                                                tfd.valor_passagens_formatado &&
                                                <SttTextItem title={strings.valorPassagens} content={Util.util.adicionarMascara(tfd.valor_passagens_formatado, 'R$ #.##0,00', { reverse: true })} />
                                            }
                                            {
                                                tfd.reembolso !== null &&
                                                <SttTextItem title={strings.reembolso} content={tfd.reembolso ? strings.sim : strings.nao} />
                                            }
                                            {
                                                tfd.tipo_reembolso &&
                                                <SttTextItem title={strings.tipoReembolso} content={tfd.tipo_reembolso === 'P' ? strings.parcial : strings.total} />
                                            }
                                            {
                                                tfd.valor_reembolso_formatado &&
                                                <SttTextItem title={strings.valorReembolso} content={Util.util.adicionarMascara(tfd.valor_reembolso_formatado, 'R$ #.##0,00', { reverse: true })} />
                                            }
                                            <SttTextItem title={strings.funcionarioAutorizador} content={tfd.nome_funcionario_autorizador} />
                                            {
                                                tfd.fatura &&
                                                <SttTextItem title={strings.numeroFatura} content={tfd.fatura} />
                                            }
                                            {
                                                tfd.data_compra_passagem_formatada &&
                                                <SttTextItem title={strings.dataCompra} content={tfd.data_compra_passagem_formatada} />
                                            }
                                        </div>
                                    }
                                />
                            </>
                        }
                        {
                            tfd.comprovantesComparecimento?.length > 0 &&
                            <>
                                <Divider />
                                <SttExpansionPanel
                                    title={strings.comprovantes}
                                    children={
                                        <div>
                                            {
                                                tfd.comprovantesComparecimento.map((comp, index) => (
                                                    <div key={index}>
                                                        <SttHeading variant="h4" color="primary" className={classesHeading.root}>{`${strings.comprovante} ${index + 1}`}</SttHeading>
                                                        <SttTextItem key={`A_${index}`} title={strings.data} content={comp.data_formatada} />
                                                        <SttTextItem key={`B_${index}`} title={strings.funcionarioComprovante} content={comp.nome_funcionario_responsavel} />
                                                        <SttTextItem key={`C_${index}`} title={strings.numeroTfd} content={comp.numero_pedido_tfd} />
                                                        <SttTextItem key={`D_${index}`} title={strings.requisicaoPassagem} content={comp.requisicao_passagem} />
                                                        {
                                                            comp.numero_pernoites != null &&
                                                            <SttTextItem key={`E_${index}`} title={strings.numeroPernoites} content={comp.numero_pernoites} />
                                                        }
                                                        {
                                                            comp.numero_diarias != null &&
                                                            <SttTextItem key={`F_${index}`} title={strings.numeroDiarias} content={comp.numero_diarias} />
                                                        }
                                                        {
                                                            comp.numero_acompanhantes != null &&
                                                            <SttTextItem key={`G_${index}`} title={strings.numeroAcompanhantes} content={comp.numero_acompanhantes} />
                                                        }
                                                        {
                                                            comp.observacao &&
                                                            <SttTextItem key={`H_${index}`} title={strings.observacoes} content={comp.observacao} classContent={classes.textoQuebraLinha} />
                                                        }
                                                        {
                                                            comp.anexos?.length > 0 &&
                                                            <ExibicaoAnexo anexos={comp.anexos} titulo={strings.anexosComprovante} classe={classesHeading.root} strings={strings} />
                                                        }
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    }
                                />
                            </>
                        }
                        <Divider />
                        <SttLoading
                            open={imprimindoTfd}
                            text={strings.gerandoDocumento}
                        />
                        <div className={classes.buttonWrapper}>
                            {
                                tfd.acoes.podeAlterar &&
                                <SttButton
                                    type="button"
                                    variant="contained"
                                    className={classes.button}
                                    color="primary"
                                    onClick={() => {
                                        setIdTfdPassagem(id);
                                    }}
                                >
                                    {strings.alterar}
                                </SttButton>
                            }
                            {
                                (tfd.acoes.podeDevolverEstadual || tfd.acoes.podeDevolverRegulador) &&
                                <SttButton
                                    type="button"
                                    variant="contained"
                                    className={classes.button}
                                    color="primary"
                                    onClick={() => realizarOperacao(OPERACAO.DEVOLVER)}
                                >
                                    {strings.devolver}
                                </SttButton>
                            }
                            {
                                tfd.acoes.podeEncaminharCompraPassagem &&
                                <SttButton
                                    type="button"
                                    variant="contained"
                                    className={classes.button}
                                    color="primary"
                                    onClick={() => realizarOperacao(OPERACAO.ENCAMINHAR_SOLICITACAO_VIAGEM, false)}
                                >
                                    {strings.encaminharViagem}
                                </SttButton>
                            }
                            {
                                tfd.acoes.podeSelecionarMotorista &&
                                <SttButton
                                    type="button"
                                    variant="contained"
                                    className={classes.button}
                                    color="primary"
                                    onClick={() => realizarOperacao(OPERACAO.SELECIONAR_MOTORISTA)}
                                >
                                    {strings.selecionarMotorista}
                                </SttButton>
                            }
                            {
                                tfd.acoes.podeEncaminharRegulador &&
                                <SttButton
                                    type="button"
                                    variant="contained"
                                    className={classes.button}
                                    color="primary"
                                    onClick={() => realizarOperacao(OPERACAO.ENCAMINHAR_APROVACAO_REGULADOR)}
                                >
                                    {strings.encaminharRegulador}
                                </SttButton>
                            }
                            {
                                tfd.acoes.podeAprovarRegulador &&
                                <SttButton
                                    type="button"
                                    variant="contained"
                                    className={classes.button}
                                    color="primary"
                                    onClick={() => realizarOperacao(OPERACAO.APROVAR, false)}
                                >
                                    {strings.aprovar}
                                </SttButton>
                            }
                            {
                                tfd.acoes.podeEncaminharFaturaPassagem &&
                                <SttButton
                                    type="button"
                                    variant="contained"
                                    className={classes.button}
                                    color="primary"
                                    onClick={() => realizarOperacao(OPERACAO.ANEXAR_COMPROVANTE_PASSAGEM)}
                                >
                                    {strings.anexarPassagem}
                                </SttButton>
                            }
                            {
                                tfd.acoes.podeAutorizar &&
                                <SttButton
                                    type="button"
                                    variant="contained"
                                    className={classes.button}
                                    color="primary"
                                    onClick={() => realizarOperacao(OPERACAO.FATURA)}
                                >
                                    {strings.fatura}
                                </SttButton>
                            }
                            {
                                tfd.acoes.podeSuspender &&
                                <SttButton
                                    type="button"
                                    variant="contained"
                                    className={classes.button}
                                    color="primary"
                                    onClick={() => realizarOperacao(OPERACAO.SUSPENDER)}
                                >
                                    {strings.suspender}
                                </SttButton>
                            }
                            {
                                tfd.acoes.podeArquivarProcesso &&
                                <SttButton
                                    type="button"
                                    variant="contained"
                                    color="primary"
                                    className={classes.button}
                                    onClick={() => realizarOperacao(OPERACAO.ARQUIVAR)}
                                >
                                    {strings.arquivar}
                                </SttButton>
                            }
                            {
                                tfd.acoes.podeReabrir &&
                                <SttButton
                                    type="button"
                                    variant="contained"
                                    className={classes.button}
                                    color="primary"
                                    onClick={() => realizarOperacao(OPERACAO.REABRIR)}
                                >
                                    {strings.reabrir}
                                </SttButton>
                            }
                            {
                                tfd.acoes.podeCancelar &&
                                <SttButton
                                    type="button"
                                    variant="contained"
                                    className={classes.button}
                                    color="primary"
                                    onClick={() => realizarOperacao(OPERACAO.CANCELAR)}
                                >
                                    {strings.cancelar}
                                </SttButton>
                            }
                            {
                                tfd.acoes.podeImprimir &&
                                <SttButton
                                    type="button"
                                    variant="contained"
                                    disabled={imprimindoTfd}
                                    key={`${imprimindoTfd}`}
                                    className={classes.button}
                                    color="primary"
                                    onClick={imprimirTfd}
                                >
                                    {strings.imprimir}
                                </SttButton>
                            }
                            {
                                tfd.acoes.podeVoltarProcesso &&
                                <SttButton
                                    type="button"
                                    variant="contained"
                                    className={classes.button}
                                    color="primary"
                                    onClick={() => realizarOperacao(OPERACAO.VOLTAR_PROCESSO)}
                                >
                                    {strings.voltarProcesso}
                                </SttButton>
                            }
                        </div>

                    </>
            }
            {
                exibirModalOperacao &&
                <OperacaoTfd
                    setExibirModalOperacao={setExibirModalOperacao}
                    setIdProcessoEspelho={setIdProcessoEspelho}
                    exibirModalForm={exibirModalForm}
                    operacao={operacao}
                    tfd={tfd}
                    voltarPesquisa={voltar}
                />
            }
        </SttContainer>
    )

}

Detalhes.propTypes = {
    user: PropTypes.object.isRequired,
    id: PropTypes.number.isRequired,
    voltar: PropTypes.func.isRequired,
    setIdSolicitacaoSelecionada: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
    return {
        user: state.index.user
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setIdTfdPassagem: (id) => dispatch(setIdTfdPassagem(id)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Detalhes);