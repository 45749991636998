import React, { useContext, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, withRouter, useLocation } from "react-router-dom";
import {
    SttHeader,
    SttMainNavigationBar,
    SttAlerta,
    SttSplashScreen,
    SttTranslateHook,
    MateriaisSuporte,
    SttHelpdeskShortcut,
    SttNotificationScreen
} from '@stt-componentes/core';
import { setExecutarBusca } from '../reducers/actions/pesquisa';
import { PERMISSOES } from '../common/Constants';
import { setIdTfdPassagem } from '..//reducers/actions/solicitacao';
import { temPermissaoRBAC } from '../secutity/acl';
import { getHeaders } from '../request';

const Menu = ({ user, idPassagem, setIdTfdPassagem, setExecutarBusca }) => {
    const location = useLocation();
    const history = useHistory();
    const { strings } = useContext(SttTranslateHook.I18nContext);

    const [tabAtual, setTabAtual] = useState(0);
    const [submenus, setSubmenus] = useState([]);
    const [openSS, setOpenSS] = useState(false);
    const [openMS, setOpenMS] = useState(false);
    const [exibirNotificacao, setExibirNotificacao] = useState(false);

    const stateRefSubmenus = useRef();
    stateRefSubmenus.current = submenus;

    //Alertas
    const [mostrarAlerta, setMostrarAlerta] = useState(false);
    const [tituloAlerta, setTituloAlerta] = useState('');
    const [mensagemAlerta, setMensagemAlerta] = useState('');
    const [opcoesAlerta, setOpcoesAlerta] = useState([]);

    const temMenuSolicitacao = () => {
        return stateRefSubmenus.current.some(m => m.id === '/solicitacao');
    }

    useEffect(() => {
        if (location.pathname.includes('/solicitacao') && (idPassagem || location.search.includes('?id='))) {
            setTabAtual(stateRefSubmenus.current.length - 1);
            return;
        }
        submenus.forEach((menu, i) => {
            if (menu.id === location.pathname) {
                setTabAtual(i);
            }
        });
    }, [submenus, location.pathname]);

    useEffect(() => {
        let menuOptions = [...stateRefSubmenus.current];

        if (idPassagem) {
            const menu = {
                id: `/solicitacao`,
                text: strings.solicitacao,
                onClick: () => {
                    history.push(`/solicitacao`);
                }
            };
            if (!temMenuSolicitacao(menuOptions)) {
                menuOptions.push(menu);
            } else {
                menuOptions[stateRefSubmenus.current.length - 1] = menu;
                setTabAtual(stateRefSubmenus.current.length - 1);
            }
        } else {
            setTabAtual(0);
            setExecutarBusca();
            if (temMenuSolicitacao()) {
                menuOptions = stateRefSubmenus.current.filter((m, index) => index < stateRefSubmenus.current.length - 1);
            }
            history.push("/");

        }
        setSubmenus(menuOptions);
    }, [idPassagem]);

    useEffect(() => {
        let menuOptions = submenus;
        if (user.credenciais) {
            //Tratamento de permissão do usuário
            if (!temPermissaoRBAC(user, PERMISSOES.PASSAGENS)) {
                history.push(`/nao-autorizado`);
                return;
            }

            setExibirNotificacao(true);
            if (temPermissaoRBAC(user, PERMISSOES.VISUALIZACAO)) {
                menuOptions = [...menuOptions, {
                    id: '/pesquisa',
                    text: strings.pesquisa,
                    onClick: () => {
                        if (history.location.pathname === '/solicitacao') {
                            setTituloAlerta(strings.atencao);
                            setMensagemAlerta(strings.confirmarSairEdicao);
                            setOpcoesAlerta([
                                {
                                    title: strings.ok,
                                    onClick: () => {
                                        history.push("/");
                                        setIdTfdPassagem();
                                        setMostrarAlerta(false);
                                    }
                                },
                                {
                                    title: strings.cancelar,
                                    onClick: () => {
                                        setTabAtual(stateRefSubmenus.current.length - 1);
                                        setMostrarAlerta(false);
                                    }
                                }
                            ]);
                            setMostrarAlerta(true);
                        } else {
                            setIdTfdPassagem();
                            history.push("/");
                        }
                    }
                }];
            }
            if (temPermissaoRBAC(user, PERMISSOES.ADMINISTRATIVO)) {
                menuOptions = [...menuOptions, {
                    id: '/administrativo',
                    text: strings.administrativo,
                    onClick: () => {
                        if (history.location.pathname === '/solicitacao') {
                            setTituloAlerta(strings.atencao);
                            setMensagemAlerta(strings.confirmarSairEdicao);
                            setOpcoesAlerta([
                                {
                                    title: strings.ok,
                                    onClick: () => {
                                        history.push("/administrativo");
                                        setIdTfdPassagem();
                                        setMostrarAlerta(false);
                                    }
                                },
                                {
                                    title: strings.cancelar,
                                    onClick: () => {
                                        setTabAtual(stateRefSubmenus.current.length - 1);
                                        setMostrarAlerta(false);
                                    }
                                }
                            ]);
                            setMostrarAlerta(true);
                        } else {
                            setIdTfdPassagem();
                            history.push("/administrativo");
                        }
                    }
                }];
            }
            if (temPermissaoRBAC(user, PERMISSOES.SOLICITACAO) && location.pathname.includes('/solicitacao') && location.search.includes('?id=')) {
                menuOptions = [...menuOptions, {
                    id: `/solicitacao`,
                    text: strings.solicitacao,
                    onClick: () => {
                        history.push(`/solicitacao`);
                    }
                }];
                setSubmenus(menuOptions);
                setTabAtual(menuOptions.length - 1);
                return;
            }
            setSubmenus(menuOptions);
            setTabAtual(0);
        }
    }, [user.credenciais]);

    return (
        <div>
            <SttHeader
                titulo={global.gConfig.instancia_nome}
                config={global.gConfig}
                opcoesDuvida={{
                    onClickNotasAtualizacao: () => setOpenSS(!openSS),
                    onClickMateriaisSuporte: () => setOpenMS(!openMS),
                    strings
                }}
            />
            <SttMainNavigationBar
                titulo={global.gConfig.modulo_nome}
                config={global.gConfig}
                submenus={submenus}
                selectedTab={tabAtual}
                callbackNavigationBar={(a, b) => { }}
            />
            <SttAlerta
                open={mostrarAlerta}
                title={tituloAlerta}
                message={mensagemAlerta}
                type='alert'
                onClose={() => {
                    setMostrarAlerta(false);
                }}
                options={opcoesAlerta}
            />
            <SttSplashScreen
                modulo={global.gConfig.modulo_nome}
                versao={global.gConfig.modulo_versao}
                novidades={global.gConfig.modulo_novidades}
                textoAjuda={global.gConfig.texto_ajuda}
                perfisRBAC={user.perfisRBAC}
                open={openSS}
                setOpen={setOpenSS}
            />
            <MateriaisSuporte
                strings={strings}
                config={global.gConfig}
                headers={getHeaders()}
                open={openMS}
                setOpen={setOpenMS}
            />
            {
                exibirNotificacao &&
                <SttNotificationScreen
                    config={global.gConfig}
                    strings={strings}
                    headers={getHeaders()}
                    perfisRBAC={user.perfisRBAC}
                />
            }
            <SttHelpdeskShortcut
                config={global.gConfig}
                usuario={user}
            />
        </div>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        setExecutarBusca: () => dispatch(setExecutarBusca(true)),
        setIdTfdPassagem: () => dispatch(setIdTfdPassagem(null)),
    };
};

const mapStateToProps = (state) => {
    return {
        user: state.index.user,
        idPassagem: state.solicitacao.idPassagem
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Menu));